<template>
  <div>
    <div class="userInfo">
      <div class="sendTime">
        <img v-if="ordertype == 1" src="@/assets/icon/delivery.png" alt="" />
        <img v-else src="@/assets/icon/pickup.png" alt="" />
        <p v-if="ordertype == 1">{{ haoWeiSuDa }}</p>
        <p v-if="ordertype == 1">
          | {{ lanType == 1 ? "預計" : "Estimated Delivery Time at  "
          }}<span class="quoteTime">{{ time }}</span
          >{{ lanType == 1 ? "送到" : "" }}
        </p>

        <p v-if="ordertype == 2 || takeaway == 2">{{ ziQu }}｜</p>
        <p v-if="ordertype == 2 || takeaway == 2">
          {{ lanType == 1 ? "預計" : "Estimated Ready Time at "
          }}<span class="quoteTime">{{ time }}</span
          >{{ lanType == 1 ? "準備好" : "" }}
        </p>

        <p v-if="ordertype == 3 && takeaway != 2">{{ QRDD_dc }}</p>
        <!-- <p v-if="ordertype == 3 && takeaway != 2">
          {{ lanType == 1 ? "預計" : "Estimated Ready Time at "
          }}<span class="quoteTime">{{ time }}</span
          >{{ lanType == 1 ? "準備好" : "" }}
        </p> -->
      </div>
      <!-- 地址 -->
      <div class="sendPlace" @click="changePlace">
        <div class="sendPlaceLeft">
          <b></b>
          <p>{{ placeName }}</p>
          <i></i>
        </div>
      </div>
      <div :style="{ paddingBottom: $store.state.placeInfo ? 0 : '.16rem' }">
        <div class="userSell sendFunc" v-if="ordertype == 3">
          <p
            @click="changeFunc(1, false)"
            class="shopEat"
            :class="{ active: sendFunc == 1 }"
          >
            {{ $store.state.language == 1 ? "堂食" : "Dine-in" }}
          </p>
          <p
            @click="changeFunc(2, true)"
            class="mineEat"
            :class="{ active: sendFunc == 2 }"
          >
            {{ $store.state.language == 1 ? "外賣" : "Takeaway" }}
          </p>
        </div>
        <div class="userSell address" v-if="ordertype == 1">
          <p>{{ NO_vip }}</p>
          <input
            type="text"
            v-model.trim="detailPlace"
            :placeholder="fillAddress"
            @input="setLength(1)"
            maxlength="16"
          />
        </div>
        <div class="userSell house" v-if="ordertype == 1">
          <input
            type="text"
            v-model.trim="house"
            :placeholder="zuoShuo"
            maxlength="5"
            @input="setLength(2)"
          />
          <input
            type="text"
            v-model.trim="floor"
            :placeholder="cengShu"
            maxlength="5"
            @input="setLength(3)"
          />
          <input
            type="text"
            v-model.trim="doorNum"
            :placeholder="danWei"
            maxlength="5"
            @input="setLength(4)"
          />
        </div>

        <div class="userSell address">
          <p>{{ lianXiRen }}</p>
          <input type="text" v-model.trim="concatPerson" :placeholder="name" />
        </div>
        <div class="userSell">
          <p>{{ gender }}</p>
          <div class="sex">
            <p @click="changeSex(7)">
              <i :class="{ active: sex == 7 }"></i>
              {{ male }}
            </p>
            <p @click="changeSex(8)">
              <i :class="{ active: sex == 8 }"></i>
              {{ female }}
            </p>
          </div>
        </div>
        <div class="userSell handlePhone">
          <p>{{ shouJiHaoMa }}</p>
          <div class="phoneStart">
            <span @click="selectPhone">+{{ phoneCode }}</span>
            <i></i>
            <div class="phoneBox" :class="{ active: phoneShow }">
              <p @click="selectLogCode(852)">+852</p>
              <p @click="selectLogCode(823)">+823</p>
            </div>
          </div>
          <input
            type="number"
            @input="getPhone"
            v-model.trim="phone"
            :placeholder="QRDD_hm"
          />
        </div>
        <div class="userSell address">
          <p>{{ jieShouKFC }}</p>
          <input type="text" v-model.trim="email" :placeholder="QRDD_dy" />
        </div>
      </div>
      <!-- <div class="userSell sendFunc" v-if="ordertype == 3">
        <p
          @click="changeFunc(1, false)"
          class="shopEat"
          :class="{ active: sendFunc == 1 }"
        >
          {{ $store.state.language == 1 ? "堂食" : "Dine-in" }}
        </p>
        <p
          @click="changeFunc(2, true)"
          class="mineEat"
          :class="{ active: sendFunc == 2 }"
        >
          {{ $store.state.language == 1 ? "外賣" : "Takeaway" }}
        </p>
      </div> -->

      <!-- 收货人电话 -->
      <!-- <div class="userSell phone" v-if="ordertype == 3">
        <p>{{ shouJiHaoMa }}</p>
        <input
          type="number"
          @input="getPhone"
          v-model.trim="phoneOne"
          :placeholder="enterPhone"
          maxlength="8"
        />
      </div>
      <div class="userSell phone" v-if="ordertype == 3">
        <p>{{ jieShouKFC }}</p>
        <input type="text" v-model.trim="emailOne" :placeholder="jieShouKFC" />
      </div> -->
      <div class="userSell notice">
        <p>{{ new_QRDD_beiZhu }}</p>
        <textarea
          name=""
          id=""
          cols="20"
          rows="2"
          maxlength="26"
          :placeholder="xiaoYu26"
          v-model.trim="notice"
          @input="setLength(5)"
        ></textarea>
      </div>
      <!-- 当登录之后并且没有选择详细地址信息的时候显示 -->
      <div
        v-if="
          $store.state.userInfo &&
          $store.state.placeInfo &&
          !$store.state.placeInfo.contactPerson &&
          !isYuu
        "
      >
        <p class="setDefault">
          <i :class="{ active: setDefault }" @click="setDefaultPlace"></i>
          <span>{{ new_XDZ_moRenDiZhi }}</span>
        </p>
        <p class="saveBtn" @click="savePlace">{{ baoCunDiZhi }}</p>
      </div>
      <div
        class="userSell active"
        v-if="
          ordertype == 3 &&
          totalPricePre <= 300 &&
          shopInfo.extraStoreId == 'N026' &&
          sendFunc == 2
        "
      >
        <p class="bowl">是否將餐品放入取餐櫃</p>
        <p class="switch" @click="changeSendBox">
          <img
            v-show="switchSendBox"
            src="@/assets/icon/switchActive.png"
            alt=""
          />
          <img v-show="!switchSendBox" src="@/assets/icon/switch.png" alt="" />
        </p>
      </div>
    </div>

    <div class="userInfo active">
      <div class="userSell">
        <p class="bowl active">
          <span>{{ canJu }}</span>
        </p>
        <p class="switch" @click="changeSwitch">
          <img
            v-show="switchBowl"
            src="@/assets/icon/switchActive.png"
            alt=""
          />
          <img v-show="!switchBowl" src="@/assets/icon/switch.png" alt="" />
        </p>
      </div>
      <div v-if="ordertype == 1 && false">
        <div class="userSell active">
          <p class="bowl">
            <span>{{ zhouQuanSongDa }}</span>
            <span>{{ zhiDIngDiDian }}</span>
          </p>
          <p class="switch" @click="changeSend">
            <img
              v-show="switchSend"
              src="@/assets/icon/switchActive.png"
              alt=""
            />
            <img v-show="!switchSend" src="@/assets/icon/switch.png" alt="" />
          </p>
        </div>
        <div class="userSell active bowladd" v-if="switchSend">
          <p>{{ jianYiRenShi }}</p>
          <p class="switch" @click="changeBowladd">
            <img v-show="bowladd" src="@/assets/icon/switchActive.png" alt="" />
            <img v-show="!bowladd" src="@/assets/icon/switch.png" alt="" />
          </p>
        </div>
        <p class="noticeSend" v-if="switchSend">
          {{ jianYiAlert }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import language from "../../assets/js/language.js";
import { wgs_gcj_encrypts } from "../../assets/utils/coordinate";
import { postPaas } from "../../assets/utils/request";

export default {
  name: "OrderConfirmVip",
  data() {
    return {
      switchBowl: false,
      switchSend: false,
      switchSendBox: false,
      bowladd: false,
      bowladSet: true, //是否有隔离人员是否可以修改
      sendFunc: 1, //就餐方式
      detailPlace: "", //详细地址
      house: "", //做数
      floor: "", //层数
      doorNum: "", //单位
      notice: "", //备注
      concatPerson: "", //联系人
      phone: "", //手机号码
      email: "", //电话
      sex: 7, //性别
      phoneOne: "", //手机号
      emailOne: "", //电话
      invite: false, // false 店内i就餐 true 打包
      setDefault: false, //登录状态时填写地址是否设置为默认
      lanType: this.$store.state.language,
      phoneShow: false,
      phoneCode: "852",
      // 静态文字
      gengGaiDiZhi: "",
      sonDaShiJian: "",
      yuJiSongDa: "",
      xiangXiDiZhi: "",
      note: "",
      xiaoYu26: "",
      lianXiRen: "",
      name: "",
      zuoShuo: "",
      cengShu: "",
      danWei: "",
      gender: "",
      male: "",
      female: "",
      shouJiHaoMa: "",
      jieShouKFC: "",
      canJu: "",
      huanBaoCanJu: "",
      zhouQuanSongDa: "",
      zhiDIngDiDian: "",
      enterPhone: "",
      fillAddress: "",
      haoWeiSuDa: "",
      pickAtStore: "",
      QRDD_dc: "",
      jianYiRenShi: "",
      jianYiAlert: "",
      ziQu: "",
      baoCunDiZhi: "",
      new_QRDD_beiZhu: "",
      new_XDZ_moRenDiZhi: "",
      NO_vip: "",
      QRDD_hm: "",
      QRDD_dy: "",
      QXZDZ: "",
      QTXXXDZ: "",
      QTXLXR: "",
      QSRSJHM: "",
    };
  },
  props: {
    ordertype: {
      //1 会员外送 2 到店就餐 3 堂食
      default: 0,
    },
    time: {
      type: String,
      default: "",
    },
    storeName: {
      type: String,
      default: "",
    },
    shopName: {
      type: String,
      default: "",
    },
    quoteTime: {
      type: String,
      default: "",
    },
    shopInfo: {
      type: Object,
      default() {
        return {};
      },
    },
    takeaway: {
      type: Number,
      default: 0,
    },
    book: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    switchBowl() {
      this.$emit("paramInfo", { switchBowl: this.switchBowl });
    },
    switchSend() {
      this.$emit("paramInfo", { switchSend: this.switchSend });
    },
    switchSendBox() {
      this.$emit("paramInfo", { switchSendBox: this.switchSendBox });
    }, //就餐方式
    sendFunc() {
      this.$emit("paramInfo", { sendFunc: this.sendFunc });
    }, //详细地址
    detailPlace() {
      this.$emit("paramInfo", { detailPlace: this.detailPlace });
    },
    house() {
      //做数
      this.$emit("paramInfo", { house: this.house });
    },
    floor() {
      //层数
      this.$emit("paramInfo", { floor: this.floor });
    },
    doorNum() {
      //单位
      this.$emit("paramInfo", { doorNum: this.doorNum });
    },
    notice() {
      //备注
      this.$emit("paramInfo", { notice: this.notice });
    },
    concatPerson() {
      //联系人
      this.$emit("paramInfo", { concatPerson: this.concatPerson });
    },
    phone() {
      //手机号码
      this.$emit("paramInfo", { phone: this.phone });
    },
    email() {
      //电话
      this.$emit("paramInfo", { email: this.email });
    },
    sex() {
      //性别
      this.$emit("paramInfo", { sex: this.sex });
    },
    phoneOne() {
      //手机号
      this.$emit("paramInfo", { phoneOne: this.phoneOne });
    },
    emailOne() {
      //电话
      this.$emit("paramInfo", { emailOne: this.emailOne });
    },
    invite() {
      //堂食 还是打包
      this.$emit("paramInfo", { invite: this.invite });
    },
    phoneCode() {
      this.$emit("paramInfo", { areaCode: this.areaCode });
    },
    takeaway() {
      if (this.takeaway == 2) {
        this.invite = true;
        this.sendFunc = 2;
      }
    },
  },
  methods: {
    setLength(type) {
      var num;
      var str;
      if (type == 1) {
        str = this.detailPlace;
        num = 16;
      } else if (type == 2) {
        str = this.house;
        num = 5;
      } else if (type == 3) {
        str = this.floor;
        num = 5;
      } else if (type == 4) {
        str = this.doorNum;
        num = 5;
      } else if (type == 5) {
        str = this.notice;
        num = 26;
      }
      var TC = this.getStrLength(str);
      var E = str.length - TC;
      var all = TC * 2 + E;
      if (all > num) {
        if (type == 1) {
          this.detailPlace = this.detailPlace.slice(0, str.length - 1);
        } else if (type == 2) {
          this.house = this.house.slice(0, str.length - 1);
        } else if (type == 3) {
          this.floor = this.floor.slice(0, str.length - 1);
        } else if (type == 4) {
          this.doorNum = this.doorNum.slice(0, str.length - 1);
        } else if (type == 5) {
          this.notice = this.notice.slice(0, str.length - 1);
        }
      }
    },
    getStrLength(str) {
      var A = str.match(/[^\x00-\x80]/g) || [];
      return A.length;
    },
    showDatePicker() {
      this.$emit("showDatePicker");
    },
    selectPhone() {
      this.phoneShow = !this.phoneShow;
    },
    selectLogCode(type) {
      this.phoneCode = type;
      this.selectPhone();
    },
    // 保存地址
    async savePlace() {
      if (!this.detailPlace) {
        return this.$toast(this.QTXXXDZ);
      }
      if (!this.concatPerson) {
        return this.$toast(this.QTXLXR);
      }
      if (!this.phone) {
        return this.$toast(this.QSRSJHM);
      }
      var params = {
        actionName: "candao.member.addressCreate",
        content: {
          contactPerson: this.concatPerson,
          phoneNo: this.phone,
          areaCode: this.phoneCode,
          email: this.email,
          cityId: 3, //1 香港
          district: this.detailPlace,
          block: this.house,
          floor: this.floor,
          flat: this.doorNum,
          isDefault: this.setDefault,
          titlesId: this.sex,
          building: this.$store.state.placeInfo.building || "",
        },
      };
      if (this.$store.state.placeInfo) {
        params.content.lat = this.$store.state.placeInfo.lat;
        params.content.lng = this.$store.state.placeInfo.lng;
      } else {
        return this.$toast(this.QXZDZ);
      }
      let result = await postPaas("UserUnify", params);
      if (result.status == 1) {
        var S = this.$store.state.language == 1 ? "添加成功" : "Add success";
        this.$toast(S);
      }
    },
    // 是否设置为默认地址
    setDefaultPlace() {
      this.setDefault = !this.setDefault;
    },
    // 切换地址
    changePlace() {
      if (this.ordertype != 1) {
        this.$router.push({
          path: "/selectShop",
          query: { type: this.ordertype },
        });
      } else if (this.$store.state.userInfo && !this.$store.state.placeInfo) {
        this.$router.push({ path: "/selectShop", query: { type: 1 } });
      } else {
        // this.$router.push("/place/order");
        this.$emit("showPlaceBox");
      }
    },
    changeFunc(type, bool) {
      this.dataLayerPush();
      this.sendFunc = type;
      this.invite = bool;
      if (type == 1 && this.shopInfo.extraStoreId == "N026") {
        this.switchSendBox = false;
        this.$emit("addFoodBox", false);
      }
    },
    // 埋点
    dataLayerPush() {
      window.dataLayer.push({
        event: "dine_in_switch",
        page_category: "confirm_payment",
      });
    },
    // 餐具
    changeSwitch() {
      this.switchBowl = !this.switchBowl;
    },
    // 是否将餐品放入取餐柜
    changeSendBox() {
      this.switchSendBox = !this.switchSendBox;
      this.$emit("addFoodBox", this.switchSendBox);
    },
    // 周全送遞
    changeSend() {
      this.switchSend = !this.switchSend;
    },
    // 是否有隔离人员
    changeBowladd() {
      if (this.bowladSet) return;
      this.bowladd = !this.bowladd;
    },
    // 地址是否隔离
    async getBowladdPerson() {
      var params = {
        actionName: "candao.member.addressIsolationValidate",
        content: {
          lat: 22.36578,
          lng: 114.117975,
        },
      };
      if (this.$store.state.location) {
        var coordinateRes = wgs_gcj_encrypts(
          this.$store.state.location.lat,
          this.$store.state.location.lng
        );
        params.content.lat = coordinateRes.lat;
        params.content.lng = coordinateRes.lng;
      }
      let result = await postPaas("UserUnify", params);
      if (result.status == 1) {
        this.bowladd = result.data.isIsolation;
        this.bowladSet = result.data.isIsolation;
        this.switchSend = result.data.isIsolation;
        this.$emit("paramInfo", {
          switchSend: result.data.isIsolation,
          bowladd: result.data.isIsolation,
        });
      }
    },
    setValue() {
      var param = JSON.parse(localStorage.getItem("KFCYK"));
      for (var key in param) {
        this[key] = param[key];
      }
    },
    // 修改性别
    changeSex(type) {
      this.sex = type;
    },
    getPhone() {
      if (this.phone.length > 8) {
        this.phone = this.phone.slice(0, 8);
      }
      if (this.phoneOne.length > 8) {
        this.phoneOne = this.phoneOne.slice(0, 8);
      }
      if (!this.$store.state.userInfo) {
        if (this.phone.length < 8) {
          if (this.$store.state.couponInfo) {
            this.$parent.cancelCoupon();
          }
        }
      }
      var phone = this.phone || this.phoneOne;
      this.$emit("phone", phone);
    },
    // yuu进来自动设置参数
    setYuuInfo() {
      var yuuInfo =
        this.$store.state.yuuInfo ||
        JSON.parse(localStorage.getItem("yuuUserInfo"));
      if (yuuInfo) {
        yuuInfo = yuuInfo.memberProfileList[0];
      }
      if (!yuuInfo) return;
      this.concatPerson = yuuInfo.firstName + " " + yuuInfo.lastName;
      this.phone = yuuInfo.phoneNo ? yuuInfo.phoneNo.slice(4) : "";
      this.email = yuuInfo.emailAddress || 0;
      this.phoneOne = yuuInfo.phoneNo ? yuuInfo.phoneNo.slice(4) : "";
      this.emailOne = yuuInfo.emailAddress || 0;
    },
    setlanguage() {
      var T;
      if (this.$store.state.language == 1) {
        T = "chinese";
      } else {
        T = "english";
      }
      this.gengGaiDiZhi = language.gengGaiDiZhi[T];
      this.sonDaShiJian = language.sonDaShiJian[T];
      this.yuJiSongDa = language.yuJiSongDa[T];
      this.xiangXiDiZhi = language.xiangXiDiZhi[T];
      this.note = language.note[T];
      this.xiaoYu26 = language.xiaoYu26[T];
      this.lianXiRen = language.lianXiRen[T];
      this.name = language.name[T];
      this.zuoShuo = language.zuoShuo[T];
      this.cengShu = language.cengShu[T];
      this.danWei = language.danWei[T];
      this.gender = language.gender[T];
      this.male = language.male[T];
      this.female = language.female[T];
      this.shouJiHaoMa = language.shouJiHaoMa[T];
      this.jieShouKFC = language.jieShouKFC[T];
      this.canJu = language.canJu[T];
      this.huanBaoCanJu = language.huanBaoCanJu[T];
      this.zhouQuanSongDa = language.zhouQuanSongDa[T];
      this.zhiDIngDiDian = language.zhiDIngDiDian[T];
      this.enterPhone = language.enterPhone[T];
      this.fillAddress = language.fillAddress[T];
      this.haoWeiSuDa = language.haoWeiSuDa[T];
      this.pickAtStore = language.pickAtStore[T];
      this.QRDD_dc = language.QRDD_dc[T];
      this.jianYiRenShi = language.jianYiRenShi[T];
      this.jianYiAlert = language.jianYiAlert[T];
      this.ziQu = language.ziQu[T];
      this.baoCunDiZhi = language.baoCunDiZhi[T];
      this.new_QRDD_beiZhu = language.new_QRDD_beiZhu[T];
      this.new_XDZ_moRenDiZhi = language.new_XDZ_moRenDiZhi[T];
      this.NO_vip = language.NO_vip[T];
      this.QRDD_hm = language.QRDD_hm[T];
      this.QRDD_dy = language.QRDD_dy[T];
      this.QXZDZ = language.QXZDZ[T];
      this.QTXXXDZ = language.QTXXXDZ[T];
      this.QTXLXR = language.QTXLXR[T];
      this.QSRSJHM = language.QSRSJHM[T];
    },
  },
  computed: {
    ...mapState(["isYuu", "yuuInfo"]),
    placeName() {
      var place = "";
      if (this.ordertype == 1) {
        place = this.$store.state.placeInfo.building;
      } else {
        place = this.shopName;
      }
      if (this.isYuu) {
        if (this.yuuInfo && this.yuuInfo.addressNow) {
          place = this.yuuInfo.addressNow.address2;
        }
      }
      return place;
    },
  },
  created() {
    this.setValue();
    this.setlanguage();
    if (this.$store.state.isYuu) {
      this.setYuuInfo();
    }
    this.getBowladdPerson();
    if (this.$store.state.userInfo) {
      this.phone = this.$store.state.userInfo.phoneNo;
    }
    this.$nextTick(() => {
      var cart = this.$store.state.cart;
      if (cart.length) {
        for (var c = 0; c < cart.length; c++) {
          if (cart[c].extId == "ZZ920") {
            this.switchSendBox = true;
            break;
          }
        }
      }
    });
  },
};
</script>
<style scoped>
input::placeholder,
textarea::placeholder {
  color: #b1b1b3;
}
input,
textarea {
  color: #030f14 !important;
}
.userInfo,
.orderFood,
.orderCoupon {
  margin: 0.16rem 0.16rem 0.12rem;
  padding: 0.16rem 0.16rem 0;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 0.08rem;
}
.sendTime {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.12rem;
  font-family: PingFang HK;
  font-weight: 500;
  color: #03060d;
}
.sendTime img {
  height: 0.32rem;
  width: 0.32rem;
  object-fit: cover;
  margin-right: 0.04rem;
}
.sendTime p {
  white-space: nowrap;
}
.sendTime p:last-child {
  font-size: 0.1rem;
  font-family: PingFang HK;
  font-weight: 400;
  color: #474747;
  margin-left: 0.04rem;
  white-space: normal;
}
.sendPlace {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.04rem;
  border-bottom: 0.01rem solid #eee;
  padding-bottom: 0.2rem;
}
.sendPlaceLeft {
  font-size: 0.16rem;
  font-family: PingFang HK;
  font-weight: 600;
  line-height: 18px;
  color: #030f14;
  text-align: left;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.sendPlaceLeft i {
  width: 0.08rem;
  height: 0.06rem;
  background: url("../../assets/icon/bot.png") no-repeat center;
  background-size: cover;
  margin-bottom: 0.02rem;
  margin-left: 0.06rem;
}
.sendPlaceLeft b {
  width: 0.21rem;
  height: 0.21rem;
  background: url("../../assets/icon/dd.png") no-repeat center;
  background-size: cover;
  margin-right: 0.08rem;
}
.sendPlaceRight p {
  height: 0.14rem;
  font-size: 0.1rem;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 0.14rem;
  color: #218bce;
}
.sendPlaceRight i {
  display: block;
  width: 0.25rem;
  height: 0.31rem;
  background: url("../../assets/icon/location.png") no-repeat center;
  background-size: cover;
  margin: auto;
}
.userSell {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.16rem 0;
  box-sizing: border-box;
  font-size: 0.14rem;
  font-family: PingFang HK;
  font-weight: 600;
  color: #030f14;
  border-bottom: 0.01rem solid #eee;
}
.cellTime {
  color: #e4022b;
}
.userSell.active {
  border: none;
}
.bowl {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.bowl span:last-child {
  height: 0.17rem;
  font-size: 0.12rem;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 0.17rem;
  color: #b1b1b3;
}
.bowl.active span:first-child {
  font-size: 0.14rem;
  font-family: PingFang HK;
  font-weight: 600;
  color: #030f14;
}
.switch img {
  width: 0.52rem;
}
.sendFunc p {
  width: 1.48rem;
  height: 0.44rem;
  border: 0.01rem solid #b1b1b3;
  border-radius: 0.04rem;
  line-height: 0.44rem;
  color: #b1b1b3;
  font-size: 0.12rem;
}
.shopEat.active {
  border: 0.01rem solid #e4022b;
  color: #e4022b;
}
.mineEat.active {
  border: 0.01rem solid #e4022b;
  color: #e4022b;
}
.phone input {
  width: 2.2rem;
  text-align: left;
  font-size: 0.14rem;
  font-family: PingFang HK;
  font-weight: 400;
  color: #b1b1b3;
  border: none;
  outline: none;
}
.address input {
  width: 2.3rem;
  text-align: left;
  font-size: 0.14rem;
  font-family: PingFang HK;
  font-weight: 400;
  color: #b1b1b3;
  border: none;
  outline: none;
}
.house input {
  width: 0.93rem;
  height: 0.36rem;
  border: 0.01rem solid #d9d9d9;
  font-size: 0.12rem;
  font-family: PingFang HK;
  font-weight: 400;
  color: #b1b1b3;
  text-align: center;
}
.notice {
  align-items: flex-start;
}
.notice textarea {
  width: 2.2rem;
  font-size: 0.14rem;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 0.18rem;
  color: #b1b1b3;
  border: none;
  outline: none;
  resize: none;
}
.sex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.sex p {
  margin-right: 0.28rem;
  display: flex;
  align-items: center;
}
.sex p i {
  display: inline-block;
  width: 0.16rem;
  height: 0.16rem;
  margin-right: 0.06rem;
  border: none;
  outline: none;
  background: url("../../assets/icon/cycle.png") no-repeat center;
  background-size: cover;
}
.sex p i.active {
  background: url("../../assets/icon/icon@2x.png") no-repeat center;
  background-size: cover;
}
.phoneStart {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.phoneStart i {
  width: 0.12rem;
  height: 0.12rem;
  /* background: url("../../assets/icon/show.png") no-repeat center; */
  background-size: cover;
}
.handlePhone input {
  width: 1.7rem;
  border: none;
  outline: none;
  font-weight: 400 !important;
}
.userInfo.active,
.orderCoupon.active {
  padding-top: 0;
  padding-bottom: 0;
}
.setDefault {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 0.22rem;
  font-size: 0.16rem;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 0.22rem;
  color: #131314;
  margin: 0.16rem 0 0.24rem;
}
.setDefault i {
  width: 0.16rem;
  height: 0.16rem;
  background: url("../../assets/icon/cycle.png") no-repeat center;
  background-size: cover;
  margin-right: 0.08rem;
}
.setDefault i.active {
  background: url("../../assets/icon/icon@2x.png") no-repeat center;
  background-size: cover;
}
.saveBtn {
  width: 100%;
  height: 0.44rem;
  border-radius: 0.06rem;
  font-size: 0.16rem;
  font-family: PingFang HK;
  font-weight: 700;
  line-height: 0.44rem;
  color: #fff;
  background: linear-gradient(135deg, #ff1212 0%, #e4022b 100%);
}
.noticeSend {
  font-size: 0.11rem;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 0.16rem;
  color: #808080;
  text-align: justify;
  padding-bottom: 0.16rem;
}
.bowladd p:first-child {
  width: 2.2rem;
  text-align: left;
}
.phoneBox {
  height: 0;
  position: absolute;
  top: 0.2rem;
  left: 0;
  /* z-index: ; */
  background-color: #fff;
  overflow: hidden;
  transition: all 0.2s linear;
}
.phoneBox.active {
  height: 60px;
}
.quoteTime {
  color: #e4022b !important;
}
</style>