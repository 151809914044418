<template>
  <div class="box">
    <div class="cardBox" :class="{ active: deviceId }">
      <div class="title">
        <p @click="cancle" class="close-icon"></p>
        <p>{{ title }}</p>
        <p v-if="addOrPay" @click="finish">
          <!-- {{ lanType == 1 ? "確認" : "Confirm" }} -->
        </p>
        <p v-else></p>
      </div>
      <div v-if="addOrPay">
        <div class="cardList">
          <div
            @click="selectCard(item, index)"
            class="carditem"
            v-for="(item, index) in cardList"
            :key="item.cardNumber"
          >
            <span :class="{ active: cardIndex == index }"></span>
            <card :cardInfo="item"></card>
          </div>
          <div class="addBtn" @click="addCard">{{ btnText }}</div>
        </div>
      </div>
      <div class="addBox" v-else>
        <bind-card-con
          :show="false"
          :userId="userId"
          :btnText="$store.state.language == 1 ? '確認' : 'Confirm'"
          :cancelParam="cancelParam"
          :confirmAdd="true"
          @closeBox="closeBox"
        ></bind-card-con>
      </div>
    </div>
  </div>
</template>
<script>
import Card from "../mine/Card.vue";
import BindCardCon from "../mine/BindCardCon.vue";
import { postPaas, postDC } from "../../assets/utils/request";
export default {
  name: "CardSelectBox",
  data() {
    return {
      cardIndex: null,
      info: null,
      title:
        this.$store.state.language == 1
          ? "選擇付款方式"
          : "Select Payment Method",
      btnText: this.$store.state.language == 1 ? "確認" : "Confirm",
      addOrPay: true,
      userId: null,
      cardList: [],
      deviceId: false,
      lanType: this.$store.state.language,
      HQXXSB:
        this.$store.state.language == 1
          ? "網絡異常，請重試"
          : "Connection error has occurred, please retry",
    };
  },
  props: {
    sendFunc: {
      default: 1,
    },
    book: {
      default: 1,
    },
    orderType: {
      default: 0,
    },
    phone: {
      type: Number,
      default: 0,
    },
    cancelParam: {
      type: Object,
      default() {
        return {};
      },
    },
    isAddressIsolation: {
      type: Boolean,
      default: false,
    },
    isolatedPerson: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    selectCard(info, index) {
      this.info = info;
      this.cardIndex = index;
    },
    cancle() {
      this.cardIndex = null;
      this.info = null;
      this.$emit("cancle");
    },
    finish() {
      if (!this.info) {
        var X =
          this.$store.state.language == 1
            ? "請選擇付款方式"
            : "Please select payment method";
        return this.$toast(X);
      }
      if (this.info.paymentChannel == "MPGS") {
        this.title =
          this.$store.state.language == 1 ? "添加信用卡" : "Add Card";
        this.addOrPay = false;
        return;
      }
      var param = {
        userId: this.userId,
        card: this.info,
      };
      this.$emit("finishCard", param);
    },
    // 添加信用卡
    addCard() {
      if (!this.info) {
        var X =
          this.$store.state.language == 1
            ? "請選擇付款方式"
            : "Please select payment method";
        return this.$toast(X);
      }
      if (this.info.paymentChannel == "MPGS") {
        this.title =
          this.$store.state.language == 1 ? "添加信用卡" : "Add Card";
        this.addOrPay = false;
        return;
      }
      var param = {
        userId: this.userId,
        card: this.info,
      };
      this.$emit("finishCard", param);
      // if (this.btnText == "添加信用卡") {
      //   this.title = "添加信用卡";
      //   this.addOrPay = false;
      // }
    },
    // 获取支付方式
    async getPayMethods() {
      this.addOrPay = true;
      // if (this.cardList.length) {
      //   this.closeBox();
      //   return;
      // }
      var userId;
      if (!this.$store.state.userInfo) {
        if (!this.phone) {
          var alertText = "請輸入手機號碼";
          if (this.$store.state.language != 1) {
            alertText = "Please provide mobile number";
          }
          return this.$toast(alertText);
        }
        var infoRes = await this.ykRegister();
        if (infoRes) {
          userId = infoRes.uid;
          this.userId = userId;
        }
        // else {
        //   return this.$toast("注册游客用户失败");
        // }
      }
      var year = new Date().getFullYear();
      var month = new Date().getMonth() + 1;
      var day = new Date().getDate();
      if (month < 10) {
        month = "0" + month;
      }
      if (day < 10) {
        day = "0" + day;
      }
      let OT = this.orderType;
      if (this.orderType == 3) {
        if (this.sendFunc == 1) {
          OT = 5;
        } else if (this.sendFunc == 2) {
          OT = 4;
        }
      }
      if (this.book == 2) {
        if (this.orderType == 1) {
          OT = 101;
        }
        if (this.orderType == 2) {
          OT = 102;
        }
      }
      var params = {
        actionName: "candao.member.queryPayMethod",
        content: {
          userId: userId || this.$store.state.userInfo.userId,
          orderType: OT,
          module: "Order",
          restoreDate: year + "-" + month + "-" + day,
          supportGooglePay: this.android,
          isAddressIsolation: this.isAddressIsolation,
          isolatedPerson: this.isolatedPerson,
        },
      };
      if (window.ApplePaySession) {
        try {
          var canMakePayments = await window.ApplePaySession.canMakePayments();
          if (!canMakePayments) {
            params.content.supportApplyPay = false;
          } else {
            params.content.supportApplyPay = true;
          }
        } catch (error) {
          params.content.supportApplyPay = false;
        }
      } else {
        params.content.supportApplyPay = false;
      }
      let result = await postPaas("UserUnify", params);
      if (result.status == 1) {
        this.cardList = result.data.data;
        this.closeBox();
      } else {
        this.$toast(this.HQXXSB);
      }
    },
    // 關閉支付弹窗
    closeBox(info) {
      if (info) {
        var card = this.info;
        card.token = info.token;
        var param = {
          userId: this.userId,
          card: card,
        };
        this.$emit("finishCard", param);
        return;
      }
      this.$emit("showBox");
    },
    //游客注册
    async ykRegister() {
      var params = {
        actionName: "candao.user.getOrCreateVisitor",
        content: {
          phone: this.phone,
        },
      };
      let result = await postDC("Action", params);
      if (result.status == 1 && result.data) {
        return result.data;
      } else {
        return null;
      }
    },
  },
  created() {
    var u = navigator.userAgent;
    if (u.indexOf("iPhone") > -1 || u.indexOf("iphone") > -1) {
      this.deviceId = true;
    } else if (u.indexOf("iPad") > -1 || u.indexOf("ipad") > -1) {
      this.deviceId = true;
    }
  },
  computed: {
    android() {
      var u = navigator.userAgent;
      if (u.indexOf("Android") > -1 || u.indexOf("android") > -1) {
        return true;
      } else {
        return false;
      }
    },
  },
  components: {
    Card,
    BindCardCon,
  },
};
</script>
<style scoped>
.box {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  z-index: 999999;
  border-radius: 0.12rem 0.12rem 0 0;
}
.cardBox {
  width: 100%;
  background-color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
}
.cardBox.active {
  padding-bottom: 0.1rem;
}
.title {
  width: 100%;
  height: 0.54rem;
  border-radius: 0.12rem 0.12rem 0 0;
  background-color: #f2f3f5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.16rem;
  font-family: PingFang HK;
  font-weight: 400;
  color: #808080;
  padding: 0.16rem;
  box-sizing: border-box;
}
.close-icon {
  width: 0.24rem;
  height: 0.24rem;
  background: url("../../assets/icon/login_close.png") no-repeat center;
  background-size: cover;
}
.title p:nth-child(2) {
  font-size: 0.16rem;
  font-family: PingFang HK;
  font-weight: 400;
  color: #051b24;
}
.cardList {
  padding: 0 0.16rem;
  padding-bottom: 0.6rem;
  box-sizing: border-box;
  max-height: 4rem;
  overflow: scroll;
}
.carditem {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.carditem span {
  width: 0.21rem;
  height: 0.21rem;
  border: 0.01rem solid #131314;
  border-radius: 50%;
}
.carditem span.active {
  border: 0.01rem solid #e4022b;
  position: relative;
}
.carditem span.active::after {
  content: "";
  display: inline-block;
  width: 0.12rem;
  height: 0.12rem;
  background-color: #e4022b;
  line-height: 0.2rem;
  text-align: center;
  position: absolute;
  top: 0.04rem;
  left: 0.04rem;
  border-radius: 50%;
}
.addBtn {
  width: 3.43rem;
  height: 0.44rem;
  border-radius: 0.08rem;
  /* margin: 0 0.16rem; */
  background-color: #e4022b;
  font-size: 0.16rem;
  font-family: PingFang HK;
  font-weight: 600;
  line-height: 0.44rem;
  color: #ffffff;
  position: absolute;
  z-index: 999;
  bottom: 0.1rem;
}
</style>