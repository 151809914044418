<template>
  <div class="scoreItem" @click="lookDetail">
    <img :src="couponInfo.imageUrl" alt="" />
    <div class="iconBox">
      <p class="icon" v-if="couponInfo.tagType == 1">
        <i></i>
        <span>{{ $store.state.language == 1 ? "餐廳兌換" : "In Store" }}</span>
      </p>
      <p class="icon iconApp" v-else-if="couponInfo.tagType == 3">
        <i></i>
        <span>{{ $store.state.language == 1 ? "App兌換" : "By App" }}</span>
      </p>
      <p class="icon devicry" v-else-if="couponInfo.tagType == 2">
        <i></i>
        <span>{{ $store.state.language == 1 ? "外送速遞" : "Delivery" }}</span>
      </p>
    </div>
    <p class="voucherName">
      {{ couponInfo.voucherName }}
    </p>
    <div class="btnBox">
      <p>
        {{ couponInfo.point
        }}{{ $store.state.language == 1 ? "積分" : "Points" }}
      </p>
      <p class="btn" @click.prevent.stop="scoreCoupon">
        {{ $store.state.language == 1 ? "兌換" : "Redeem" }}
      </p>
    </div>
    <confim
      v-show="show"
      :show="show"
      :title="$store.state.language == 1 ? '兌換說明' : 'How to Redeem'"
      :showType="2"
      :descriptText="content"
      :btnText="$store.state.language == 1 ? '知道了' : 'got It'"
      @closeCon="showConfim"
    ></confim>
  </div>
</template>
<script>
import Confim from "../common/Confim.vue";
export default {
  name: "ScoreItem",
  data() {
    return {
      send: 1,
      show: false,
      content: "說明",
    };
  },
  props: {
    couponInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    lookDetail() {
      this.$emit("jump", this.couponInfo);
    },
    showConfim(desc) {
      this.content = desc;
      this.show = !this.show;
    },
    // 兑换优惠券
    async scoreCoupon() {
      this.$emit("coupon", this.couponInfo);
    },
  },
  components: {
    Confim,
  },
};
</script>
<style scoped>
.scoreItem {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding-bottom: 0.12rem;
  /* box-shadow: -2px 10px 10px 1px rgb(193, 194, 195); */
  border-radius: 0.08rem;
  overflow: hidden;
}
.scoreItem img {
  width: 1.64rem;
  height: 1.37rem;
  object-fit: cover;
}
.iconBox {
  display: flex;
  flex-wrap: wrap;
}
.icon {
  padding: 0 0.05rem;
  /* width: 0.5rem; */
  height: 0.18rem;
  display: flex;
  margin: 0.12rem 0.14rem 0;
  font-size: 0.1rem;
  color: #03060d;
  align-items: center;
  justify-content: center;
  background-color: #f5e9eb;
  border-radius: 0.02rem;
}

.icon.active {
  background-color: #fff;
}

.icon i {
  width: 0.11rem;
  height: 0.11rem;
  background: url("../../assets/icon/g2.png") no-repeat center;
  background-size: cover;
  margin-right: 0.04rem;
}
.iconApp i {
  width: 0.068rem;
  height: 0.114rem;
  background: url("../../assets/icon/g1.png") no-repeat center;
  background-size: 0.068rem 0.114rem;
}

.icon.devicry {
  width: 0.7rem;
  background-color: #f2f3f5;
}

.icon.devicry i {
  width: 0.13rem;
  height: 0.1rem;
  background: url("../../assets/icon/sd.png") no-repeat center;
  background-size: cover;
}

.time {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.14rem;
  font-family: PingFang HK;
  font-weight: 600;
  color: #e3002b;
  padding: 0 0.14rem;
  box-sizing: border-box;
}
.time p:nth-child(2) {
  font-size: 0.11rem;
  font-weight: 400;
  color: #7f7f7f;
}
.btn {
  width: 0.64rem;
  height: 0.29rem;
  background: linear-gradient(90deg, #fe1112 0%, #e4022b 100%);
  border-radius: 0.24rem;
  font-size: 0.14rem;
  font-family: PingFang HK;
  font-weight: 500;
  line-height: 0.29rem;
  color: #ffffff;
  box-sizing: border-box;
}
.warn {
  font-size: 0.11rem;
  font-family: PingFang HK;
  font-weight: 400;
  color: #808080;
  text-align: left;
  display: flex;
  align-items: center;
  padding-left: 0.14rem;
  box-sizing: border-box;
}
.warn i {
  width: 0.16rem;
  height: 0.16rem;
  background: url("../../assets/icon/error.png") no-repeat center;
  background-size: cover;
  margin-right: 0.02rem;
}
.voucherName {
  font-family: PingFang HK;
  font-weight: 500;
  font-size: 0.14rem;
  line-height: 0.15rem;
  text-align: left;
  /* word-break: break-all; */
  /* word-wrap: break-word; */
  color: #02050c;
  padding: 0 0.12rem;
  box-sizing: border-box;
  margin-top: 0.08rem;
  margin-bottom: 0.05rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.btnBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  font-size: 0.12rem;
  color: #e3002b;
  padding: 0 0.12rem;
  box-sizing: border-box;
}
</style>