<template>
  <div>
    <div class="userInfo">
      <div class="sendTime">
        <img
          v-if="ordertype == 1 || ordertype == 2"
          src="@/assets/icon/delivery.png"
          alt=""
        />
        <img v-else src="@/assets/icon/pickup.png" alt="" />

        <p v-if="ordertype == 1">{{ haoWeiSuDa }}｜</p>
        <p v-if="ordertype == 1">
          {{ lanType == 1 ? "預計" : "Estimated Delivery Time at  "
          }}<span class="quoteTime">{{ time }}</span
          >{{ lanType == 1 ? "送到" : "" }}
        </p>

        <p v-if="ordertype == 2 || takeaway == 2">{{ ziQu }}｜</p>
        <p v-if="ordertype == 2 || takeaway == 2">
          {{ lanType == 1 ? "預計" : "Estimated Ready Time at " }}
          <span class="quoteTime">{{ time }}</span>
          {{ lanType == 1 ? "準備好" : "" }}
        </p>

        <p v-if="ordertype == 3 && takeaway != 2">{{ QRDD_dc }}</p>
        <!-- <p v-if="ordertype == 3 && takeaway != 2">
          {{ lanType == 1 ? "預計" : "Estimated Ready Time at " }}
          <span class="quoteTime">{{ time }}</span>
          {{ lanType == 1 ? "準備好" : "" }}
        </p> -->
      </div>
      <!-- 地址 -->
      <div class="sendPlace" @click="changePlace">
        <div class="sendPlaceLeft" v-if="ordertype != 1">
          <p class="placeName"><b></b>{{ shopName }}<i></i></p>
          <p>{{ shopInfo.storeAddress }}</p>
        </div>
        <div class="sendPlaceLeft" v-if="ordertype == 1">
          <p class="placeName">
            <b></b>
            {{ placeInfo ? placeInfo.district : "" }}
            <i></i>
          </p>
          <p>
            {{ placeInfo ? placeInfo.contactPerson : "" }}
            {{ placeInfo ? placeInfo.phoneNo : "" }}
          </p>
        </div>
        <!-- <div @click="changePlace" class="sendPlaceRight">
          <i></i>
          <p>{{ gengGaiDiZhi }}</p>
        </div> -->
      </div>
      <div class="userSell sendFunc" v-if="ordertype == 3">
        <p
          @click="changeFunc(1, false)"
          class="shopEat"
          :class="{ active: sendFunc == 1 }"
        >
          {{ $store.state.language == 1 ? "堂食" : "Dine-in" }}
        </p>
        <p
          @click="changeFunc(2, true)"
          class="mineEat"
          :class="{ active: sendFunc == 2 }"
        >
          {{ $store.state.language == 1 ? "外賣" : "Takeaway" }}
        </p>
      </div>
      <!-- 收货人电话 -->
      <div class="userSell">
        <p>{{ shouJiHaoMa }}</p>
        <p v-if="ordertype == 1">
          {{ placeInfo ? placeInfo.phoneNo : "" }}
        </p>
        <p v-else>
          {{ $store.state.userInfo ? $store.state.userInfo.phoneNo : "" }}
        </p>
      </div>
      <div class="userSell noticeBox">
        <p>{{ new_QRDD_beiZhu }}</p>
        <textarea
          name=""
          id=""
          cols="20"
          rows="2"
          maxlength="26"
          :placeholder="xiaoYu26"
          v-model="notice"
        ></textarea>
      </div>

      <div
        class="userSell active"
        v-if="
          ordertype == 3 &&
          totalPricePre <= 300 &&
          shopInfo.extraStoreId == 'N026' &&
          sendFunc == 2 && false
        "
      >
        <p class="bowl">是否將餐品放入取餐櫃</p>
        <p class="switch" @click="changeSendBox">
          <img
            v-show="switchSendBox"
            src="@/assets/icon/switchActive.png"
            alt=""
          />
          <img v-show="!switchSendBox" src="@/assets/icon/switch.png" alt="" />
        </p>
      </div>
    </div>
    <div class="userInfo active">
      <div class="userSell">
        <p class="bowl active">
          <span>{{ canJu }}</span>
          <!-- <span>{{ huanBaoCanJu }}</span> -->
        </p>
        <p class="switch" @click="changeSwitch">
          <img
            v-show="switchBowl"
            src="@/assets/icon/switchActive.png"
            alt=""
          />
          <img v-show="!switchBowl" src="@/assets/icon/switch.png" alt="" />
        </p>
      </div>
      <div v-if="ordertype == 1 && false">
        <div class="userSell active">
          <p class="bowl">
            <span>{{ zhouQuanSongDa }}</span>
            <span>{{ zhiDIngDiDian }}</span>
          </p>
          <p class="switch" @click="changeSend">
            <img
              v-show="switchSend"
              src="@/assets/icon/switchActive.png"
              alt=""
            />
            <img v-show="!switchSend" src="@/assets/icon/switch.png" alt="" />
          </p>
        </div>
        <div class="userSell active bowladd" v-if="switchSend">
          <p>{{ jianYiRenShi }}</p>
          <p class="switch" @click="changeBowladd">
            <img v-show="bowladd" src="@/assets/icon/switchActive.png" alt="" />
            <img v-show="!bowladd" src="@/assets/icon/switch.png" alt="" />
          </p>
        </div>
        <p class="notice" v-if="switchSend">
          {{ jianYiAlert }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import language from "../../assets/js/language.js";
import { wgs_gcj_encrypts } from "../../assets/utils/coordinate";

import { postPaas } from "../../assets/utils/request";

export default {
  name: "OrderConfirmVip",
  data() {
    return {
      switchBowl: false, //是否使用环保餐具
      switchSend: false, //是否周全送达
      switchSendBox: false, //是否将餐品放入取餐柜
      sendFunc: 1, //就餐方式
      bowladd: false, //是否有隔离人员
      bowladSet: true, //是否有隔离人员是否可以修改
      invite: false, //
      notice: "",
      // placeInfo: this.$store.state.placeInfo, //配送地址信息
      lanType: this.$store.state.language,
      // 静态文字
      gengGaiDiZhi: "",
      shouJiHaoMa: "",
      sonDaShiJian: "",
      canJu: "",
      huanBaoCanJu: "",
      zhouQuanSongDa: "",
      zhiDIngDiDian: "",
      jianYiRenShi: "",
      jianYiAlert: "",
      yuJiSongDa: "",
      haoWeiSuDa: "",
      pickAtStore: "",
      ziQu: "",
      new_QRDD_beiZhu: "",
      xiaoYu26: "",
      QRDD_dc: "",
    };
  },
  props: {
    ordertype: {
      //1 会员外送 2 到店就餐 3 堂食
      default: 0,
    },
    time: {
      type: String,
      default: "",
    },
    shopName: {
      type: String,
      default: "",
    },
    storeId: {
      type: Number,
      default: 0,
    },
    shopInfo: {
      type: Object,
      default() {
        return {};
      },
    },
    quoteTime: {
      type: String,
      default: "",
    },
    placeInfo: {
      type: Object,
      default() {
        return {};
      },
    },
    takeaway: {
      type: Number,
      default: 0,
    },
    totalPricePre: {
      type: Number,
      default: 0,
    },
    book: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    showDatePicker() {
      this.$emit("showDatePicker");
    },
    changeFunc(type, bool) {
      this.dataLayerPush();
      this.sendFunc = type;
      this.invite = bool;
      if (type == 1 && this.shopInfo.extraStoreId == "N026") {
        this.switchSendBox = false;
        this.$emit("addFoodBox", false);
      }
    },
    // 埋点
    dataLayerPush() {
      window.dataLayer.push({
        event: "dine_in_switch",
        page_category: "confirm_payment",
      });
    },
    // 餐具
    changeSwitch() {
      this.switchBowl = !this.switchBowl;
    },
    // 是否将餐品放入取餐柜
    changeSendBox() {
      this.switchSendBox = !this.switchSendBox;
      this.$emit("addFoodBox", this.switchSendBox);
    },
    // 周全送遞
    changeSend() {
      if (this.bowladSet) {
        this.switchSend = true;
        return;
      }
      if (this.bowladd) return;
      this.switchSend = !this.switchSend;
    },
    // 是否有隔离人员
    changeBowladd() {
      if (this.bowladSet) return;
      this.bowladd = !this.bowladd;
    },
    // 地址是否隔离
    async getBowladdPerson() {
      var params = {
        actionName: "candao.member.addressIsolationValidate",
        content: {
          lat: 22.36578,
          lng: 114.117975,
          // lat: 22.263897,
          // lng: 114.240544,
        },
      };
      // if (this.$store.state.userInfo) {
      var placeInfo = this.selectPlaceInfo || this.$store.state.placeInfo;
      if (placeInfo) {
        var coordinateRes = wgs_gcj_encrypts(placeInfo.lat, placeInfo.lng);
        params.content.lng = coordinateRes.lng;
        params.content.lat = coordinateRes.lat;
      }
      // }

      let result = await postPaas("UserUnify", params);
      if (result.status == 1) {
        this.bowladd = result.data.isIsolation;
        this.bowladSet = result.data.isIsolation;
        this.switchSend = result.data.isIsolation;
        this.$emit("paramInfo", {
          switchSend: result.data.isIsolation,
          bowladd: result.data.isIsolation,
        });
      }
    },
    // 更改地址
    changePlace() {
      if (this.ordertype == 1) {
        // var param = {
        //   storeId: this.storeId,
        //   coordinate: this.shopInfo.coordinate,
        // };
        // param = JSON.stringify(param);
        // this.$router.push("/place/" + param);
        this.$emit("showPlaceBox");
      } else {
        this.$router.push({
          path: "/selectShop",
          query: { type: this.ordertype },
        });
      }
    },
    // 设置语言
    setLanguage() {
      var languageType;
      if (this.$store.state.language == 1) {
        languageType = "chinese";
      } else {
        languageType = "english";
      }
      this.gengGaiDiZhi = language.gengGaiDiZhi[languageType];
      this.shouJiHaoMa = language.shouJiHaoMa[languageType];
      this.sonDaShiJian = language.sonDaShiJian[languageType];
      this.canJu = language.canJu[languageType];
      this.huanBaoCanJu = language.huanBaoCanJu[languageType];
      this.zhouQuanSongDa = language.zhouQuanSongDa[languageType];
      this.zhiDIngDiDian = language.zhiDIngDiDian[languageType];
      this.jianYiRenShi = language.jianYiRenShi[languageType];
      this.jianYiAlert = language.jianYiAlert[languageType];
      this.yuJiSongDa = language.yuJiSongDa[languageType];
      this.haoWeiSuDa = language.haoWeiSuDa[languageType];
      this.pickAtStore = language.pickAtStore[languageType];
      this.QRDD_dc = language.QRDD_dc[languageType];
      this.ziQu = language.ziQu[languageType];
      this.new_QRDD_beiZhu = language.new_QRDD_beiZhu[languageType];
      this.xiaoYu26 = language.xiaoYu26[languageType];
    },
  },
  watch: {
    invite() {
      //堂食 还是打包
      this.$emit("paramInfo", { invite: this.invite });
    },
    switchBowl() {
      this.$emit("paramInfo", { switchBowl: this.switchBowl });
    },
    switchSend() {
      this.$emit("paramInfo", { switchSend: this.switchSend });
    },
    switchSendBox() {
      this.$emit("paramInfo", { switchSendBox: this.switchSendBox });
    }, //就餐方式
    sendFunc() {
      this.$emit("paramInfo", { sendFunc: this.sendFunc });
    },
    bowladd() {
      this.$emit("paramInfo", { bowladd: this.bowladd });
    },
    takeaway() {
      if (this.takeaway == 2) {
        this.invite = true;
        this.sendFunc = 2;
      }
    },
    notice() {
      this.$emit("paramInfo", { notice: this.notice });
    },
  },

  created() {
    this.setLanguage();
    this.getBowladdPerson();
    this.$nextTick(() => {
      var cart = this.$store.state.cart;
      if (cart.length) {
        for (var c = 0; c < cart.length; c++) {
          if (cart[c].extId == "ZZ920") {
            this.switchSendBox = true;
            break;
          }
        }
      }
    });
  },
};
</script>
<style scoped>
input::placeholder,
textarea::placeholder {
  color: #b1b1b3;
}
input,
textarea {
  color: #030f14 !important;
}
.userInfo,
.orderFood,
.orderCoupon {
  margin: 0.16rem 0.16rem 0.12rem;
  padding: 0.16rem 0.16rem 0;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 0.08rem;
}
.sendTime {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.12rem;
  font-family: PingFang HK;
  font-weight: 500;
  color: #03060d;
}
.sendTime img {
  height: 0.32rem;
  width: 0.32rem;
  object-fit: cover;
  margin-right: 0.04rem;
}
.sendTime p:last-child {
  font-size: 0.1rem !important;
  font-family: PingFang HK;
  font-weight: 400;
  color: #474747;
  margin-left: 0.04rem;
}
.sendPlace {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 0.04rem;
  border-bottom: 0.01rem solid #eee;
  padding-bottom: 0.2rem;
}
.placeName {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.placeName i {
  width: 0.08rem;
  height: 0.06rem;
  background: url("../../assets/icon/bot.png") no-repeat center;
  background-size: cover;
  margin-bottom: 0.02rem;
  margin-left: 0.06rem;
}
.placeName b {
  width: 0.21rem;
  height: 0.21rem;
  background: url("../../assets/icon/dd.png") no-repeat center;
  background-size: cover;
  margin-right: 0.08rem;
}
.sendPlaceLeft {
  font-size: 0.16rem;
  font-family: PingFang HK;
  font-weight: 600;
  line-height: 18px;
  color: #030f14;
  text-align: left;
}
.sendPlaceLeft p:last-child {
  height: 0.17rem;
  font-size: 0.12rem;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 0.17rem;
  color: #474747;
  margin-top: 0.04rem;
}
.sendPlaceRight p {
  height: 0.14rem;
  font-size: 0.1rem;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 0.14rem;
  color: #218bce;
  white-space: nowrap;
}
.sendPlaceRight i {
  display: block;
  width: 0.08rem;
  height: 0.06rem;
  background: url("../../assets/icon/bot.png") no-repeat center;
  background-size: cover;
  margin: auto;
}
.userSell {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.16rem 0;
  box-sizing: border-box;
  font-size: 0.14rem;
  font-family: PingFang HK;
  font-weight: 600;
  color: #030f14;
  border-bottom: 0.01rem solid #eee;
}
.cellTime {
  color: #e4022b;
}
.userSell.active {
  border: none;
}
.bowl {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.bowl span:last-child {
  height: 0.17rem;
  font-size: 0.12rem;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 0.17rem;
  color: #b1b1b3;
}
.bowl.active span {
  font-size: 0.14rem;
  font-family: PingFang HK;
  font-weight: 600;
  color: #030f14;
}
.switch img {
  width: 0.52rem;
}
.sendFunc p {
  width: 1.48rem;
  height: 0.44rem;
  border: 0.01rem solid #b1b1b3;
  border-radius: 0.04rem;
  line-height: 0.44rem;
  color: #b1b1b3;
  font-size: 0.12rem;
}
.shopEat.active {
  border: 0.01rem solid #e4022b;
  color: #e4022b;
}

.mineEat.active {
  border: 0.01rem solid #e4022b;
  color: #e4022b;
}
.userInfo.active,
.orderCoupon.active {
  padding-top: 0;
  padding-bottom: 0;
}
.bowladd p:first-child {
  width: 2.2rem;
  text-align: left;
}
.notice {
  font-size: 0.11rem;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 0.16rem;
  color: #808080;
  text-align: justify;
  padding-bottom: 0.16rem;
}
.noticeBox {
  align-items: flex-start;
}
.noticeBox textarea {
  width: 2.2rem;
  font-size: 0.14rem;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 0.18rem;
  color: #b1b1b3;
  border: none;
  outline: none;
  resize: none;
}
.quoteTime {
  color: #e4022b !important;
}
</style>