<template>
  <div class="myScorePage">
    <topbar :title="jiFenDuiHan" category="points_redemption"></topbar>
    <div class="content">
      <p class="score">
        <span>{{ cardInfo.point }}</span>
        <span>{{ jiFen }}</span>
      </p>
      <!-- <p class="noneScore">
        {{ new_JFDH_jiFenDaoQi }}
        <span>-</span>
      </p> -->
      <div class="scoreListBox">
        <div class="scoreList">
          <div v-for="(item, index) in scoreList" :key="item.voucherId">
            <div class="itemBox" v-if="index % 2 == 0">
              <score-item
                @coupon="scoreCoupon"
                :couponInfo="item"
                @jump="lookDetail"
              ></score-item>
            </div>
          </div>
        </div>
        <div class="scoreList">
          <div v-for="(item, index) in scoreList" :key="item.voucherId">
            <div class="itemBox" v-if="index % 2 > 0">
              <score-item
                @coupon="scoreCoupon"
                :couponInfo="item"
                @jump="lookDetail"
              ></score-item>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Topbar from "../../components/Topbar.vue";
import ScoreItem from "../../components/score/ScoreItem.vue";
import { postPaas } from "../../assets/utils/request";
import language from "../../assets/js/language";
export default {
  name: "MyScore",
  data() {
    return {
      scoreList: [],
      cardInfo: {},
      jiFenDuiHan: "",
      jiFen: "",
      new_JFDH_jiFenDaoQi: "",
      new_JFDH_duiHuan: "",
      HQXXSB:""
    };
  },
  methods: {
    // 埋點
    dataLayerPush(info) {
      var ecommerce = {
        Coupon: info.voucherName,
        // Coupon_category_1
        // Coupon_category_2
        coupon_ID: info.voucherId,
        coupon_variant: info.voucherDesc,
        used_points: info.point,
        User_expiry_points: 0,
        user_total_points: this.cardInfo.point,
      };
      if (info.voucherCategory) {
        for (var i = 0; i < info.voucherCategory.length; i++) {
          ecommerce["Coupon_category_" + (i + 1)] = info.voucherCategory[i];
        }
      }
      window.dataLayer.push({
        event: "points_redemption",
        ecommerce,
      });
    },
    lookDetail(info) {
      // this.dataLayerPush(info);
      info = JSON.stringify(info);
      info = encodeURIComponent(info);
      this.$router.push({
        path: "/couponDetail",
        query: {
          info,
          score: this.cardInfo.point,
        },
      });
    },
    async getUserInfo() {
      if (this.$store.state.userInfo) {
        let params = {
          actionName: "candao.member.userProfileGet",
          content: {},
        };
        let result = await postPaas("UserUnify", params);
        if (result.status == 1) {
          this.cardInfo = result.data;
        }
      }
    },
    async getCouponDui() {
      let params = {
        actionName: "candao.member.voucherRedeemableGet",
        content: {},
      };
      if (this.$store.state.language == 1) {
        params.content.language = "TC";
      }
      let result = await postPaas("UserUnify", params);
      if (result.status == 1) {
        for (var z = 0; z < result.data.data.length; z++) {
          var beginDateC = result.data.data[z].validBeginDate
            ? result.data.data[z].validBeginDate
                .split("T")[0]
                .split("-")
                .reverse()
            : null;
          var endDateC = result.data.data[z].validEndDate
            ? result.data.data[z].validEndDate
                .split("T")[0]
                .split("-")
                .reverse()
            : null;
          if (beginDateC) {
            result.data.data[z].date =
              beginDateC.join("-") + "至" + endDateC.join("-");
          } else if (endDateC) {
            result.data.data[z].date = endDateC.join("-");
          } else {
            result.data.data[z].date = "-";
          }
        }
        result.data.data.forEach((val) => {
          if (
            val.voucherCategoryCode &&
            (val.voucherCategoryCode.indexOf("A03") == -1 ||
              val.voucherCategoryCode.indexOf("A05") == -1)
          ) {
            if (val.voucherCategoryCode.indexOf("A03") == -1) {
              val.tagType = 1;
            }
            if (val.voucherCategoryCode.indexOf("A05") == -1) {
              val.tagType = 1;
            }
          } else if (
            val.voucherCategoryCode &&
            (val.voucherCategoryCode.indexOf("A01") > -1 ||
              val.voucherCategoryCode.indexOf("A02") > -1)
          ) {
            val.tagType = 2;
          } else {
            val.tagType = 0;
          }
        });
        this.scoreList = result.data.data;
      }
    },
    //  兑换优惠券
    async scoreCoupon(info) {
      this.dataLayerPush(info);
      this.$emit("coupon");
      let params = {
        actionName: "candao.member.voucherRedeemableRedeem",
        content: {
          voucherId: info.voucherId,
        },
      };
      let result = await postPaas("UserUnify", params);
      if (result.status != 1) {
        this.$toast(this.HQXXSB);
      }
    },
    setlanguage() {
      var type = "chinese";
      if (this.$store.state.language != 1) {
        type = "english";
      }
      this.jiFenDuiHan = language.jiFenDuiHan[type];
      this.jiFen = language.jiFen[type];
      this.new_JFDH_jiFenDaoQi = language.new_JFDH_jiFenDaoQi[type];
      this.new_JFDH_duiHuan = language.new_JFDH_duiHuan[type];
      this.HQXXSB=language.HQXXSB[type]
    },
  },

  created() {
    this.setlanguage();
    this.getUserInfo();
    this.getCouponDui();
    // this.getScore();
  },
  components: {
    Topbar,
    ScoreItem,
  },
};
</script>
<style scoped>
.myScorePage {
  width: 100%;
  height: 100vh;
  overflow: scroll;
  background-color: #f2f3f5;
}
.content {
  padding: 0.16rem;
  box-sizing: border-box;
}
.score {
  font-size: 0.26rem;
  font-family: PingFang HK;
  font-weight: 600;
  line-height: 0.3rem;
  color: #e4022b;
  text-align: left;
}
.score span:last-child {
  font-size: 0.1rem;
  font-family: PingFang HK;
  font-weight: 400;
  color: #03060d;
  margin-left: 0.04rem;
}
.noneScore {
  height: 0.2rem;
  font-size: 0.14rem;
  font-family: PingFang HK;
  font-weight: 600;
  line-height: 0.2rem;
  color: #474747;
  text-align: left;
}
.noneScore span {
  color: #e4022b;
}
.scoreList {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 0.16rem;
  margin-right: 0.15rem;
}
.scoreList .itemBox {
  width: 1.64rem;
  height: auto;
  /* margin-right: 0.15rem; */
  margin-bottom: 0.12rem;
}
.scoreList .itemBox:nth-child(2n) {
  margin-right: 0;
}
.scoreListBox {
  display: flex;
}
</style>