<template>
  <div class="confirmOrderPage" id="confirmOrderPage">
    <topbar :title="new_QRDD_queRenDingDan"></topbar>
    <!-- 配送信息 -->
    <!--  && $store.state.placeInfo -->
    <div
      v-if="
        login &&
        $store.state.placeInfo &&
        ($store.state.placeInfo.contactPerson || pageParam.businessType != 1)
      "
    >
      <order-confirm-vip
        :sendTime="sendTime"
        :ordertype="pageParam.businessType"
        :time="time"
        :shopName="shopInfo.storeName"
        @paramInfo="getParmaInfo"
        @showPlaceBox="showPlaceBox"
        @showDatePicker="showDatePicker"
        :storeId="pageParam.storeId"
        :shopInfo="shopInfo"
        :quoteTime="quoteTime || '未知'"
        :placeInfo="selectPlaceInfo || $store.state.placeInfo"
        :takeaway="pageParam.takeaway || null"
        :totalPricePre="totalPricePre"
        @addFoodBox="addFoodBox"
        :book="pageParam.book"
      ></order-confirm-vip>
    </div>
    <div v-else>
      <order-confirm-no-vip
        @phone="getPhone"
        :sendTime="sendTime"
        :ordertype="pageParam.businessType"
        @showPlaceBox="showPlaceBox"
        @paramInfo="getParmaInfo"
        @showDatePicker="showDatePicker"
        :time="time"
        :shopName="shopInfo.storeName"
        :storeId="pageParam.storeId"
        :shopInfo="shopInfo"
        :quoteTime="quoteTime || '未知'"
        :takeaway="pageParam.takeaway || null"
        :totalPricePre="totalPricePre"
        @addFoodBox="addFoodBox"
        :book="pageParam.book"
      ></order-confirm-no-vip>
    </div>
    <!-- //订单详情 -->
    <div class="orderFood">
      <div class="title-box">
        <p class="title"><i></i>{{ dingDanXiangQing }}</p>
        <p @click="editOrder">
          <i></i>{{ $store.state.language == 1 ? "更改" : "Edit" }}
        </p>
      </div>
      <div class="foodList">
        <div class="itemBox" :class="{ active: true }">
          <template v-for="item in cartList" :key="item.uid">
            <div class="foodItem" v-if="item.isCanShow">
              <div class="itemLeft">
                <p class="foodName">
                  <span class="couponIcon" v-if="item.isCouponPro && false"
                    >券</span
                  >
                  <span v-if="lanType == 1">{{ item.name }}</span>
                  <span v-else>{{ item.nameEn || item.nameTw }}</span>
                </p>
                <p>x{{ item.num }}</p>
                <p>
                  <span>{{ $store.state.money[$store.state.area] }} </span>
                  <span> {{ item.priceAll }}</span>
                </p>
              </div>
              <!-- 食物详情 -->
              <div class="foodInfo" v-if="item.type != 1 || item.typeId != 1">
                <div
                  class="foodInfoItem"
                  v-for="(subPro, i) in item.subProList"
                  :key="i + '_' + subPro.pid"
                >
                  <p>{{ lanType == 1 ? subPro.name : subPro.nameEn }}</p>
                  <p>x{{ subPro.num }}</p>
                </div>
              </div>
            </div>
          </template>
          <div class="foodItem" v-if="foodItemShow">
            <div class="itemLeft">
              <p class="foodName">
                <span class="couponIcon" v-if="false">券</span>
                <span v-if="lanType == 1">{{ couponInfo.copyPro.name }}</span>
                <span v-else>{{
                  couponInfo.copyPro.name || couponInfo.copyPro.name
                }}</span>
              </p>
              <p>x1</p>
              <p>
                <span>{{ $store.state.money[$store.state.area] }} </span>
                <span> {{ couponInfo.copyPro.price }}</span>
              </p>
            </div>
            <!-- 食物详情 -->
            <p class="foodInfo">{{ couponInfo.copyPro.desc }}</p>
          </div>
          <div class="foodItem" v-if="couponItemSHow">
            <div class="itemLeft">
              <p class="foodName">
                <span class="couponIcon" v-if="false">券</span>
                <span v-if="lanType == 1">{{
                  couponInfo.mappingPro.mappingName
                }}</span>
                <span v-else>{{ couponInfo.mappingPro.mappingName }}</span>
              </p>
              <p>x{{ 1 }}</p>
              <p>
                <span>{{ $store.state.money[$store.state.area] }} </span>
                <span> {{ couponInfo.mappingPro.mappingPrice || 0 }}</span>
              </p>
            </div>
            <!-- 食物详情 -->
            <p class="foodInfo" v-if="lanType == 1">
              {{ couponInfo.mappingPro.mappingName + " x 1" }}
            </p>
            <p class="foodInfo" v-else>
              {{ couponInfo.mappingPro.mappingName + " x 1" }}
            </p>
          </div>
          <!-- 營銷活動 -->
          <div
            class="pre active"
            v-if="preItemInfo && pageParam.businessType == 2"
          >
            <p>
              {{ lanType == 1 ? preItemInfo.title : preItemInfo.titleEng }}
            </p>
            <p>-${{ couPrice }}</p>
          </div>
          <div class="pre active" v-else-if="preItemInfo && preItemInfo.item">
            <p v-if="$store.state.language == 1">
              滿{{ preItemInfo.price[0] }}減{{ Math.abs(preItemInfo.price[1]) }}
            </p>
            <p v-else>
              Save {{ $store.state.money[$store.state.area]
              }}{{ Math.abs(preItemInfo.price[1]) }} upon purchase of
              {{ $store.state.money[$store.state.area]
              }}{{ preItemInfo.price[0] }}
            </p>
            <p>
              <span>-{{ $store.state.money[$store.state.area] }}</span>
              {{ Math.abs(preItemInfo.price[1]) }}
            </p>
          </div>
          <div
            class="pre active"
            v-if="
              couponInfo &&
              couponInfo.voucherType != 3 &&
              couponInfo.voucherType != 5
            "
          >
            <p>
              {{ couponInfo.voucherName }}
            </p>
            <p>
              <span>-{{ $store.state.money[$store.state.area] }}</span>
              {{ priceByCoupon.price }}
            </p>
          </div>
          <div class="pre" v-if="$store.state.area == 1">
            <p>膠袋費</p>
            <p>
              <span>{{ $store.state.money[$store.state.area] }}</span> 1
            </p>
          </div>
          <div class="pre" v-if="pageParam.businessType == 1">
            <p>{{ new_QRDD_peiSongFei }}</p>
            <div class="sendMoney">
              <p>
                <!-- <span>{{ $store.state.money[$store.state.area] }}</span>
                {{ shopInfo.fee }} <i></i> -->
              </p>
              <p>
                <span>{{ $store.state.money[$store.state.area] }}</span>
                {{ pageParam.afterDeliveryFee }}
              </p>
            </div>
          </div>
        </div>
        <!-- <p class="lookAll" @click="lookAllFood">
          {{ btnText }}
          <van-icon :name="btnArrow" />
        </p> -->
      </div>
      <p class="totalPrice">
        {{ xiaoJiJinE }}：{{ $store.state.money[$store.state.area] }}
        <span>{{
          priceTotalInfo
            ? priceTotalInfo.subTotalPrice
            : Number(priceByCoupon.money) + Number(pageParam.afterDeliveryFee)
        }}</span>
      </p>
      <p class="title active" v-if="recommendPre && recommendPre.length">
        <i></i>
        {{ hungryMore }}
      </p>

      <div class="recomendList" v-if="recommendPre && recommendPre.length">
        <div class="recomendItemBox" :class="{ active: recomnedFoodAll }">
          <div
            class="recomendItem"
            v-for="(item, index) in recommendPre"
            :key="item"
          >
            <img :src="item.productLogo" alt="" />
            <p class="name">
              {{ lanType == 1 ? item.productName : item.productName }}
            </p>
            <div class="price">
              <p>
                <span>{{ $store.state.money[$store.state.area] }}</span>
                {{ item.price }}
              </p>
              <p class="priceLine">
                <span>{{ $store.state.money[$store.state.area] }}</span>
                {{ item.productPrice }} <i></i>
              </p>
            </div>
            <p class="foodNum">
              <span
                :class="{ active: orderPresNum[index + '_' + item.pid] > 0 }"
                @click="addRecommend(1, item, index, item.limitNum)"
                >-</span
              >
              <input
                :ref="'recommendFood_' + index + item.pid"
                :value="orderPresNum[index + '_' + item.pid] || 0"
                type="number"
                @focus="changeActive(1, index, item.pid, item.limitNum)"
                @blur="changeActive(2, index, item.pid, item.limitNum)"
                @input="addRecommend(3, item, index, item.limitNum)"
              />
              <span
                class="add"
                @click="addRecommend(2, item, index, item.limitNum)"
                >+</span
              >
            </p>
          </div>
        </div>
        <p class="lookAll" @click="lookAllRecomend">
          {{ recomnedBtnText }}
          <van-icon :name="recomnedArrow" />
        </p>
      </div>
      <p class="totalPrice" v-if="recommendPre && recommendPre.length">
        {{ xiaoJiJinE }}：{{ $store.state.money[$store.state.area] }}
        <span>{{
          priceTotalInfo ? priceTotalInfo.addTotalPrice : recommendPrice
        }}</span>
      </p>
    </div>
    <div class="orderCoupon" v-if="!isYuu">
      <div class="userSell">
        <p>{{ coupon }}</p>
        <p @click="selectCoupon" v-if="!couponInfo">
          <span class="coupon"
            >{{ lanType == 1 ? "可用優惠券 " : "You've "
            }}<span>
              {{ couponList.length }}
              <span v-if="lanType != 1" style="color: #000">coupon</span>
            </span>
            {{ lanType == 1 ? "張" : "" }}</span
          >
          <van-icon name="arrow" color="#ccc" />
        </p>
        <p class="couponInfoBox" @click="showRemoveVoucherFrames" v-else>
          <span class="coupon">{{
            couponInfo.voucherName || couponInfo.name
          }}</span>
          <i class="delIcon"></i>
        </p>
      </div>
      <div class="userSell couponCode active">
        <input type="text" v-model.trim="couponCode" :placeholder="youHuiMa" />
        <p @click="getCouponByCode">{{ apply }}</p>
      </div>
    </div>
    <div class="orderCoupon active" @click="selectPay">
      <div class="userSell active">
        <p>{{ Payment }}</p>
        <p>
          <span v-if="lanType == 1">{{ cardInfo.name || "選擇" }}</span>
          <span v-else>{{ cardInfo.name || "Select" }}</span>
          <van-icon name="arrow" color="#ccc" />
        </p>
      </div>
    </div>
    <div class="orderCoupon active">
      <div class="userSell active">
        <p>{{ huiYuanJiFen }}</p>
        <p class="score">
          <span
            v-if="!isYuu"
            @click="selctScore(1)"
            :class="{ active: scoreType == 1 }"
            >KFC</span
          >
          <span @click="selctScore(2)" :class="{ active: scoreType == 2 }"
            >yuu</span
          >
          <!-- <span @click="selctScore(0)" :class="{ active: scoreType == 0 }">{{
            wu
          }}</span> -->
        </p>
      </div>
      <div
        class="userSell active"
        v-if="scoreType == 1 && $store.state.userInfo && false"
      >
        <p>本次订单获得:</p>
        <p class="scoreGet">150 <span>積分</span></p>
      </div>
      <div
        class="userSell activeK"
        v-else-if="scoreType == 1 && !$store.state.userInfo"
      >
        <p>{{ new_QRDD_dengRuKFC }}</p>
        <p @click="loginJump">{{ new_QRDD_liJiDengRu }}</p>
      </div>
      <div class="yuuBox" v-show="scoreType == 2">
        <yuu
          ref="YUU"
          @bindYuu="getBind"
          :phone="paramInfoYk.phone || paramInfoYk.phoneOne"
        ></yuu>
      </div>
    </div>
    <div class="agreement" v-if="wink">
      <img
        @click="changeXY(1)"
        v-show="ageAgree"
        src="@/assets/icon/cycle.png"
        alt=""
      />
      <img
        @click="changeXY(1)"
        v-show="!ageAgree"
        src="@/assets/icon/icon@2x.png"
        alt=""
      />
      <p>{{ age18 }}</p>
    </div>
    <div class="agreement">
      <img
        @click="changeXY(2)"
        v-show="xieyiAgree"
        src="@/assets/icon/cycle.png"
        alt=""
      />
      <img
        @click="changeXY(2)"
        v-show="!xieyiAgree"
        src="@/assets/icon/icon@2x.png"
        alt=""
      />
      <p class="XYAGREE" v-html="agreeXieXi"></p>
    </div>
    <div class="payBtnBox">
      <p class="warn">{{ jieZhang }}</p>
      <div class="payBtn">
        <p>
          {{ new_QRDD_heJi }} {{ $store.state.money[$store.state.area] }}
          {{
            priceTotalInfo
              ? priceTotalInfo.totalPrice
              : Number(priceByCoupon.money) +
                Number(recommendPrice) +
                pageParam.afterDeliveryFee
          }}
        </p>
        <p @click="toPay">{{ checkout }}</p>
      </div>
    </div>
    <div class="useCoupon" v-show="showCoupon">
      <use-coupon
        :couponList="couponList"
        :productMenu="productMenu"
        :storePres="storePresAll"
        :businessType="pageParam.businessType"
        :totalPricePre="totalPricePre"
        :isCheck="true"
        @checkSelectCoupon="checkSelectCoupon"
        @closeCoupon="selectCoupon"
        @finishSelect="finishSelect"
      ></use-coupon>
    </div>
    <div class="warnBox" v-show="showWarn">
      <confirm-box
        @closeBox="cancel"
        @finish="finishWink"
        :show="false"
        :finishBtn="lanType == 1 ? '確認' : 'Confirm'"
        :title="winkA"
        :content="hanYouJiuJing"
      ></confirm-box>
    </div>
    <div class="warnBox" v-show="showErrorTime">
      <confirm-box
        :show="false"
        @finish="closeErrorTime"
        :title="QXZQTMD"
        :content="DQMDBZYYSJN"
      ></confirm-box>
    </div>
    <div class="cardBox">
      <card-select-box
        ref="cardSelectBox"
        @finishCard="finishCard"
        @cancle="cancleCard"
        @showBox="showCardSelectBox"
        :orderType="pageParam.businessType"
        :sendFunc="paramInfoYk.sendFunc"
        :book="pageParam.book"
        :phone="paramInfoYk.phone || paramInfoYk.phoneOne"
        v-show="showCard"
        :cancelParam="pageParam"
        :isAddressIsolation="paramInfoYk.switchSend"
        :isolatedPerson="paramInfoYk.bowladd"
      ></card-select-box>
    </div>
    <div class="placeBox">
      <myplace
        v-show="showPlace"
        @update="updatePlace"
        @select="selectPlce"
        :shopInfo="shopInfo"
        @closeMyPlace="closeMyPlace"
        :edit="false"
        :MS="true"
      ></myplace>
    </div>
    <!-- 時間選擇器 -->
    <div class="datePicker" :class="{ active: showPicker }">
      <van-picker
        ref="timePicker"
        :title="selectTime"
        :confirm="lanType == 1 ? '確認' : 'Confirm'"
        :cancel="lanType == 1 ? '取消' : 'Cancel'"
        :columns="columns"
        @confirm="finishTime"
        @cancel="showDatePicker"
        @change="changeTime"
      />
    </div>
    <div v-show="showPicker" @click="showDatePicker" class="pickerBg"></div>
    <div class="confirmBox">
      <food-confirm
        v-show="showConfirm"
        :title="confirmTitle"
        :subTitle="confirmTitleSubTitle"
        :subTitleE="confirmTitleSubTitleE"
        :type="1"
        @confirm="closePage"
        @cancle="closePage"
        :leftBtn="lanType == 1 ? '取消' : 'Cancel'"
        :rightBtn="lanType == 1 ? '確認' : 'Confirm'"
      ></food-confirm>
    </div>
    <!-- 移除优惠券弹窗 -->
    <div class="confirmBox">
      <food-confirm
        v-show="showRemoveVoucher"
        :title="removeVoucherTitle"
        :subTitle="removeVoucherTitleSubTitle"
        @confirm="cancelRemove"
        @cancle="confirmRemove"
        :leftBtn="lanType == 1 ? '返回' : 'Cancel'"
        :rightBtn="lanType == 1 ? '確認' : 'Confirm'"
      ></food-confirm>
    </div>
  </div>
</template>
<script>
import Topbar from "../../components/Topbar.vue";
import OrderConfirmVip from "../../components/food/OrderConfirmVip.vue";
import OrderConfirmNoVip from "../../components/food/OrderConfirmNoVip.vue";
import UseCoupon from "../../components/food/UseCoupon.vue";
import ConfirmBox from "../../components/common/CnfirmBox.vue";
import CardSelectBox from "../../components/food/CardSelectBox.vue";
import Yuu from "../../components/yuu/Yuu.vue";
import FoodConfirm from "../../components/food/OrderConfirm.vue";
import language from "../../assets/js/language.js";
import { postDC, postPaas } from "../../assets/utils/request";
import { encodeStr } from "../../assets/utils/encode";
import Myplace from "../../components/myplace/Myplace.vue";
import { wgs_gcj_encrypts } from "../../assets/utils/coordinate";
import { copyData } from "../../assets/utils/copy";
import { mapState } from "vuex";
import checkCouonType from "../../assets/utils/checkCouonType";
import setHashTag from "../../assets/utils/setHashTag";

export default {
  name: "ConfirmOrder",
  data() {
    return {
      showRemoveVoucher: false, //移除优惠券弹窗
      removeVoucherTitle: "", //移除优惠券弹窗标题
      removeVoucherTitleSubTitle: "", //移除优惠券弹窗标题副标题
      isVoucherByCode: false, //是否是使用兑换码兑换的优惠券
      afterDeliveryFee: 0, //配送费
      showErrorTime: false, //是否在營業時間
      languageType: "",
      lanType: this.$store.state.language, //语言类型
      languageList: {}, //语言列表
      ageAgree: true, //是否满18岁
      xieyiAgree: false, //是否同意协议
      btnText: "展開全部",
      allFood: false, //是否展开全部
      btnArrow: "arrow-down", //展开的箭头方向
      recomnedBtnText: "展開全部",
      recomnedFoodAll: false,
      recomnedArrow: "arrow-down",
      showCoupon: false, //是否显示优惠券
      couponInfo: null, //选择的优惠券信息
      showWarn: false, //含酒精饮料提示框
      hanYouJiuJing: "",
      showCard: false, //信用卡
      recomendList: [1, 2, 3, 4],
      pageParam: {}, //传到页面的参数
      storePres: {}, //优惠信息
      storePresAll: null, //所有的营销活动
      wink: false, //是否含酒精餐品
      phone: "", //游客身份是填写的手机号
      paramInfoYk: {}, //游客身份填写的信息
      couponList: [], //
      userIdYk: null, //游客身份 注册的游客id
      cardInfo: {}, //选择的信用卡信息
      scoreType: 0, //会员积分
      shopInfo: {}, //门店信息
      couponCode: "", //兑换优惠券
      productMenu: [], //餐单
      catNext: false, //点击确定按钮是否唏嘘下单
      rccommendInfo: {}, //推荐搭配的信息
      recommendPre: [], //推荐搭配
      orderPres: [], //满加活动选择的餐品
      orderPresNum: {}, //满加活动选择的餐品数量
      canClickPay: [], //是否可以點擊取付款
      confirmTitle: "",
      confirmTitleSubTitle: "",
      confirmTitleSubTitleE: "",
      showConfirm: false, //是否显示弹窗
      bindYuuSuc: false, //绑定yuu是否成功
      preMoney: 0, //優惠的錢
      discountPre: null, //折扣餐品
      deliveryTime: 0,
      yuuId: null,
      quoteTime: null,
      noUnfood: true,
      showPlace: false,
      selectPlaceInfo: null, //在当前页选择的地址
      preItem: null,
      showPicker: false,
      hours: [],
      minutesStart: [],
      minutesEnd: [],
      yuTime: null,
      storePresDelivery: null, //配送費優惠信息
      priceTotalInfo: null,
      pageLink: "",
      isGetPreCount: 0,
      columns: [
        // 第一列
        {
          values: [],
          defaultIndex: 0,
        },
        // 第二列
        {
          values: [],
          defaultIndex: 0,
        },
        {
          values: [],
          defaultIndex: 0,
        },
      ],
      // 静态文字
      new_QRDD_queRenDingDan: "",
      gengGaiDiZhi: "",
      age18: "",
      Payment: "",
      huiYuanJiFen: "",
      youHuiMa: "",
      apply: "",
      xiaoJiJinE: "",
      hungryMore: "",
      coupon: "",
      useCoupon: "",
      dingDanXiangQing: "",
      wu: "",
      agreeXieXi: "",
      jieZhang: "",
      checkout: "",
      selectTime: "",
      new_QRDD_heJi: "",
      new_QRDD_peiSongFei: "",
      new_QRDD_dengRuKFC: "",
      new_QRDD_liJiDengRu: "",
      winkA: "",
      GWC_del: "",
      HQXXSB: "",
      QXZQTMD: "",
      DQMDBZYYSJN: "",
      WFSYYHQ: "",
      BZPSFWN: "",
      SYMK: "",
      CDDLXBKY: "",
      BZSYSJFW: "",
      BZCD: "",
      HQPSFYHSB: "",
      QBDYUU: "",
      BFJKSB: "",
      QTYXY: "",
      QXZDZ: "",
      QTXDY: "",
      QTXLXR: "",
      SJHCW: "",
      QTXXXDZ: "",
      QXZZFFS: "",
      CPBZPSSJ: "",
      CPYCS: "",
      WDSYMK: "",
      WDSYMKSUBJE: "",
      WDSYMKSUBCP: "",
      WDSYMKSUB: "",
      JXXG: "",
      QWFK: "",
      TDCP: "",
      QSJG: "",
      BZCDHYSQ: "",
      BZDQSJDSUB: "",
      BZDQSJDSUBF: "",
      BZCDHYSQSUB: "",
      SXDZBZFWFWN: "",
      MZDDZKSYYZQSUB: "",
      MZDDZKSYYZQSUBE: "",
      YCYHQ: "",
      YCYHQSUB: "",
      QRDDMK: "",
      YCYHQCPSUB: "",
      BNYYCDCFS: "",
      XYQTYHQFK: "",
      YHMYSX: "",
      NKYGGSJ: "",
      BNZCCTSY: "",
      SCSJYG: "",
    };
  },
  methods: {
    showRemoveVoucherFrames() {
      this.removeVoucherTitle = this.YCYHQ; //移除优惠券弹窗标题
      this.removeVoucherTitleSubTitle = this.YCYHQSUB; //移除优惠券弹窗标题副标题
      if (
        this.couponInfo.voucherType == 3 ||
        this.couponInfo.voucherType == 5
      ) {
        this.removeVoucherTitleSubTitle = this.YCYHQCPSUB; //移除优惠券弹窗标题副标题
      }
      this.showRemoveVoucher = true;
    },
    //取消删除优惠券
    cancelRemove() {
      this.showRemoveVoucher = false;
    },
    // 确认删除优惠券
    confirmRemove() {
      this.cancelCoupon();
      this.showRemoveVoucher = false;
    },
    jumpAgree() {
      this.$router.push("/agreement");
    },
    editOrder() {
      var P = encodeURIComponent(localStorage.getItem("KFCOrderParam"));
      this.$router.push("/orderFood/" + P);
      this.$store.commit("changeShowCart", true);
    },
    changeActive(type, index, pid) {
      type == 1
        ? this.$refs["recommendFood_" + index + pid].classList.add("active")
        : this.$refs["recommendFood_" + index + pid].classList.remove("active");
    },
    loginJump() {
      this.$router.push({
        path: "/login",
        query: {
          page: 1,
        },
      });
    },
    changeData() {
      var cartList = copyData(this.$store.state.cart);
      for (var i = 0; i < cartList.length; i++) {
        if (cartList[i].type != 1 && cartList[i].typeId != 1) {
          cartList[i].selectedFood.num =
            cartList[i].num || cartList[i].selectedFood.num || 1;
          if (
            cartList[i].selectedFood.groups &&
            cartList[i].selectedFood.groups.length
          ) {
            cartList[i].selectedFood.groups = [
              cartList[i].selectedFood.groups[0],
            ];
          }
        }
      }
    },
    // 關閉營業時間時間彈唱
    closeErrorTime() {
      this.showErrorTime = false;
    },
    // 选择 时间确认按钮
    finishTime(value) {
      var timeMap = {
        Jan: "01",
        Feb: "02",
        Mar: "03",
        Apr: "04",
        May: "05",
        Jun: "06",
        Jul: "07",
        Aug: "08",
        Sept: "09",
        Oct: "10",
        Nov: "11",
        Dec: "12",
      };
      var splitType = "月";
      if (this.lanType != 1) {
        splitType = " ";
      }
      var arr = value[0].split(splitType);
      var year = new Date().getFullYear();
      var month = arr[0];
      var day = parseInt(arr[1]);
      if (this.lanType != 1) {
        month = arr[1];
        day = parseInt(arr[0]);
      }
      var hour = parseInt(value[1]);
      var minutes = parseFloat(value[2]) || 0;
      // if (month < 10) {
      //   month = "0" + month;
      // }
      if (hour < 10) {
        hour = "0" + hour;
      }
      if (day < 10) {
        day = "0" + day;
      }
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      var second =
        year + "/" + month + "/" + day + " " + hour + ":" + minutes + ":00";
      if (this.lanType != 1) {
        second =
          year +
          "/" +
          timeMap[month] +
          "/" +
          day +
          " " +
          hour +
          ":" +
          minutes +
          ":00";
      }
      this.showDatePicker();
      var secondTime = new Date(second).getTime();
      this.yuTime = second;
      this.checkCart(secondTime);
      this.pageParam.book = 2;
      this.pageParam.second = second;
    },
    // 组装预约时间
    setAdvanceTime(times) {
      var timeMap = {
        "01": "Jan",
        "02": "Feb",
        "03": "Mar",
        "04": "Apr",
        "05": "May",
        "06": "Jun",
        "07": "Jul",
        "08": "Aug",
        "09": "Sept",
        10: "Oct",
        11: "Nov",
        12: "Dec",
      };
      var month = "月";
      var day = "日";
      var hourText = "時";
      var minuteText = "分";
      if (this.lanType != 1) {
        month = "month";
        day = "day";
        hourText = "";
        minuteText = "";
      }
      var date = [];
      var hours = [];
      var minutes = [];
      for (var i = 0; i < times.length; i++) {
        var dateArr = times[i].date.split("-");
        var dateStr;
        if (this.lanType == 1) {
          dateStr = dateArr[1] + month + dateArr[2] + day;
        } else {
          dateStr = dateArr[2] + " " + timeMap[dateArr[1]];
        }
        date.push(dateStr);
        var time = times[i].times[0].split("~");
        var hourStart = Number(time[0].split(":")[0]);
        var hourEnd = Number(time[1].split(0)) || 24;
        var minuteStartOne = time[0].split(":")[1];
        var minuteEndtOne = time[1].split(":")[1];
        var minuteArr = [minuteStartOne, minuteEndtOne];
        minutes.push(minuteArr);
        var hourArr = [];
        for (var a = hourStart; a < hourEnd; a++) {
          var hourStr = a;
          if (a < 10) {
            hourStr = "0" + hourStr;
          }
          hourArr.push(hourStr + hourText);
        }
        hours.push(hourArr);
      }
      this.hours = hours;
      this.minutes = minutes;
      this.columns[0].values = date;
      this.columns[1].values = this.hours[0];
      var minutesShowArr = [this.minutes[0][0] + minuteText];
      var minText = Number(this.minutes[0][0]);
      for (var c = 0; c < 4; c++) {
        if (minText > 30) break;
        minText += 15;
        minutesShowArr.push(minText + minuteText);
      }
      var maxText = 0;
      var minutesShowArrOne = ["00"];
      for (var k = 0; k < 4; k++) {
        if (maxText > Number(this.minutes[0][1])) break;
        maxText += 15;
        minutesShowArrOne.push(maxText + minuteText);
      }
      this.columns[2].values = minutesShowArr;
      this.minutesStart = minutesShowArr;
      this.minutesEnd = minutesShowArrOne;
    },
    // 時間改變
    changeTime() {
      var index = this.$refs.timePicker.getIndexes();
      this.columns[1].values = this.hours[index[0]];
      var minutesArr = ["00", "15分", "30分", "45分"];
      if (this.lanType != 1) {
        minutesArr = ["00", "15", "30", "45"];
      }
      if (index[1] != 0 && index[1] != this.hours[index[0]].length - 1) {
        this.columns[2].values = minutesArr;
      } else if (index[1] == 0) {
        this.columns[2].values = this.minutesStart;
      } else {
        this.columns[2].values = this.minutesEnd;
      }
    },
    // 关闭时间选择器
    showDatePicker() {
      this.showPicker = !this.showPicker;
      var cancel = "取消";
      var finish = "確認";
      if (this.lanType != 1) {
        cancel = "Cancel";
        finish = "Confirm";
      }
      document.querySelector(".van-picker__cancel").innerHTML = cancel;
      document.querySelector(".van-picker__confirm").innerHTML = finish;
    },
    checkCart() {
      let time = new Date().getTime();
      if (this.pageParam.book == 2) {
        time = new Date(this.pageParam.second).getTime();
      }
      let nowTime = time;
      this.$nextTick(() => {
        var week = new Date(time).getDay();
        if (week == 0) {
          week = 7;
        }
        var cart = copyData(this.$store.state.cart);
        if (!cart || !cart.length) return;
        var cartArr = [];
        var cartIndex = [];
        var bigs = this.productMenu.bigs;
        for (var i = 0; i < cart.length; i++) {
          for (var k = 0; k < bigs.length; k++) {
            var smalls = bigs[k].smalls;
            for (var a = 0; a < smalls.length; a++) {
              var products = smalls[a].products;
              for (var b = 0; b < products.length; b++) {
                if (
                  cart[i].uid == products[b].uid ||
                  cart[i].pid == products[b].uid
                ) {
                  if (
                    !products[b].isDiscontinue &&
                    products[b].status == 1 &&
                    products[b].isInServiceTime
                  ) {
                    var year = new Date(time).getFullYear();
                    var month = new Date(time).getMonth() + 1;
                    var day = new Date(time).getDate();
                    var date = year + "/" + month + "/" + day;
                    if (this.pageParam.book == 2) {
                      date = this.pageParam.second.split(" ")[0];
                    }
                    if (cart[i].serviceWeeks && cart[i].serviceWeeks.length) {
                      if (cart[i].serviceWeeks.indexOf(week) > -1) {
                        if (
                          cart[i].serviceTimes &&
                          cart[i].serviceTimes.length
                        ) {
                          for (
                            var ST1 = 0;
                            ST1 < cart[i].serviceTimes.length;
                            ST1++
                          ) {
                            var startTime = new Date(
                              date + " " + cart[i].serviceTimes[ST1].start
                            ).getTime();
                            var endTime = new Date(
                              date + " " + cart[i].serviceTimes[ST1].end
                            ).getTime();

                            if (nowTime >= startTime && nowTime <= endTime) {
                              cartArr.push(cart[i]);
                              cartIndex.push(i);
                              break;
                            }
                          }
                        } else {
                          if (
                            this.shopInfo &&
                            this.shopInfo.businessTimes &&
                            this.shopInfo.businessTimes.length
                          ) {
                            for (
                              let be = 0;
                              be < this.shopInfo.businessTimes.length;
                              be++
                            ) {
                              var businessTimesStart = new Date(
                                date +
                                  " " +
                                  this.shopInfo.businessTimes[be].beginTime
                              ).getTime();
                              var businessTimesEnd = new Date(
                                date +
                                  " " +
                                  this.shopInfo.businessTimes[be].endTime
                              ).getTime();
                              if (
                                nowTime >= businessTimesStart &&
                                nowTime <= businessTimesEnd
                              ) {
                                cartArr.push(cart[i]);
                                cartIndex.push(i);
                                break;
                              }
                            }
                          } else {
                            cartArr.push(cart[i]);
                            cartIndex.push(i);
                          }
                        }
                      }
                    } else {
                      if (cart[i].serviceTimes && cart[i].serviceTimes.length) {
                        for (
                          var ST = 0;
                          ST < cart[i].serviceTimes.length;
                          ST++
                        ) {
                          var startTime1 = new Date(
                            date + " " + cart[i].serviceTimes[ST].start
                          ).getTime();
                          var endTime1 = new Date(
                            date + " " + cart[i].serviceTimes[ST].end
                          ).getTime();
                          if (nowTime >= startTime1 && nowTime <= endTime1) {
                            cartArr.push(cart[i]);
                            cartIndex.push(i);
                            break;
                          }
                        }
                      } else {
                        if (
                          this.shopInfo &&
                          this.shopInfo.businessTimes &&
                          this.shopInfo.businessTimes.length
                        ) {
                          for (
                            let be = 0;
                            be < this.shopInfo.businessTimes.length;
                            be++
                          ) {
                            var businessTimesStart1 = new Date(
                              date +
                                " " +
                                this.shopInfo.businessTimes[be].beginTime
                            ).getTime();
                            var businessTimesEnd1 = new Date(
                              date +
                                " " +
                                this.shopInfo.businessTimes[be].endTime
                            ).getTime();

                            if (
                              nowTime >= businessTimesStart1 &&
                              nowTime <= businessTimesEnd1
                            ) {
                              cartArr.push(cart[i]);
                              cartIndex.push(i);
                              break;
                            }
                          }
                        } else {
                          cartArr.push(cart[i]);
                          cartIndex.push(i);
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        var unCart = [];
        var unCartName = "";
        for (var c = 0; c < cart.length; c++) {
          if (cartIndex.indexOf(c) == -1) {
            unCart.push(cart[c]);
            if (this.$store.state.language == 1) {
              unCartName += cart[c].name + " ";
            } else {
              unCartName += cart[c].nameEn + " ";
            }
          }
        }
        if (unCart.length == 0) {
          this.rightBtnCan = false;
          this.noUnfood = true;
          return;
        }

        this.noUnfood = false;
        this.confirmTitle = unCartName + this.GWC_del;
        this.confirmTitleSubTitle = "";
        this.confirmTitleSubTitleE = "";
        this.showConfirm = true;
      });
    },
    closePage() {
      this.showConfirm = false;
    },
    showPlaceBox() {
      this.showPlace = !this.showPlace;
    },
    // 隐藏地址
    updatePlace(type) {
      this.showPlace = type;
    },
    closeMyPlace() {
      this.showPlace = false;
    },
    // 选择完的地址
    async selectPlce(info) {
      if (this.pageParam.businessType != 1) return;
      // 获取门店白名单地址
      let whiteListAddressParam = {
        actionName: "candao.storeOwn.listWhiteListAddress",
        content: {
          address: info.district,
        },
      };
      let whiteListAddress = await postDC("Action", whiteListAddressParam);
      // 如果不再白名单内要查看是否在配送范围内
      if (whiteListAddress.status == 1 && whiteListAddress.data.length == 0) {
        // 查看是否在配送范围内
        let deliveryRangeParam = {
          actionName: "candao.storeStandard.getStoreDeliveryRange",
          content: {
            storeId: this.pageParam.storeId,
            coordinate: [info.lng, info.lat],
          },
        };
        var coordinateRes = wgs_gcj_encrypts(info.lat, info.lng);
        deliveryRangeParam.content.coordinate = [
          coordinateRes.lng,
          coordinateRes.lat,
        ];
        var deliveryRange = await postDC("Action", deliveryRangeParam);
        if (deliveryRange && deliveryRange.data.length != 0) {
          var fee = deliveryRange.data[0].fee;
          var P =
            Number(this.priceByCoupon.money) + Number(this.recommendPrice);
          this.getDispatchPre(P, fee);
        }
      }
      // // 白名单调用成功 继续下一步
      if (whiteListAddress.status == 1) {
        if (whiteListAddress.data.length) {
          this.selectPlaceInfo = info;
        } else if (deliveryRange.data && deliveryRange.data.length) {
          this.selectPlaceInfo = info;
        } else {
          this.$toast(this.BZPSFWN);
        }
      } else {
        this.$toast(this.HQXXSB);
      }
      if (this.selectPlaceInfo) {
        this.$store.commit("savePlace", this.selectPlaceInfo);
        this.showPlace = false;
      }
    },
    // add on 埋點
    addOnDataLayerPush(info, num) {
      var T = this.$store.state.language;
      var ecommerce = {
        Item_name: info.productName,
        price: info.price,
        Item_list_name: "add_on",
        Item_list_ID: this.rccommendInfo.pid,
        quantity: num,
        item_id: info.pid,
        Index: info.index,
        Item_variant: T == 1 ? info.content : info.contentEng,
        rec_usage: "",
        Items_category: "",
      };
      window.dataLayer.push({
        event: "begin_checkout",
        ecommerce,
      });
    },
    // 推荐餐品的添加
    addRecommend(type, item, d, limitNum) {
      var nowNum = Number(this.orderPresNum[d + "_" + item.pid] || 0);
      if (type == 2 && limitNum != 0 && nowNum >= limitNum) return;
      var changeedNum;
      if (type == 1) {
        if (nowNum == 0) return;
        // 减号
        changeedNum = nowNum - 1;
      } else if (type == 2) {
        // 加号
        changeedNum = nowNum + 1;
      } else if (type == 3) {
        changeedNum = this.$refs["recommendFood_" + d + item.pid].value;
        if (changeedNum >= limitNum && limitNum != 0) {
          changeedNum = limitNum;
        }
        if (changeedNum < 0) {
          changeedNum = 0;
        }
      }
      this.addOnDataLayerPush(item, changeedNum);
      // this.$refs["recommendFood_" + d + item.pid].value = changeedNum;
      this.orderPresNum[d + "_" + item.pid] = changeedNum;
      var preInfo = {
        preId: this.rccommendInfo.pid,
        pid: item.pid,
        itemIndex: item.index,
        num: changeedNum,
        listRequirements: [],
        price: item.price,
        groups: [
          {
            num: changeedNum,
            pid: item.pid,
            typeId: item.productType,
            weight: 0,
          },
        ],
      };
      var hasIndex = -1;
      for (var i = 0; i < this.orderPres.length; i++) {
        if (
          this.orderPres[i].pid == item.pid &&
          this.orderPres[i].itemIndex == item.index
        ) {
          hasIndex = i;
        }
      }
      if (hasIndex >= 0) {
        if (changeedNum == 0) {
          this.orderPres.splice(hasIndex, 1);
        } else {
          this.orderPres[hasIndex] = preInfo;
        }
      } else {
        this.orderPres.push(preInfo);
      }
      if (this.pageParam.businessType == 1) {
        var P = Number(this.priceByCoupon.money) + Number(this.recommendPrice);
        this.getDispatchPre(P);
      }
      this.getPrice();
    },
    // 兑换优惠券
    async getCouponByCode() {
      if (!this.$store.state.userInfo) {
        if (!this.paramInfoYk.phone && !this.paramInfoYk.phoneOne) {
          var alertText = "請輸入手機號碼";
          if (this.$store.state.language != 1) {
            alertText = "Please provide mobile number";
          }
          return this.$toast(alertText);
        }
      }
      if (!this.couponCode) {
        return;
      }

      var params = {
        actionName: "candao.coupon.convertCouponByPromoteCode",
        content: {
          promoteCode: this.couponCode,
          posId: 396,
          phone: this.$store.state.userInfo
            ? this.$store.state.userInfo.phoneNo
            : this.paramInfoYk.phone || this.paramInfoYk.phoneOne,
        },
      };
      let result = await postDC("Action", params);
      if (result.status == 1 && result.data) {
        this.isVoucherByCode = true;
        if (this.login) {
          // if (result.msg == "该优惠券已存在") {
          //   return this.$toast("您已兌換該優惠券，請使用後在進行兌換");
          // } else {
          this.getCoupon(result.data);
          // }
        } else {
          this.cancelCoupon();
          let data = result.data.coupon;
          let voucher = {};
          switch (data.type) {
            case 1 || 6:
              data.type = 4;
              break;
            case 2:
              data.type = 3;
              break;
            case 3:
              data.type = 1;
              break;
            case 5:
              data.type = 2;
              break;
            case 9:
              data.type = 5;
              break;
          }
          // 9232595752cfq2
          // 9839993168vvc
          // 0609798629lzc
          voucher.voucherId = result.data.parentId;
          voucher.voucherCode = result.data.couponId;
          voucher.voucherName =
            this.$store.state.language == 1 ? data.name : data.nameEng;
          voucher.voucherType = data.type;
          voucher.imageUrl = data.couponImg;
          voucher.validBeginDate = data.startTime;
          voucher.validEndDate = data.endTime;
          voucher.voucherDiscount = data.discount * 100;
          voucher.voucherAmount = data.offsetMoney * 100;
          voucher.minUsage = data.minMoney * 100; // 满多少钱可以使用
          voucher.voucherProductCode =
            voucher.voucherType == 5 ? [data.fixProductId] : data.pids;
          voucher.minUsageProductCode = data.needBuyProductIds;
          voucher.voucherCategoryCode = data.orderType;
          voucher.voucherHashtag = null;
          voucher.voucherHashtagCode = data.couponcanUseTime;
          voucher.pointsoftProducts = [result.data.parentCouponMappingCode];
          voucher.availableShops = data.exceptionStoreIds;
          voucher.canDaoAvailableShops = data.storeIds;
          voucher.pointsoftExclProducts = data.exceptionPids;
          voucher.canDaoProductType = data.needProductType;
          voucher.canDaoExclProductType = data.expProductType;
          voucher.canDaoFixPrice = data.fixPrice;
          voucher.canDaoPriceType = data.priceType;
          voucher.voucherCalType = data.needType;
          voucher.includedBoxedMealFee = data.isContainMealFee;
          // console.log(voucher);
          // 优惠券在当前业务类型是否可用
          if (data.orderType && data.orderType.length) {
            if (
              data.orderType.indexOf(this.pageParam.businessType) < 0 &&
              data.orderType.indexOf(Number(this.pageParam.businessType)) < 0
            ) {
              this.confirmTitle = this.BNYYCDCFS;
              this.confirmTitleSubTitle = this.XYQTYHQFK;
              this.confirmTitleSubTitleE = "";
              this.showConfirm = true;
              return;
            }
          }
          let nowTime = new Date().getTime();
          if (this.pageParam.book == 2) {
            nowTime = new Date(this.pageParam.second).getTime();
          }
          // 优惠券有效期起始时间
          if (data.startTime) {
            let starts = data.startTime.split(" ");
            let startDate = starts[0].split("-");
            startDate = startDate.join("/");
            let startTime = new Date(startDate + " " + starts[1]).getTime();
            if (startTime > nowTime) {
              this.confirmTitle = this.WFSYYHQ;
              this.confirmTitleSubTitle = "";
              this.confirmTitleSubTitleE = "";
              this.showConfirm = true;
              return;
            }
          }
          // 优惠券过期时间
          if (data.endTime) {
            let ends = data.endTime.split(" ");
            let endDate = ends[0].split("-");
            endDate = endDate.join("/");
            let endTime = new Date(endDate + " " + ends[1]).getTime();
            if (endTime < nowTime) {
              this.confirmTitle = this.YHMYSX;
              this.confirmTitleSubTitle = this.XYQTYHQFK;
              this.confirmTitleSubTitleE = "";
              this.showConfirm = true;
              return;
            }
          }
          // 优惠券是否在当天可用
          if (data.canUseWeek && data.canUseWeek.length) {
            let day = new Date().getDay() + 1;
            let dayHas;
            if (data.canUseWeek.indexOf(day) > -1) {
              dayHas = true;
            }
            if (!dayHas) {
              this.confirmTitle = this.WDSYMK;
              this.confirmTitleSubTitle = this.YHMZDSJSY;
              this.confirmTitleSubTitleE = this.NKYGGSJ;
              this.showConfirm = true;
              return;
            }
          }
          // 优惠券是否在当前时间段可用
          if (data.canUseTime && data.canUseTime.length) {
            let timeHas = false;
            let year = new Date().getFullYear();
            let month = new Date().getMonth() + 1;
            let days = new Date().getDate();
            data.canUseTime.forEach((val) => {
              let T = val.split("-");
              let Tarr = [year, month, days].join("/");
              let startTime = new Date(Tarr + " " + T[0]);
              let endTime = new Date(Tarr + " " + T[1]);
              if (nowTime >= startTime && nowTime <= endTime) {
                timeHas = true;
                return;
              }
            });
            if (!timeHas) {
              this.confirmTitle = this.WDSYMK;
              this.confirmTitleSubTitle = this.YHMZDSJSY;
              this.confirmTitleSubTitleE = this.NKYGGSJ;
              this.showConfirm = true;
              return;
            }
          }
          let isCandao = true;
          if (voucher.voucherCalType == null) {
            isCandao = false;
          }
          let availableShops = isCandao
            ? voucher.canDaoAvailableShops
            : voucher.availableShops;
          let storeIdOrCode = isCandao
            ? this.shopInfo.storeId
            : this.shopInfo.extraStoreId;
          if (
            availableShops &&
            availableShops.length > 0 &&
            availableShops.indexOf(storeIdOrCode) < 0
          ) {
            this.confirmTitle = this.BNZCCTSY;
            this.confirmTitleSubTitle = this.XYQTYHQFK;
            this.confirmTitleSubTitleE = "";
            this.showConfirm = true;
            return false;
          }
          if (voucher.voucherType == 5 || voucher.voucherType == 3) {
            let hasProduct = false;
            // 如果是餐品券判断当前门店COPY分类是否有次餐品
            if (
              voucher.voucherProductCode &&
              voucher.voucherProductCode.length
            ) {
              let bigs = this.productMenu.bigs;
              for (let a = 0; a < bigs.length; a++) {
                let smalls = bigs[a].smalls;
                for (let b = 0; b < smalls.length; b++) {
                  if (smalls[b].name == "COPY" || smalls[b].nameEn == "COPY") {
                    let products = smalls[b].products;
                    for (let c = 0; c < products.length; c++) {
                      if (products[c].uid == voucher.voucherProductCode[0]) {
                        hasProduct = true;
                        voucher.mappingPro = copyData(products[c]);
                        voucher.copyPro = copyData(products[c]);
                        if (isCandao) {
                          voucher.mappingPro.mappingPrice =
                            voucher.canDaoFixPrice;
                          voucher.copyPro.price = voucher.canDaoFixPrice;
                        } else {
                          voucher.mappingPro.mappingPrice = products[c].price;
                          voucher.copyPro.price = products[c].price;
                        }
                        break;
                      }
                    }
                  }
                }
              }
              if (!hasProduct) {
                this.confirmTitle = this.BZCDHYSQ;
                this.confirmTitleSubTitle = this.XYQTYHQFK;
                this.confirmTitleSubTitleE = "";
                this.showConfirm = true;
                return;
              }
            } else {
              this.confirmTitle = this.WFSYYHQ;
              this.confirmTitleSubTitle = "";
              this.confirmTitleSubTitleE = "";
              this.showConfirm = true;
              return;
            }
          }
          let checkRes = await checkCouonType(
            voucher,
            this.pageParam.businessType,
            this.storePresAll,
            true
          );
          if (!checkRes.usage) {
            if (checkRes.type == 1) {
              this.confirmTitle = this.WDSYMK;
              this.confirmTitleSubTitle = this.WDSYMKSUBJE;
              this.confirmTitleSubTitleE = this.XYQTYHQFK;
            } else {
              this.confirmTitle = this.WDSYMK;
              this.confirmTitleSubTitle = this.WDSYMKSUBCP;
              this.confirmTitleSubTitleE = this.XYQTYHQFK;
            }
            this.showConfirm = true;
            return false;
          }
          if (voucher.voucherType == 3 || voucher.voucherType == 5) {
            if (voucher.copyPro.isSoldOut) {
              this.confirmTitle = this.BZCDHYSQ;
              this.confirmTitleSubTitle = this.XYQTYHQFK;
              this.confirmTitleSubTitleE = "";
              this.showConfirm = true;
              return;
            }
          }
          this.$store.commit("changeClearCou", false);
          this.couponInfo = voucher;
          this.$store.commit("saveCouponInfo", voucher);
          this.getPrice();
          if (voucher.voucherType == 3 || voucher.voucherType == 5) {
            voucher.copyPro.isCouponPro = true;
            voucher.copyPro.isCouponProType = voucher.voucherType;
            voucher.copyPro.isCandao = isCandao;
            this.lookDetail(voucher.copyPro);
          }
        }
      } else {
        if (result.msg && result.msg != "CodeErr") {
          this.confirmTitle = this.YHMYSX;
          this.confirmTitleSubTitle = this.XYQTYHQFK;
          this.confirmTitleSubTitleE = "";
          this.showConfirm = true;
        }
      }
    },
    // 选择会员积分
    selctScore(type) {
      this.scoreType = type;
    },
    // 同意协议
    changeXY(type) {
      if (type == 1) {
        this.ageAgree = !this.ageAgree;
      } else if (type == 2) {
        this.xieyiAgree = !this.xieyiAgree;
      }
    },
    // 食品查看全部
    lookAllFood() {
      this.allFood = !this.allFood;
      this.btnText = this.allFood
        ? this.languageList.collapse[this.languageType]
        : this.languageList.more[this.languageType];
      this.btnArrow = this.allFood ? "arrow-up" : "arrow-down";
    },
    //推荐食物查看全部
    lookAllRecomend() {
      this.recomnedFoodAll = !this.recomnedFoodAll;
      this.recomnedBtnText = this.recomnedFoodAll
        ? this.languageList.collapse[this.languageType]
        : this.languageList.more[this.languageType];
      this.recomnedArrow = this.recomnedFoodAll ? "arrow-up" : "arrow-down";
    },
    // 填写的手机号
    getPhone(phone) {
      this.phone = phone;
    },
    // 游客身份填写的信息
    getParmaInfo(param) {
      for (var key in param) {
        this.paramInfoYk[key] = param[key];
      }
      if (this.selectPlaceInfo || this.$store.state.placeInfo) {
        this.selectPlaceInfo = null;
      }
    },
    // 选择可使用的优惠券
    selectCoupon() {
      this.showCoupon = !this.showCoupon;
    },
    // 选择的优惠券信息 如果选择的是赠送餐品券 获取餐品的id
    async finishSelect(info) {
      if (info.voucherType == 3 || info.voucherType == 5) {
        if (info.copyPro.isSoldOut) {
          this.couponErrorTitle = this.BZCDHYSQ;
          this.couponErrorSubTitle = this.XYQTYHQFK;
          this.confirmTitleSubTitleE = "";
          this.showConfirm = true;
          this.cancelCoupon();
          this.deleteCouPro();
          return;
        }
      }
      this.dataLayerPushCoupon(info);
      this.showCoupon = !this.showCoupon;
      this.couponInfo = info;
      // 保存优惠券信息
      this.$store.commit("changeClearCou", false);
      this.$store.commit("saveCouponInfo", this.couponInfo);
      this.getPrice();
      if (info.voucherType == 3 || info.voucherType == 5) {
        info.copyPro.isCouponPro = true;
        info.copyPro.isCouponProType = info.voucherType;
        this.lookDetail(info.copyPro);
      }
    },
    // 埋点
    dataLayerPushCoupon(info) {
      window.dataLayer.push({
        event: "coupon_select",
        ecommerce: {
          coupon: info.voucherName,
          coupon_ID: info.voucherId,
          expiary_date: info.validEndDate,
          coupon_variant: info.voucherName + info.voucherDesc,
        },
      });
    },
    // 查看食物详情
    lookDetail(food) {
      if (food.isSoldOut) {
        this.confirmTitle = this.BZCDHYSQ;
        this.confirmTitleSubTitle = this.QRDDMK;
        this.confirmTitleSubTitleE = "";
        this.showConfirm = true;
        this.cancelCoupon();
        this.deleteCouPro();
        return;
      }
      food.storeId = this.pageParam.storeId;
      food.menuId = this.productMenu.menuId;
      var params = JSON.stringify(food);
      params = encodeURIComponent(params);
      if ((food.type == 1) & !food.property) return;
      this.$router.push("/foodDetail/" + params);
    },
    // 获取配送费优惠
    async getDispatchPre(price, fee) {
      if (this.pageParam.businessType != 1) return;
      if (!this.shopInfo.fee && this.shopInfo.fee != 0) return;
      let params = {
        actionName: "candao.preferential.getDispatchPre",
        content: {
          storeId: this.pageParam.storeId,
          products: this.foodSku.proAll,
          price: price,
          orderType: this.pageParam.businessType,
          menuId: this.productMenu.menuId,
          deliveryFee: this.shopInfo.fee || 0,
        },
      };
      if (fee) {
        params.content.deliveryFee = fee;
      }
      let result = await postDC("Action", params);
      if (result.status == 1) {
        if (result.data) {
          if (this.pageParam.businessType == 1) {
            this.pageParam.afterDeliveryFee = result.data.afterDeliveryFee;
          }
        }
      }
    },
    // 取消选的优惠券
    cancelCoupon() {
      this.couponInfo = null;
      this.$store.commit("saveCouponInfo", null);
      this.$store.commit("changeClearCou", true);
      this.deleteCouPro();
      this.getPrice();
    },
    //
    getBind(id) {
      this.yuuId = id;
      this.bindYuuSuc = true;
    },
    // 重新获取门店信息判断当前预约时间是否还在预约时间内
    async getNewAppointime() {
      var coordinate = [];
      var placeInfo = this.selectPlaceInfo || this.$store.state.placeInfo;
      if (placeInfo) {
        coordinate = [
          this.$store.state.placeInfo.lng,
          this.$store.state.placeInfo.lat,
        ];
      }
      if (this.isYuu && this.yuuInfo.addressNow) {
        coordinate = [
          this.yuuInfo.addressNow.postalCode.lng,
          this.yuuInfo.addressNow.postalCode.lat,
        ];
      }
      let params = {
        actionName: "candao.storeStandard.getStore",
        langType: this.$store.state.language,
        content: {
          storeId: this.pageParam.storeId,
          businessType: this.pageParam.businessType,
          coordinate,
        },
      };
      let storeDetail = await postDC("Action", params);
      if (storeDetail.status != 1) return false;
      let appointment = storeDetail.data.appointmentTime;
      if (this.pageParam.businessType != 1) {
        appointment = storeDetail.data.takeSelfTime;
      }
      let bookTime = new Date(this.second).getTime();
      let can = false;
      for (let i = 0; i < appointment.length; i++) {
        let date = appointment[i].date.split("-").join("/");
        let times = appointment[i].times;
        for (let t = 0; t < times.length; t++) {
          let time = times[t].split("~");
          let start = new Date(date + " " + time[0]).getTime();
          let end = new Date(date + " " + time[1]).getTime();
          if (bookTime >= start && bookTime <= end) {
            can = true;
            break;
          }
        }
        if (can) break;
      }
      return can;
    },
    // 去付款
    async toPay() {
      if (this.pageParam.book == 2) {
        let can = await this.getNewAppointime();
        if (can) {
          this.confirmTitle = this.SCSJYG;
          this.confirmTitleSubTitle = "";
          this.confirmTitleSubTitleE = "";
          this.showConfirm = true;
          return;
        }
      }
      if (!this.canToPay) return;
      this.dataLayerPushBegin();
      if (!this.isYuu) {
        if (!this.$store.state.userInfo || !this.$store.state.userInfo.yuuId) {
          if (this.scoreType == 2 && !this.bindYuuSuc) {
            return this.$toast(this.QBDYUU);
          }
        }
      }

      var nowTime = new Date().getTime();
      var year = new Date().getFullYear();
      var month = new Date().getMonth() + 1;
      var day = new Date().getDate();
      if (this.pageParam.book == 2) {
        nowTime = new Date(this.pageParam.second).getTime();
        year = new Date(this.pageParam.second).getFullYear();
        month = new Date(this.pageParam.second).getMonth() + 1;
        day = new Date(this.pageParam.second).getDate();
      }
      var date = year + "/" + month + "/" + day;
      if (
        this.shopInfo &&
        this.shopInfo.businessTimes &&
        this.shopInfo.businessTimes.length
      ) {
        let can = false;
        for (let be = 0; be < this.shopInfo.businessTimes.length; be++) {
          var businessTimesStart = new Date(
            date + " " + this.shopInfo.businessTimes[be].beginTime
          ).getTime();
          var businessTimesEnd = new Date(
            date + " " + this.shopInfo.businessTimes[be].endTime
          ).getTime();
          if (nowTime >= businessTimesStart && nowTime <= businessTimesEnd) {
            can = true;
          }
        }
        if (!can) {
          return this.$toast("不在營業時間");
        }
      }
      if (this.canClickPay.length < 3) {
        return this.$toast(this.BFJKSB);
      }
      for (var m = 0; m < this.canClickPay.length; m++) {
        if (this.canClickPay[m] != 1) {
          return this.$toast(this.BFJKSB);
        }
      }
      var phoneAlert =
        this.$store.state.language == 1
          ? "請輸入手機號碼"
          : "Please provide mobile number";
      if (!this.noUnfood) {
        return this.$toast(this.CPBZPSSJ);
      }
      if (!this.cardInfo.name) {
        return this.$toast(this.QXZZFFS);
      }
      if (!this.$store.state.userInfo) {
        if (
          this.pageParam.businessType == 1 ||
          this.pageParam.businessType == 2
        ) {
          if (
            !this.paramInfoYk.detailPlace &&
            this.pageParam.businessType != 2
          ) {
            return this.$toast(this.QTXXXDZ);
          }
          if (!this.paramInfoYk.phone && !this.paramInfoYk.phoneOne) {
            return this.$toast(phoneAlert);
          }
          if (this.paramInfoYk.phone && this.paramInfoYk.phone.length != 8) {
            return this.$toast(this.SJHCW);
          }
          if (
            this.paramInfoYk.phoneOne &&
            this.paramInfoYk.phoneOne.length != 8
          ) {
            return this.$toast(this.SJHCW);
          }
          // if (!this.paramInfoYk.house) {
          //   return this.$toast("请填写座数");
          // }
          // if (!this.paramInfoYk.floor) {
          //   return this.$toast("请填写层数");
          // }
          // if (!this.paramInfoYk.doorNum) {
          //   return this.$toast("请填写单位");
          // }
          // if (!this.paramInfoYk.concatPerson) {
          //   return this.$toast(this.QTXLXR);
          // }
        } else {
          if (!this.paramInfoYk.phoneOne && !this.paramInfoYk.phone) {
            return this.$toast(phoneAlert);
          }
        }
        if (!this.paramInfoYk.concatPerson) {
          return this.$toast(this.QTXLXR);
        }
        if (!this.paramInfoYk.email && !this.paramInfoYk.emailOne) {
          return this.$toast(this.QTXDY);
        }
      } else {
        if (
          this.pageParam.businessType == 1 &&
          !this.$store.state.placeInfo &&
          !this.selectPlaceInfo
        ) {
          return this.$toast(this.QXZDZ);
        }
        if (
          this.$store.state.placeInfo &&
          !this.selectPlaceInfo &&
          !this.$store.state.placeInfo.contactPerson &&
          this.pageParam.businessType == 1
        ) {
          if (!this.paramInfoYk.detailPlace) {
            return this.$toast(this.QTXXXDZ);
          }
          if (!this.paramInfoYk.concatPerson) {
            return this.$toast(this.QTXLXR);
          }
          if (!this.paramInfoYk.email && !this.paramInfoYk.emailOne) {
            return this.$toast(this.QTXDY);
          }
        }
      }
      // 是否含有酒精
      if (this.wink && !this.catNext) {
        this.catNext = true;
        this.showWarn = true;
        return;
      }
      if (this.wink && this.ageAgree) {
        return this.$toast(this.QTYXY);
      }
      if (this.ageAgree && this.xieyiAgree) {
        return this.$toast(this.QTYXY);
      }
      var preInfo = {};

      if (this.pageParam.businessType == 2) {
        if (this.preItemInfo) {
          preInfo[this.storePres.pid] = {};
          preInfo[this.storePres.pid][this.preItemInfo.items[0].index] =
            this.preItemInfo.items[0].num;
        }
      } else if (this.preItemInfo && this.preItemInfo.item) {
        preInfo[this.storePres.pid] = {};
        preInfo[this.storePres.pid][this.preItemInfo.item.index] =
          this.preItemInfo.item.num;
      }
      if (this.orderPres.length) {
        preInfo[this.rccommendInfo.pid] = {};
        this.orderPres.forEach((val) => {
          preInfo[this.rccommendInfo.pid][val.itemIndex] = val.num;
        });
      }
      if (this.discountPre) {
        preInfo[this.discountPre.pid] = {};
        this.discountPre.items.forEach((P) => {
          preInfo[this.discountPre.pid][P.index] = P.num;
        });
      }
      var selfGetDT = 0;
      if (this.pageParam.book != 2) {
        var timeNow =
          parseInt(this.quoteTime || 30) * 60 * 1000 + new Date().getTime();
        var years = new Date(timeNow).getFullYear();
        var months = new Date(timeNow).getMonth() + 1;
        var days = new Date(timeNow).getDate();
        var hour = new Date(timeNow).getHours();
        var minutes = new Date(timeNow).getMinutes();
        var miao = new Date(timeNow).getSeconds();
        if (months < 10) {
          months = "0" + months;
        }
        if (days < 10) {
          days = "0" + days;
        }
        if (hour < 10) {
          hour = "0" + hour;
        }
        if (minutes < 10) {
          minutes = "0" + minutes;
        }
        if (miao < 10) {
          miao = "0" + miao;
        }
        selfGetDT =
          years +
          "-" +
          months +
          "-" +
          days +
          " " +
          hour +
          ":" +
          minutes +
          ":" +
          miao;
      } else {
        var yuYueTime =
          this.pageParam.book == 2 ? this.pageParam.second : this.sendTime;
        var yuYueTimeArr = yuYueTime.split(" ");
        var yuYueDate = yuYueTimeArr[0].split("/");
        var yuYueHMS = yuYueTimeArr[1].split(":");
        if (yuYueDate[1] < 10 && yuYueDate[1].length < 2) {
          yuYueDate[1] = "0" + yuYueDate[1];
        }
        if (yuYueDate[2] < 10 && yuYueDate[2].length < 2) {
          yuYueDate[2] = "0" + yuYueDate[2];
        }
        if (yuYueHMS[0] < 10 && yuYueHMS[0].length < 2) {
          yuYueHMS[0] = "0" + yuYueHMS[0];
        }
        if (yuYueHMS[1] < 10 && yuYueHMS[1].length < 2) {
          yuYueHMS[1] = "0" + yuYueHMS[1];
        }
        // if (yuYueHMS[2] < 10 && yuYueHMS[2].length < 2) {
        //   yuYueHMS[2] = "0" + yuYueHMS[2];
        // }
        var selfGetDate =
          yuYueDate[0] + "-" + yuYueDate[1] + "-" + yuYueDate[2];
        var selfGetTime = yuYueHMS[0] + ":" + yuYueHMS[1] + ":" + "00";
        selfGetDT = selfGetDate + " " + selfGetTime;
      }

      var params = {
        actionName: "candao.orderOwn.postOrder",
        content: {
          storeId: this.pageParam.storeId,
          menuId: this.pageParam.menuId,
          fromTypeNote: "H5",
          type:
            this.pageParam.businessType == 1 && this.pageParam.book == 2
              ? 4
              : this.pageParam.businessType,
          selfGetTime: this.pageParam.businessType != 3 ? selfGetDT : "", //预约送达时间 格式：yyyy-MM-dd HH:mm:ss
          payType: 1, //支付方式
          name: this.paramInfoYk.concatPerson || "yk", //联系人
          phone: this.paramInfoYk.phone || this.paramInfoYk.phoneOne, //联系电话，
          tableNum: 1, //堂食桌号
          book: this.pageParam.book || 1,
          products: this.foodSku.products,
          pres: preInfo || {},
          // orderPres: this.orderPres,
          randomCode:
            new Date().getFullYear() +
            "625163030" +
            parseInt(Math.random() * 1000), //随机码 格式：yyyyMMddHHmmssSSS
        },
      };
      if (this.isYuu) {
        params.content.fromTypeNote = "YUU-H5";
      } else if (this.$store.state.app) {
        params.content.fromTypeNote = "KFC-H5";
      } else if (this.isPC) {
        params.content.fromTypeNote = "Web";
      }
      if (this.$store.state.userInfo) {
        params.content.thirdUserId = this.$store.state.userInfo.userId;
      }
      if (this.pageParam.businessType == 2) {
        params.content.address = this.shopInfo.storeAddress;
      } else if (this.pageParam.businessType == 1) {
        params.content.address = this.paramInfoYk.detailPlace || " ";
      }
      if (this.couponInfo) {
        var price = 0;
        // if (this.login) {
        if (
          this.couponInfo.voucherType == 1 ||
          this.couponInfo.voucherType == 4
        ) {
          price = Number(this.couponInfo.voucherAmount) / 100;
          price = price.toFixed(2);

          price = "-" + price;
        } else if (this.couponInfo.voucherType == 2) {
          price =
            this.totalPricePre *
            (Number(this.couponInfo.voucherDiscount) / 10000);
          price = price.toFixed(2);

          price = "-" + price;
        } else if (
          this.couponInfo.voucherType == 3 ||
          this.couponInfo.voucherType == 5
        ) {
          if (this.couponInfo.voucherAmount) {
            price = Number(this.couponInfo.voucherAmount) / 100;
          } else if (this.couponInfo.voucherDiscount) {
            price =
              this.totalPricePre *
              (Number(this.couponInfo.voucherDiscount) / 10000);
          } else if (this.couponInfo.voucherProductCode) {
            price = this.couponInfo.mappingPro.mappingPrice;
          }
        }
        // } else {
        //   if (this.couponInfo.type == 1) {
        //     console.log(1);
        //   } else if (this.couponInfo.type == 2) {
        //     console.log(2);
        //   } else if (this.couponInfo.type == 3) {
        //     price = Number(this.couponInfo.voucherAmount) / 100;
        //     price = price.toFixed(2);

        //     price = "-" + price;
        //   } else if (this.couponInfo.type == 5) {
        //     price =
        //       this.totalPricePre * (Number(this.couponInfo.discount) / 100);
        //     price = price.toFixed(2);
        //     price = "-" + price;
        //   }
        // }
        params.content.memberPreferentials = [
          {
            childType: 3,
            content: this.couponInfo.voucherName || this.couponInfo.couponName,
            contentEng: "",
            pid: this.couponInfo.voucherCode || this.couponInfo.cid,
            prePrice: this.priceByCoupon.price
              ? "-" + this.priceByCoupon.price
              : 0,
            count: 1,
            type: 7,
            preProducts: [],
          },
        ];
        // if (
        //   this.couponInfo.voucherType == 1 ||
        //   this.couponInfo.voucherType == 2
        // ) {
        //   params.content.memberPreferentials[0].pid =
        //     this.couponInfo.pointsoftProducts[0];
        // }
        if (
          // this.login &&
          this.couponInfo.voucherType == 3 ||
          this.couponInfo.voucherType == 5
        ) {
          var product = {
            pid: this.couponInfo.mappingPro.mappingId || 0,
            num: 1,
            price: price || 0,
          };
          console.log(product);
          // this.foodSku.preProducts[0].mealDiscountPrice = price;
          if (this.foodSku.preProducts.length) {
            params.content.memberPreferentials[0].preProducts =
              this.foodSku.preProducts;
          } else if (
            this.couponInfo.copyPro &&
            this.couponInfo.copyPro.type == 1 &&
            !this.couponInfo.copyPro.property
          ) {
            var singlePro = [];
            var singleObj = {
              num: 1,
              pid: this.couponInfo.copyPro.uid,
              price: this.couponInfo.copyPro.price,
              typeId: 1,
              weight: 0,
            };
            singlePro.push(singleObj);
            params.content.memberPreferentials[0].preProducts = singlePro;
          }
        } else if (!this.login && this.couponInfo.type == 2) {
          var productE = {
            pid: this.couponInfo.mappingPro.mappingId || 0,
            num: 1,
            price: this.couponInfo.mappingPro.mappingPrice || 0,
          };
          params.content.memberPreferentials[0].preProducts[0] = productE;
        }
      }
      // 如果登录了 并且手动选择了地址 用选择的地址
      var placeInfo = this.selectPlaceInfo || this.$store.state.placeInfo;
      if (placeInfo) {
        var coordinateRes = wgs_gcj_encrypts(placeInfo.lat, placeInfo.lng);
        params.content.longitude = coordinateRes.lng;
        params.content.latitude = coordinateRes.lat;
        var addressFloor = this.$store.state.language == 1 ? "樓" : "floor";
        var addressBlock = this.$store.state.language == 1 ? "座" : "block";
        var addressFlat = this.$store.state.language == 1 ? "單元" : "flat";
        if (this.$store.state.userInfo) {
          if (this.selectPlaceInfo) {
            params.content.address =
              placeInfo.building + " " + placeInfo.district;

            if (placeInfo.street) {
              params.content.address += placeInfo.street;
            }
            if (placeInfo.block) {
              params.content.address += placeInfo.block + addressBlock;
            }
            if (placeInfo.floor) {
              params.content.address += placeInfo.floor + addressFloor;
            }
            if (placeInfo.flat) {
              params.content.address += placeInfo.flat + addressFlat;
            }
          } else {
            params.content.address = placeInfo.building;
            if (this.$store.state.placeInfo.contactPerson) {
              if (this.$store.state.placeInfo.street) {
                params.content.address += this.$store.state.placeInfo.street;
              }
              if (this.$store.state.placeInfo.block) {
                params.content.address += this.$store.state.placeInfo.block;
                params.content.address += addressBlock;
              }
              if (this.$store.state.placeInfo.floor) {
                params.content.address += this.$store.state.placeInfo.floor;
                params.content.address += addressFloor;
              }
              if (this.$store.state.placeInfo.flat) {
                params.content.address += this.$store.state.placeInfo.flat;
                params.content.address += addressFlat;
              }
            } else {
              if (this.paramInfoYk.detailPlace) {
                params.content.address += this.paramInfoYk.detailPlace;
              }
              if (this.paramInfoYk.house) {
                params.content.address += this.paramInfoYk.house + addressBlock;
              }
              if (this.paramInfoYk.floor) {
                params.content.address += this.paramInfoYk.floor + addressFloor;
              }
              if (this.paramInfoYk.doorNum) {
                params.content.address +=
                  this.paramInfoYk.doorNum + addressFlat;
              }
            }
          }
          if (!this.paramInfoYk.concatPerson && !this.isYuu) {
            params.content.name =
              placeInfo.contactPerson || this.$store.state.userInfo.firstName;
          }
          if (
            !this.paramInfoYk.phone &&
            !this.paramInfoYk.phoneOne &&
            !this.isYuu
          ) {
            params.content.phone =
              placeInfo.phoneNo || this.$store.state.userInfo.phoneNo;
          }
        } else {
          params.content.address = placeInfo.building;
          if (this.paramInfoYk.detailPlace) {
            params.content.address += this.paramInfoYk.detailPlace;
          }
          if (this.paramInfoYk.house) {
            params.content.address += this.paramInfoYk.house + addressBlock;
          }
          if (this.paramInfoYk.floor) {
            params.content.address += this.paramInfoYk.floor + addressFloor;
          }
          if (this.paramInfoYk.doorNum) {
            params.content.address += this.paramInfoYk.doorNum + addressFlat;
          }
        }
      }
      if (this.isYuu) {
        if (this.yuuInfo && this.yuuInfo.addressNow) {
          params.content.address = this.yuuInfo.addressNow.address2;
          if (this.paramInfoYk.detailPlace) {
            params.content.address += this.paramInfoYk.detailPlace;
          }
          if (this.paramInfoYk.house) {
            params.content.address += this.paramInfoYk.house + addressBlock;
          }
          if (this.paramInfoYk.floor) {
            params.content.address += this.paramInfoYk.floor + addressFloor;
          }
          if (this.paramInfoYk.doorNum) {
            params.content.address += this.paramInfoYk.doorNum + addressFlat;
          }
          var coordinateResYuu = wgs_gcj_encrypts(
            this.yuuInfo.addressNow.postalCode.lat,
            this.yuuInfo.addressNow.postalCode.lng
          );
          params.content.longitude = coordinateResYuu.lng;
          params.content.latitude = coordinateResYuu.lat;
        }
      }
      params.content.userNote = this.paramInfoYk.notice || "";
      let result = await postDC("Action", params);
      if (result.status != 1) {
        if (result.status == 5) {
          this.confirmTitle = this.WDSYMK;
          this.confirmTitleSubTitle = this.BZDQSJDSUB;
          this.confirmTitleSubTitleE = this.NKYGGSJ;
          this.showConfirm = true;
          return;
        }
        if (
          result.msg == "ItemsPassed" ||
          result.msg == "ItemsServerTimePassed"
        ) {
          return this.$toast(this.CPYCS);
        }
        return this.$toast(this.HQXXSB);
      }
      var userId = null;
      if (this.$store.state.userInfo) {
        userId = this.$store.state.userInfo.userId;
      }
      let orderParam = {
        actionName: "candao.member.postOrder",
        content: {
          orderId: result.data + "",
          system: this.$store.state.app ? "App" : "Web",
          currency: this.$store.state.moneyMark[this.$store.state.area],
          userEmail: this.login
            ? this.$store.state.userInfo.email
            : this.paramInfoYk.email,
          phone: this.paramInfoYk.phone || this.paramInfoYk.phoneOne,
          vipSystem:
            this.scoreType == 2 ? "YUU" : this.scoreType == 1 ? "KFC" : "",
          payType: this.cardInfo.paymentChannel,
          pack: false,
          tablewareNumber: this.paramInfoYk.switchBowl ? 1 : 0,
          earnPointSystem: this.scoreType,
          note: this.paramInfoYk.notice || "",
          memberSystem: 0,
        },
      };
      if (this.paramInfoYk.email) {
        orderParam.content.userEmail = this.paramInfoYk.email;
      }
      if (this.isYuu) {
        orderParam.content.memberSystem = 2;
      } else if (this.$store.state.app) {
        orderParam.content.memberSystem = 1;
      }
      if (this.scoreType == 1 && !this.$store.state.userInfo) {
        orderParam.content.earnPointSystem = 0;
        orderParam.content.vipSystem = "";
      }
      if (this.$store.state.userInfo) {
        orderParam.content.userId = userId;
        if (!this.paramInfoYk.phone && !this.paramInfoYk.phoneOne) {
          orderParam.content.phone = this.$store.state.userInfo.phoneNo;
        }
      }
      if (this.pageParam.businessType == 1) {
        orderParam.content.isAddressIsolation =
          this.paramInfoYk.switchSend || false;
        orderParam.content.isolatedPerson = this.paramInfoYk.bowladd || false;
      }
      if (this.couponInfo) {
        orderParam.content.vouchers = [];
        var obj = {
          voucherId: this.couponInfo.voucherId + "",
          voucherCode: this.couponInfo.voucherCode + "",
          voucherType: this.couponInfo.voucherType + "",
        };

        if (
          this.couponInfo.voucherType == 1 ||
          this.couponInfo.voucherType == 4
        ) {
          obj.voucherThirdCode = this.couponInfo.pointsoftProducts[0];
          obj.voucherAmount = this.priceByCoupon.price * 100 || 0;
        } else if (
          this.couponInfo.voucherType == 3 ||
          this.couponInfo.voucherType == 5
        ) {
          obj.voucherAmount =
            this.couponInfo.mappingPro.mappingPrice * 100 || 0;
        } else if (this.couponInfo.voucherType == 2) {
          obj.voucherThirdCode = this.couponInfo.pointsoftProducts[0];
          // obj.voucherDiscount = this.couponInfo.voucherDiscount;
          obj.voucherAmount = this.priceByCoupon.price * 100 || 0;
        }
        if (
          this.couponInfo.voucherProductCode &&
          this.couponInfo.voucherProductCode.length
        ) {
          obj.productCode = this.couponInfo.voucherProductCode[0];
        }
        orderParam.content.vouchers.push(obj);
      }
      if (this.scoreType == 2) {
        if (this.$store.state.userInfo) {
          orderParam.content.yuuId = this.$store.state.userInfo.yuuId;
        } else {
          if (!this.yuuId) {
            return this.$toast(this.QBDYUU);
          }
          orderParam.content.yuuId = this.yuuId;
        }
      }
      if (this.pageParam.businessType == 3) {
        orderParam.content.pack = this.paramInfoYk.invite || false;
      }
      if (this.pageParam.businessType == 1) {
        orderParam.content.deliveryQuoteTime = parseInt(this.quoteTime) || 30;
      } else {
        orderParam.content.pickupQuoteTime = parseInt(this.quoteTime) || 30;
      }
      if (this.pageParam.businessType == 1) {
        var pParam = {
          street: "",
        };
        if (this.selectPlaceInfo) {
          pParam.district =
            this.selectPlaceInfo.building + " " + this.selectPlaceInfo.district;
          pParam.street = this.selectPlaceInfo.street || "";
          pParam.block = this.selectPlaceInfo.block || "";
          pParam.flat = this.selectPlaceInfo.flat || "";
          pParam.floor = this.selectPlaceInfo.floor || "";
        } else {
          pParam.district =
            this.$store.state.placeInfo.building +
            " " +
            this.$store.state.placeInfo.district;
          if (this.$store.state.placeInfo.contactPerson) {
            pParam.street = this.$store.state.placeInfo.street || "";
            pParam.block = this.$store.state.placeInfo.block || "";
            pParam.floor = this.$store.state.placeInfo.floor || "";
            pParam.flat = this.$store.state.placeInfo.flat || "";
          } else {
            pParam.street = this.paramInfoYk.detailPlace || "";
            pParam.block = this.paramInfoYk.house || "";
            pParam.flat = this.paramInfoYk.doorNum || "";
            pParam.floor = this.paramInfoYk.floor || "";
          }
        }
        orderParam.content.address = pParam;
      }
      if (this.isYuu && this.yuuInfo.addressNow) {
        var yuuPlaceParam = {};
        yuuPlaceParam.district = this.yuuInfo.addressNow.address2;
        yuuPlaceParam.street = this.paramInfoYk.detailPlace || "";
        yuuPlaceParam.block = this.paramInfoYk.house || "";
        yuuPlaceParam.flat = this.paramInfoYk.doorNum || "";
        yuuPlaceParam.floor = this.paramInfoYk.floor || "";
        orderParam.content.address = yuuPlaceParam;
      }
      let orderResult = await postPaas("UserUnify", orderParam);
      if (orderResult.status != 1) {
        return this.$toast(this.HQXXSB);
      }
      var cancelParam = this.pageParam;
      cancelParam.fail = true;
      cancelParam = JSON.stringify(this.pageParam);
      cancelParam = encodeURIComponent(cancelParam);
      let payParam = {
        actionName: "candao.pay.orderPay",
        content: {
          orderId: result.data,
          payType: 19,
          jrgAzureParams: {
            key: "40a443f1ced7f597",
            brand: "KFCHK",
            deviceId: "iPhone",
            paymentChannel: this.cardInfo.paymentChannel, //查询支付方式接口有返回
            currency: this.$store.state.moneyMark[this.$store.state.area],
            device: this.device,
            system: this.$store.state.app ? "App" : "Web",
            orderToken: orderResult.data.orderToken,
            token: this.cardInfo.token,
            createCreditCard: this.cardInfo.isAllowSave,
            language: this.$store.state.language == 2 ? "E" : "TC",
            successUrl:
              this.pageLink +
              "/orderDetail.html?key=40a443f1ced7f597&userId=" +
              userId +
              "&login=" +
              this.login +
              "&language=" +
              this.lanType +
              "&app=" +
              this.$store.state.app,
            cancelUrl: this.pageLink + "/#/confirmorder/" + cancelParam,
          },
        },
      };
      if (this.isYuu) {
        let yuuUrl = localStorage.getItem("yuuUrl");
        if (yuuUrl) {
          yuuUrl = yuuUrl.split("?")[1];
        }
        if (yuuUrl) {
          payParam.content.jrgAzureParams.cancelUrl += "?" + yuuUrl;
        }
      }
      if (this.$store.state.userInfo) {
        payParam.content.jrgAzureParams.successUrl +=
          "&accessCode=" +
          encodeURIComponent(this.$store.state.userInfo.accessCode) +
          "&phoneNo=" +
          this.$store.state.userInfo.phoneNo;
      }
      if (this.cardInfo.paymentChannel == "MPGS") {
        payParam.content.jrgAzureParams.createCreditCard = null;
      }
      if (this.paramInfoYk.areaCode) {
        payParam.content.jrgAzureParams.successUrl +=
          "&areaCode=" + this.paramInfoYk.areaCode || "852";
      }
      if (this.$store.state.userInfo) {
        payParam.content.jrgAzureParams.isMember = true;
        payParam.content.jrgAzureParams.userId = userId + "";
      } else {
        payParam.content.jrgAzureParams.isMember = false;
      }
      let payResult = await postDC("Action", payParam);
      if (payResult.status != 1) {
        return this.$toast(payResult.msg || this.HQXXSB);
      }
      // this.dataLayerPushPay(payResult.data.transactionId);
      window.location.href = payResult.data.url;
      var param = JSON.stringify(this.paramInfoYk);
      localStorage.setItem("KFCYK", param);
    },
    //取消按钮
    cancel() {
      this.catNext = false;
      this.showWarn = false;
      this.$router.back(-1);
    },
    // 确认按钮
    finishWink() {
      this.showWarn = false;
      this.ageAgree = false;
    },
    // 显示选择付款方式
    selectPay() {
      if (
        !this.$store.state.userInfo &&
        !this.paramInfoYk.phone &&
        !this.paramInfoYk.phoneOne
      ) {
        var phoneAlertT =
          this.$store.state.language == 1
            ? "請輸入手機號碼"
            : "Please provide mobile number";

        return this.$toast(phoneAlertT);
      }
      if (this.paramInfoYk.phoneOne && this.paramInfoYk.phoneOne.length != 8) {
        return this.$toast(this.SJHCW);
      }
      if (this.paramInfoYk.phone && this.paramInfoYk.phone.length != 8) {
        return this.$toast(this.SJHCW);
      }
      this.$refs.cardSelectBox.getPayMethods();
    },
    showCardSelectBox() {
      this.showCard = !this.showCard;
    },
    // 取消选择信用卡
    cancleCard() {
      this.showCardSelectBox();
    },
    //确定选择的信用卡
    finishCard(info) {
      this.userIdYk = info.userId;
      this.cardInfo = info.card;
      this.showCardSelectBox();
      this.dataLayerPaymentSelected(info.card);
    },
    // 获取门店优惠
    async getStorePres() {
      let params = {
        actionName: "candao.preferential.getStorePres",
        content: {
          storeId: this.pageParam.storeId,
        },
      };
      let storePres = await postDC("Action", params);
      if (storePres.status == 1) {
        this.canClickPay.push(storePres.status);
        // for (var sp = 0; sp < storePres.data.pres.length; sp++) {
        //   if (
        //     storePres.data.pres[sp].type == 5 &&
        //     storePres.data.pres[sp].childType == 9
        //   ) {
        //     this.storePresDelivery = storePres.data.pres[sp];
        //     break;
        //   }
        // }
      } else {
        this.$toast(this.HQXXSB);
      }
    },
    checkStoreBusinessTime() {
      let businessTimes = null;
      if (this.shopInfo) {
        businessTimes = this.shopInfo.businessTimes;
        if (this.pageParam.businessType == 2) {
          if (this.shopInfo.storeTakeEatBusinessTimeSetting) {
            businessTimes = this.shopInfo.storeTakeEatBusinessTimeOfDay;
          }
        } else if (this.pageParam.businessType == 3) {
          if (this.shopInfo.storeHereEatBusinessTimeSetting) {
            businessTimes = this.shopInfo.storeHereEatBusinessTimeOfDay;
          }
        }
        this.shopInfo.businessTimes = businessTimes;
      }
    },
    // 餐单
    async getProductMenu() {
      this.checkStoreBusinessTime();
      var params = {
        actionName: "candao.product.getProductMenu",
        content: {
          storeId: this.pageParam.storeId,
          sendType: this.pageParam.businessType,
        },
      };
      if (this.pageParam.book == 2) {
        let reserve = this.pageParam.second.split(" ");
        let reserveDate = reserve[0].split("/").join("-");
        params.content.reserveDate = reserveDate + " " + reserve[1];
        this.$store.commit("saveReserveDate", this.pageParam.second);
      }
      let productMenuRes = await postDC("Action", params);
      if (productMenuRes.status == 1) {
        var week = new Date().getDay();
        if (this.pageParam.book == 2) {
          week = new Date(this.second).getDay();
        }
        if (week == 0) {
          week = 7;
        }
        var nowTime = new Date().getTime();
        var year = new Date().getFullYear();
        var month = new Date().getMonth() + 1;
        var day = new Date().getDate();
        var date = year + "/" + month + "/" + day;
        if (this.pageParam.book == 2) {
          nowTime = new Date(this.second).getTime();
          date = this.second.split(" ")[0];
        }
        var bigsOne = productMenuRes.data.bigs;
        for (var j = 0; j < bigsOne.length; j++) {
          var smallsOne = bigsOne[j].smalls;
          for (var f = 0; f < smallsOne.length; f++) {
            var productArr = [];
            var productsOne = smallsOne[f].products;
            for (var d = 0; d < productsOne.length; d++) {
              if (
                !productsOne[d].isDiscontinue &&
                productsOne[d].status == 1 &&
                productsOne[d].isInServiceTime
              ) {
                if (
                  productsOne[d].serviceWeeks &&
                  productsOne[d].serviceWeeks.length
                ) {
                  if (productsOne[d].serviceWeeks.indexOf(week) > -1) {
                    if (
                      productsOne[d].serviceTimes &&
                      productsOne[d].serviceTimes.length
                    ) {
                      for (
                        var ST = 0;
                        ST < productsOne[d].serviceTimes.length;
                        ST++
                      ) {
                        var startTime = new Date(
                          date + " " + productsOne[d].serviceTimes[ST].start
                        ).getTime();
                        var endTime = new Date(
                          date + " " + productsOne[d].serviceTimes[ST].end
                        ).getTime();
                        if (nowTime >= startTime && nowTime <= endTime) {
                          productArr.push(productsOne[d]);
                          break;
                        }
                      }
                    } else {
                      if (
                        this.shopInfo &&
                        this.shopInfo.businessTimes &&
                        this.shopInfo.businessTimes.length
                      ) {
                        for (
                          let be = 0;
                          be < this.shopInfo.businessTimes.length;
                          be++
                        ) {
                          var businessTimesStart = new Date(
                            date +
                              " " +
                              this.shopInfo.businessTimes[be].beginTime
                          ).getTime();
                          var businessTimesEnd = new Date(
                            date + " " + this.shopInfo.businessTimes[be].endTime
                          ).getTime();
                          if (
                            nowTime >= businessTimesStart &&
                            nowTime <= businessTimesEnd
                          ) {
                            productArr.push(productsOne[d]);
                            break;
                          }
                        }
                      } else {
                        productArr.push(productsOne[d]);
                      }
                    }
                  }
                } else {
                  // 没有星期几
                  if (
                    productsOne[d].serviceTimes &&
                    productsOne[d].serviceTimes.length
                  ) {
                    for (
                      var ST1 = 0;
                      ST1 < productsOne[d].serviceTimes.length;
                      ST1++
                    ) {
                      var startTime1 = new Date(
                        date + " " + productsOne[d].serviceTimes[ST1].start
                      ).getTime();
                      var endTime1 = new Date(
                        date + " " + productsOne[d].serviceTimes[ST1].end
                      ).getTime();
                      if (nowTime >= startTime1 && nowTime <= endTime1) {
                        productArr.push(productsOne[d]);
                        break;
                      }
                    }
                  } else {
                    if (
                      this.shopInfo &&
                      this.shopInfo.businessTimes &&
                      this.shopInfo.businessTimes.length
                    ) {
                      for (
                        let be = 0;
                        be < this.shopInfo.businessTimes.length;
                        be++
                      ) {
                        var businessTimesStart1 = new Date(
                          date + " " + this.shopInfo.businessTimes[be].beginTime
                        ).getTime();
                        var businessTimesEnd1 = new Date(
                          date + " " + this.shopInfo.businessTimes[be].endTime
                        ).getTime();
                        if (
                          nowTime >= businessTimesStart1 &&
                          nowTime <= businessTimesEnd1
                        ) {
                          productArr.push(productsOne[d]);
                          break;
                        }
                      }
                    } else {
                      productArr.push(productsOne[d]);
                    }
                  }
                }
              }
              smallsOne[f].products = productArr;
            }
          }
        }
        // 去掉二级分类
        for (var pm = 0; pm < productMenuRes.data.bigs.length; pm++) {
          var psSmall = productMenuRes.data.bigs[pm].smalls;
          var smallArr = [];
          for (var ps = 0; ps < psSmall.length; ps++) {
            var ppPro = psSmall[ps].products;
            if (ppPro && ppPro.length) {
              smallArr.push(psSmall[ps]);
            }
          }
          productMenuRes.data.bigs[pm].smalls = smallArr;
        }
        // 去掉一级分类
        var bigsArr = [];
        for (var bm = 0; bm < productMenuRes.data.bigs.length; bm++) {
          if (productMenuRes.data.bigs[bm].smalls.length) {
            bigsArr.push(productMenuRes.data.bigs[bm]);
          }
        }
        productMenuRes.data.bigs = bigsArr;
        this.productMenu = productMenuRes.data;
        this.getCanPreList();
        this.canClickPay.push(productMenuRes.status);
        var secondTimes = new Date(this.sendTime).getTime();
        if (this.pageParam.book == 2) {
          secondTimes = new Date(this.pageParam.second).getTime();
        }
        this.checkCart(secondTimes);
        this.getCoupon();
      } else {
        this.$toast(this.HQXXSB);
      }
    },
    // 获取优惠券
    async getCoupon(info) {
      this.getPrice();
      var params = {
        actionName: "candao.member.voucherGuestGet",
        content: {
          // shopCode: this.shopInfo.extraStoreId,
          voucherStatus: 1,
        },
      };
      if (this.$store.state.userInfo) {
        params.actionName = "candao.member.voucherGet";
        params.content.userId = this.$store.state.userInfo.userId;
      }
      let couponRes = await postPaas("UserUnify", params);
      if (couponRes.status == 1) {
        this.onceDatalayerPush();
        var couponListInfo = couponRes.data.data;
        var orderTypeCode;
        if (this.pageParam.businessType == 1) {
          orderTypeCode = "A02";
        } else if (this.pageParam.businessType == 2) {
          orderTypeCode = "A01";
        } else if (this.pageParam.businessType == 3) {
          orderTypeCode = "A05";
        }
        var couArr = [];
        var nowTime = new Date().getTime();
        if (this.pageParam.book == 2) {
          nowTime = new Date(this.second).getTime();
        }
        for (var i = 0; i < couponListInfo.length; i++) {
          var startTime = 0;
          if (couponListInfo[i].validBeginDate) {
            var beginTimeArr = couponListInfo[i].validBeginDate.split("T");
            var beginDate = beginTimeArr[0].split("-");
            var beginTime = beginTimeArr[1].split(".")[0];
            var begin =
              beginDate[0] +
              "/" +
              beginDate[1] +
              "/" +
              beginDate[2] +
              " " +
              beginTime;
            startTime = new Date(begin).getTime();
          }
          var endTime = 0;
          if (couponListInfo[i].validEndDate) {
            var endTimeArr = couponListInfo[i].validEndDate.split("T");
            var endDate = endTimeArr[0].split("-");
            var endTimeTe = endTimeArr[1].split(".")[0];
            var end =
              endDate[0] +
              "/" +
              endDate[1] +
              "/" +
              endDate[2] +
              " " +
              endTimeTe;
            endTime = new Date(end).getTime();
          } else {
            endTime = nowTime + 100000;
          }
          if (nowTime >= startTime && nowTime <= endTime) {
            if (couponListInfo[i].voucherCategoryCode) {
              if (
                couponListInfo[i].voucherCategoryCode.indexOf(orderTypeCode) >
                  -1 ||
                couponListInfo[i].voucherCategoryCode.length == 0
              ) {
                if (
                  couponListInfo[i].voucherType == 1 ||
                  couponListInfo[i].voucherType == 4
                ) {
                  if (couponListInfo[i].voucherAmount) {
                    couArr.push(couponListInfo[i]);
                  }
                } else if (
                  couponListInfo[i].voucherType == 2 &&
                  couponListInfo[i].voucherDiscount
                ) {
                  couArr.push(couponListInfo[i]);
                } else if (
                  couponListInfo[i].voucherType == 3 ||
                  couponListInfo[i].voucherType == 5
                ) {
                  // 赠品券 和 一口价
                  couArr.push(couponListInfo[i]);
                }
              }
            } else {
              couArr.push(couponListInfo[i]);
            }
          }
        }
        var couponArr = [];
        var timeTag = this.$store.state.time;
        for (var j = 0; j < couArr.length; j++) {
          let isCandao = true;
          if (couArr[j].voucherCalType == null) {
            isCandao = false;
          }
          let availableShops = isCandao
            ? couArr[j].canDaoAvailableShops
            : couArr[j].availableShops;
          let storeIdOrCode = isCandao
            ? this.shopInfo.storeId
            : this.shopInfo.extraStoreId;
          if (
            availableShops &&
            availableShops.length > 0 &&
            availableShops.indexOf(storeIdOrCode) < 0
          ) {
            continue;
          }
          if (
            couArr[j].voucherHashtagCode &&
            couArr[j].voucherHashtagCode.length
          ) {
            if (couArr[j].voucherHashtagCode.indexOf(timeTag) > -1) {
              if (couArr[j].voucherType == 3 || couArr[j].voucherType == 5) {
                if (couArr[j].voucherProductCode) {
                  couponArr.push(couArr[j]);
                }
              } else if (
                couArr[j].voucherType == 1 ||
                couArr[j].voucherType == 4
              ) {
                if (couArr[j].voucherAmount) {
                  couponArr.push(couArr[j]);
                }
              } else if (
                couArr[j].voucherType == 2 &&
                couArr[j].voucherDiscount
              ) {
                couponArr.push(couArr[j]);
              }
            }
          } else {
            if (couArr[j].voucherType == 3 || couArr[j].voucherType == 5) {
              if (couArr[j].voucherProductCode) {
                couponArr.push(couArr[j]);
              }
            } else if (
              couArr[j].voucherType == 1 ||
              couArr[j].voucherType == 4
            ) {
              if (couArr[j].voucherAmount) {
                couponArr.push(couArr[j]);
              }
            } else if (
              couArr[j].voucherType == 2 &&
              couArr[j].voucherDiscount
            ) {
              couponArr.push(couArr[j]);
            }
          }
        }
        couponArr.forEach((val) => {
          var beginDateC = val.validBeginDate
            ? val.validBeginDate.split("T")[0].split("-").reverse()
            : null;
          var endDateC = val.validEndDate
            ? val.validEndDate.split("T")[0].split("-").reverse()
            : null;
          if (beginDateC) {
            val.date = beginDateC.join("-") + "至" + endDateC.join("-");
          } else if (endDateC) {
            val.date = endDateC.join("-");
          } else {
            val.date = "-";
          }
          val.couTag = [];
          if (
            val.voucherCategoryCode &&
            (val.voucherCategoryCode.indexOf("A01") > -1 ||
              val.voucherCategoryCode.indexOf("A02") > -1)
          ) {
            val.sendFunc = 1; //外送
            if (val.voucherCategoryCode.indexOf("A01") > -1) {
              val.couTag.push(1);
            }
            if (val.voucherCategoryCode.indexOf("A02") > -1) {
              val.couTag.push(2);
            }
          } else if (
            val.voucherCategoryCode &&
            (val.voucherCategoryCode.indexOf("A03") > -1 ||
              val.voucherCategoryCode.indexOf("A05") > -1)
          ) {
            val.sendFunc = 2; //堂食
            if (val.voucherCategoryCode.indexOf("A03") > -1) {
              val.couTag.push(3);
            }
            if (val.voucherCategoryCode.indexOf("A05") > -1) {
              val.couTag.push(5);
            }
          } else {
            val.sendFunc = 0; //通用
          }
        });
        this.couponList = couponArr;
        // this.canClickPay.push(couponRes.status);
        if (info) {
          this.cancelCoupon();
          var index = -1;
          for (var k = 0; k < this.couponList.length; k++) {
            if (this.couponList[k].voucherId == info.parentId) {
              index = k;
              break;
            }
          }
          if (index > -1) {
            let checkVoucherInfo = this.couponList[index];
            if (
              (checkVoucherInfo.voucherType == 3 ||
                checkVoucherInfo.voucherType == 5) &&
              checkVoucherInfo.voucherProductCode &&
              checkVoucherInfo.voucherProductCode.length
            ) {
              this.checkSelectCoupon(checkVoucherInfo);
            } else if (
              checkVoucherInfo.voucherType != 3 &&
              checkVoucherInfo.voucherType != 5
            ) {
              this.checkSelectCoupon(checkVoucherInfo);
            } else {
              this.$toast(this.WFSYYHQ);
            }
          } else {
            for (let i = 0; i < couponListInfo.length; i++) {
              if (couponListInfo[i].voucherId == info.parentId) {
                this.checkVoucherError(
                  couponListInfo[i],
                  orderTypeCode,
                  timeTag
                );
                break;
              }
            }
          }
        }
      }
    },
    checkVoucherError(data, orderTypeCode, timeTag) {
      let isCandao = true;
      if (data.voucherCalType == null) {
        isCandao = false;
      }
      if (data.voucherCategoryCode && data.voucherCategoryCode.length) {
        if (data.voucherCategoryCode.indexOf(orderTypeCode) < 0) {
          this.confirmTitle = this.BNYYCDCFS;
          this.confirmTitleSubTitle = this.XYQTYHQFK;
          this.confirmTitleSubTitleE = "";
          this.showConfirm = true;
          return;
        }
      }
      let nowTime = new Date().getTime();
      if (this.pageParam.book == 2) {
        nowTime = new Date(this.second).getTime();
      }
      // 优惠券有效期起始时间
      if (data.validBeginDate) {
        let startTime = 0;
        let beginTimeArr = data.validBeginDate.split("T");
        let beginDate = beginTimeArr[0].split("-").join("/");
        let beginTime = beginTimeArr[1].split(".")[0];
        let begin = beginDate + " " + beginTime;
        startTime = new Date(begin).getTime();
        if (startTime > nowTime) {
          this.confirmTitle = this.WFSYYHQ;
          this.confirmTitleSubTitle = "";
          this.confirmTitleSubTitleE = "";
          this.showConfirm = true;
          return;
        }
      }
      // 优惠券过期时间
      if (data.validEndDate) {
        let endTime = 0;
        let endTimeArr = data.validEndDate.split("T");
        let endDate = endTimeArr[0].split("-").join("/");
        let endTimeTe = endTimeArr[1].split(".")[0];
        let end = endDate + " " + endTimeTe;
        endTime = new Date(end).getTime();
        if (endTime < nowTime) {
          this.confirmTitle = this.YHMYSX;
          this.confirmTitleSubTitle = this.XYQTYHQFK;
          this.confirmTitleSubTitleE = "";
          this.showConfirm = true;
          return;
        }
      }
      // 优惠券是否在当前时间段可用
      if (data.voucherHashtagCode && data.voucherHashtagCode.length) {
        if (data.voucherHashtagCode.indexOf(timeTag) < 0) {
          this.confirmTitle = this.WDSYMK;
          this.confirmTitleSubTitle = this.YHMZDSJSY;
          this.confirmTitleSubTitleE = this.NKYGGSJ;
          this.showConfirm = true;
          return;
        }
      }
      let availableShops = isCandao
        ? data.canDaoAvailableShops
        : data.availableShops;
      let storeIdOrCode = isCandao
        ? this.shopInfo.storeId
        : this.shopInfo.extraStoreId;
      if (
        availableShops &&
        availableShops.length > 0 &&
        availableShops.indexOf(storeIdOrCode) < 0
      ) {
        this.confirmTitle = this.BNZCCTSY;
        this.confirmTitleSubTitle = this.XYQTYHQFK;
        this.confirmTitleSubTitleE = "";
        this.showConfirm = true;
        return false;
      }
    },
    // 檢查兌換的优惠券检查是否可用
    async checkSelectCoupon(info, canShow = true, canJoinDetail = true) {
      this.dataLayerPushCoupon(info);
      this.showCoupon = false;
      if (!this.$store.state.userInfo) return;
      let isCandao = true;
      if (info.voucherCalType == null) {
        isCandao = false;
      }
      let brandId = 41;
      let H5Seting = this.$store.state.H5Seting;
      if (H5Seting) {
        let brandInfos = H5Seting.brandInfos;
        if (brandInfos && brandInfos.length) {
          brandId = brandInfos[0].brandId;
        }
      }
      if (!isCandao) {
        var total = this.totalPrice || this.$store.state.totalPrice;
        if (info.minUsage) {
          if (Number(info.minUsage) / 100 > total) {
            this.confirmTitle = this.WDSYMK;
            this.confirmTitleSubTitle = this.WDSYMKSUBJE;
            this.confirmTitleSubTitleE = this.isVoucherByCode
              ? this.XYQTYHQFK
              : this.QRDDMK;
            this.showConfirm = canShow;
            // this.$toast("未達使用門檻");
            return false;
          }
        }
        if (info.minUsageProductCode && info.minUsageProductCode.length) {
          var paramsOne = {
            actionName: "candao.product.getProductMapping",
            content: {
              posId: 396,
              brandId,
            },
          };
          let arr = [];

          for (let j = 0; j < info.minUsageProductCode.length; j++) {
            paramsOne.content.mappingCode = info.minUsageProductCode[j];
            var minUsageRes = await postDC("Action", paramsOne);
            if (minUsageRes.status == 1 && minUsageRes.data) {
              arr.push(minUsageRes.data.mappingId);
            }
          }
          let cart = copyData(this.$store.state.cart);
          let hasPro = false;
          for (var i = 0; i < cart.length; i++) {
            if (arr.indexOf(cart[i].uid) > -1) {
              hasPro = true;
              break;
            }
          }
          if (!hasPro) {
            this.confirmTitle = this.WDSYMK;
            this.confirmTitleSubTitle = this.WDSYMKSUBCP;
            this.confirmTitleSubTitleE = this.isVoucherByCode
              ? this.XYQTYHQFK
              : this.QRDDMK;
            this.showConfirm = canShow;
            return false;
          }
          if (arr.length != info.minUsageProductCode.length) {
            this.confirmTitle = this.WDSYMK;
            this.confirmTitleSubTitle = this.WDSYMKSUBCP;
            this.confirmTitleSubTitleE = this.isVoucherByCode
              ? this.XYQTYHQFK
              : this.QRDDMK;
            this.showConfirm = canShow;
            return false;
          }
        }
      } else {
        // 检查一口价优惠券门槛
        let checkRes = await checkCouonType(
          info,
          this.pageParam.businessType,
          this.storePresAll
        );
        if (!checkRes.usage) {
          if (checkRes.type == 1) {
            this.confirmTitle = this.WDSYMK;
            this.confirmTitleSubTitle = this.WDSYMKSUBJE;
            this.confirmTitleSubTitleE = this.isVoucherByCode
              ? this.XYQTYHQFK
              : this.QRDDMK;
          } else {
            this.confirmTitle = this.WDSYMK;
            this.confirmTitleSubTitle = this.WDSYMKSUBCP;
            this.confirmTitleSubTitleE = this.isVoucherByCode
              ? this.XYQTYHQFK
              : this.QRDDMK;
          }
          this.showConfirm = canShow;
          return false;
        }
      }

      if (info.voucherProductCode && info.voucherProductCode.length) {
        var params = {
          actionName: "candao.product.getProductMapping",
          content: {
            posId: 396,
            mappingCode: info.voucherProductCode[0],
            brandId,
          },
        };
        let result = await postDC("Action", params);
        if (result.status == 1) {
          if (result.data) {
            var mappingId;
            var bigs = this.productMenu.bigs;

            for (var a = 0; a < bigs.length; a++) {
              for (var b = 0; b < bigs[a].smalls.length; b++) {
                if (
                  bigs[a].smalls[b].name == "COPY" ||
                  bigs[a].smalls[b].nameEn == "COPY"
                ) {
                  for (var c = 0; c < bigs[a].smalls[b].products.length; c++) {
                    if (
                      bigs[a].smalls[b].products[c].uid == result.data.mappingId
                    ) {
                      mappingId = bigs[a].smalls[b].products[c].uid;
                      // result.data.mappingPrice =
                      //   bigs[a].smalls[b].products[c].price;
                      info.mappingPro = result.data;
                      info.copyPro = copyData(bigs[a].smalls[b].products[c]);
                      if (info.voucherType == 5 || info.voucherType == 3) {
                        if (isCandao) {
                          result.data.mappingPrice = info.canDaoFixPrice / 100;
                          info.copyPro.price = info.canDaoFixPrice / 100;
                        } else {
                          result.data.mappingPrice =
                            bigs[a].smalls[b].products[c].price;
                          info.copyPro.price =
                            bigs[a].smalls[b].products[c].price;
                        }
                      } else {
                        result.data.mappingPrice =
                          bigs[a].smalls[b].products[c].price;
                      }
                      break;
                    }
                  }
                }
              }
            }
          } else {
            this.confirmTitle = this.WFSYYHQ;
            this.confirmTitleSubTitle = "";
            this.confirmTitleSubTitleE = "";
            this.showConfirm = canShow;

            return false;
          }
          if (!mappingId) {
            this.confirmTitle = this.BZCDHYSQ;
            this.confirmTitleSubTitle = this.isVoucherByCode
              ? this.XYQTYHQFK
              : this.QRDDMK;
            this.confirmTitleSubTitleE = "";
            this.showConfirm = canShow;
            return false;
          }
        } else {
          this.$toast(this.HQXXSB);
          return false;
        }
      }
      if (info.voucherType == 3 || info.voucherType == 5) {
        if (!info.voucherProductCode && !info.voucherProductCode.length) {
          this.confirmTitle = this.WFSYYHQ;
          this.confirmTitleSubTitle = "";
          this.confirmTitleSubTitleE = "";
          this.showConfirm = canShow;
          return false;
        }
      }
      this.$store.commit("changeClearCou", false);
      this.couponInfo = info;
      this.$store.commit("saveCouponInfo", this.couponInfo);
      this.getPrice();
      if (canJoinDetail) {
        if (info.voucherType == 3 || info.voucherType == 5) {
          info.copyPro.isCouponPro = true;
          info.copyPro.isCouponProType = info.voucherType;
          info.copyPro.isCandao = isCandao;
          this.lookDetail(info.copyPro);
        }
      }
      return true;
    },
    // 查看是否含有究竟制品
    getWink() {
      var cart = copyData(this.$store.state.cart);
      if (this.cartList.length) {
        for (var i = 0; i < cart.length; i++) {
          if (cart[i].isAlcohol) {
            this.wink = true;
            this.catNext = true;
            this.showWarn = true;
            break;
          }
        }
      }
    },
    // 获取门店信息
    async getStoreDetail() {
      var coordinate = [];
      var placeInfo = this.selectPlaceInfo || this.$store.state.placeInfo;
      if (placeInfo) {
        coordinate = [
          this.$store.state.placeInfo.lng,
          this.$store.state.placeInfo.lat,
        ];
      }
      if (this.isYuu && this.yuuInfo.addressNow) {
        coordinate = [
          this.yuuInfo.addressNow.postalCode.lng,
          this.yuuInfo.addressNow.postalCode.lat,
        ];
      }
      var params = {
        actionName: "candao.storeStandard.getStore",
        langType: this.$store.state.language,
        content: {
          storeId: this.pageParam.storeId,
          businessType: this.pageParam.businessType,
          coordinate,
        },
      };

      let result = await postDC("Action", params);
      if (result.status == 1) {
        this.canClickPay.push(result.status);
        this.shopInfo = result.data;
        this.getProductMenu();
        this.getStorePres();
        // this.getCanPreList();
        this.getStoreDelivery();
        // this.setAdvanceTime(result.data.appointmentTime);
      } else {
        this.$toast(this.HQXXSB);
      }
    },
    // 获取门店配送时间
    async getStoreDelivery() {
      let param = {
        actionName: "candao.member.storeAddressSearch",
        content: {
          shopCode: this.shopInfo.extraStoreId,
          // lat: this.shopInfo.coordinate[1],
          // lng: this.shopInfo.coordinate[0],
        },
      };
      let result = await postPaas("UserUnify", param);
      if (result.status == 1) {
        if (result.data.data && result.data.data.length) {
          if (this.pageParam.businessType == 1) {
            this.quoteTime = result.data.data[0].deliveryQuoteTime;
          } else {
            this.quoteTime = result.data.data[0].pickupQuoteTime;
          }
        }
        this.deliveryTime = this.quoteTime;
        if (this.lanType == 1) {
          this.quoteTime ? (this.quoteTime += "分鐘") : "30分鐘";
        } else {
          this.quoteTime ? (this.quoteTime += " minutes") : "30minutes";
        }
      }
    },
    // 获取营销活动
    async getCanPreList() {
      // this.checkVoucherInfoGetCanPre();
      if (!this.canGetPres) return;
      this.isGetPreCount += 1;
      let params = {
        actionName: "candao.preferential.canPreList",
        content: {
          storeId: this.pageParam.storeId,
          menuId: this.pageParam.menuId,
          price: this.totalPrice,
          payType: 1,
          orderType: this.pageParam.businessType,
          products: this.foodSku.products,
          isFirst: true,
        },
      };
      let result = await postDC("Action", params);
      if (result.status == 1) {
        this.storePresAll = result.data;
        for (var i = 0; i < result.data.pres.length; i++) {
          if (
            result.data.pres[i].type == 2 &&
            result.data.pres[i].childType == 4
          ) {
            this.rccommendInfo = result.data.pres[i];
            var PRArr = [];
            if (result.data.pres[i] && result.data.pres[i].items) {
              for (var r = 0; r < result.data.pres[i].items.length; r++) {
                if (
                  !result.data.pres[i].items[r].isSoldOut &&
                  !result.data.pres[i].items[r].isDiscontinue
                ) {
                  PRArr.push(result.data.pres[i].items[r]);
                }
              }
            }
            this.recommendPre = PRArr;
          }
          if (this.pageParam.businessType == 2) {
            if (
              result.data.pres[i].type == 4 &&
              result.data.pres[i].childType == 6
            ) {
              this.storePres = result.data.pres[i];
            }
          } else if (result.data.pres[i].type == 3) {
            this.storePres = result.data.pres[i];
          }
          if (
            result.data.pres[i].type == 4 &&
            result.data.pres[i].childType == 7
          ) {
            this.discountPre = result.data.pres[i];
          }
        }
      } else {
        if (this.isGetPreCount > 3) {
          this.$toast({ message: this.HQXXSB, duration: 3000 });
          return;
        }
        this.getCanPreList();
      }
    },
    async checkVoucherInfoGetCanPre() {
      if (!this.$store.state.userInfo) return;
      let voucherInfo = this.couponInfo || this.$store.state.couponInfo;
      if (voucherInfo) {
        let checkVoucherRes = await this.checkSelectCoupon(
          voucherInfo,
          true,
          false
        );
        if (!checkVoucherRes) {
          this.cancelCoupon();
        }
      } else {
        this.cancelCoupon();
      }
    },
    getParams() {
      this.$nextTick(async () => {
        if (this.isYuu) {
          this.scoreType = 2;
        }
        this.setlanguage();
        if (!this.$store.state.userInfo && !this.isYuu) {
          let visterParams = {
            actionName: "candao.user.getOrCreateVisitor",
            content: {
              phone: 11111111,
            },
          };
          let visterRes = await postDC("Action", visterParams);
          if (visterRes.status != 1) {
            return this.$toast(this.HQXXSB);
          }
        }

        var param = encodeStr(this.$route.params.query);
        param = decodeURIComponent(param);
        param = JSON.parse(param);
        this.pageParam = param;
        if (this.pageParam.book == 2) {
          this.book = this.pageParam.book;
          this.second = this.pageParam.second;
          setHashTag(this.book, this.second);
        }
        this.afterDeliveryFee = param.afterDeliveryFee;
        let C = this.$store.state.couponInfo;
        if (C && C.isCanUserVoucher) {
          this.couponInfo = this.$store.state.couponInfo;
        } else {
          this.cancelCoupon();
          this.deleteCouPro();
        }
        // else if (
        //   this.pageParam.couponInfo &&
        //   this.pageParam.couponInfo != "null"
        // ) {
        //   this.couponInfo = JSON.parse(this.pageParam.couponInfo);
        // }
        // else {
        //   this.deleteCouPro();
        // }
        this.getStoreDetail();
        this.getWink();
      });
    },
    // 将餐品放入取餐柜
    addFoodBox(type, not) {
      var hasBox = false;
      var bigs = this.productMenu.bigs;
      for (var k = 0; k < bigs.length; k++) {
        var smalls = bigs[k].smalls;
        for (var a = 0; a < smalls.length; a++) {
          var products = smalls[a].products;
          for (var b = 0; b < products.length; b++) {
            if (products[b].extId == "ZZ920") {
              if (type) {
                products[b].num = 1;
              } else {
                products[b].num = 0;
              }
              var obj = {
                storeId: this.storeId,
                product: products[b],
              };
              this.$store.commit("createCart", obj);
              hasBox = true;
              break;
            }
          }
        }
      }
      if (!hasBox) {
        if (!not) {
          this.$toast("暫不支持放入取餐櫃");
        }
      }
    },

    // 埋点
    dataLayerPushPay(id) {
      var items = [];
      var products = copyData(this.$store.state.cart);
      for (var p = 0; p < products.length; p++) {
        var itemObj = {};
        itemObj.id = products[p].pid;
        itemObj.item_name =
          this.lanType == 1 ? products[p].name : products[p].nameEn;
        itemObj.item_id = products[p].pid;
        itemObj.price = products[p].price;
        itemObj.item_list_name = products[p].smallTypeName;
        itemObj.item_list_id = products[p].smallTypeId;
        itemObj.index = p;
        itemObj.quantity = 1;
        itemObj.rec_usage = "";
        itemObj.item_category = "";
        itemObj.item_variant =
          this.lanType == 1
            ? products[p].desc || products[p].name
            : products[p].descEn || products[p].nameEn;

        items.push(itemObj);
      }

      var ecommerce = {
        id: id,
        yuu_id: this.yuuId,
        store: this.shopInfo.storeId,
        store_num: this.shopInfo.extraStoreId,
        service:
          this.pageParam.businessType == 1
            ? "delivery"
            : this.pageParam.businessType == 2
            ? "Takeaway"
            : "dine in",
        payment: this.cardInfo.paymentChannel,
        preorder: this.pageParam.book == 2 ? true : false,
        value:
          this.priceByCoupon.money +
          this.recommendPrice +
          this.pageParam.afterDeliveryFee,
        currency: this.$store.state.moneyMark[this.$store.state.area],
        cutlery: this.paramInfoYk.switchBowl,
        safe_delivery: this.paramInfoYk.switchSend,
        items,
      };
      if (this.$store.state.userInfo) {
        ecommerce.membership_id = this.$store.state.userInfo.userId;
      }
      if (this.pageParam.businessType == 2) {
        if (this.preItemInfo) {
          ecommerce.promotion =
            this.lanType == 1
              ? this.preItemInfo.title
              : this.preItemInfo.titleEng;
        }
      } else if (this.preItemInfo && this.preItemInfo.item) {
        ecommerce.promotion =
          this.lanType == 1
            ? this.preItemInfo.item.content
            : this.preItemInfo.item.contentEng;
      }
      if (this.couponInfo) {
        ecommerce.coupon = this.couponInfo.voucherCode;
        ecommerce.coupon_name = this.couponInfo.voucherName;
      }
      if (this.$store.state.app) {
        ecommerce.affiliation = "app";
      } else {
        ecommerce.affiliation = "mobile";
      }
      window.dataLayer.push({
        event: "begin_checkout",
        ecommerce,
      });
    },

    onceDatalayerPush() {
      var items = [];
      var products = copyData(this.$store.state.cart);
      for (var p = 0; p < products.length; p++) {
        var itemObj = {};
        itemObj.id = products[p].pid;
        itemObj.item_name =
          this.lanType == 1 ? products[p].name : products[p].nameEn;
        itemObj.item_id = products[p].pid;
        itemObj.price = products[p].price;
        itemObj.item_list_name = products[p].smallTypeName;
        itemObj.item_list_id = products[p].smallTypeId;
        itemObj.index = p;
        itemObj.quantity = 1;
        itemObj.rec_usage = "";
        itemObj.item_category = "";
        itemObj.item_variant =
          this.lanType == 1
            ? products[p].desc || products[p].name
            : products[p].descEn || products[p].nameEn;

        items.push(itemObj);
      }

      var ecommerce = {
        store: this.shopInfo.storeId,
        store_num: this.shopInfo.extraStoreId,
        service:
          this.pageParam.businessType == 1
            ? "delivery"
            : this.pageParam.businessType == 2
            ? "Takeaway"
            : "dine in",
        preorder: this.pageParam.book == 2 ? true : false,
        value:
          this.priceByCoupon.money +
          this.recommendPrice +
          this.pageParam.afterDeliveryFee,
        currency: this.$store.state.moneyMark[this.$store.state.area],
        cutlery: this.paramInfoYk.switchBowl || false,
        safe_delivery: this.paramInfoYk.switchSend || false,
        items,
      };
      if (this.$store.state.userInfo) {
        ecommerce.yuu_id = this.$store.state.userInfo.yuuId;
        ecommerce.membership_id = this.$store.state.userInfo.userId;
      }
      if (this.pageParam.businessType == 2) {
        if (this.preItemInfo) {
          ecommerce.promotion =
            this.lanType == 1
              ? this.preItemInfo.title
              : this.preItemInfo.titleEng;
        }
      } else if (this.preItemInfo && this.preItemInfo.item) {
        ecommerce.promotion =
          this.lanType == 1
            ? this.preItemInfo.item.content
            : this.preItemInfo.item.contentEng;
      }
      if (this.couponInfo) {
        ecommerce.coupon = this.couponInfo.voucherCode;
        ecommerce.coupon_name = this.couponInfo.voucherName;
      }
      if (this.$store.state.app) {
        ecommerce.affiliation = "app";
      } else {
        ecommerce.affiliation = "mobile";
      }
      window.dataLayer.push({
        event: "begin_checkout",
        ecommerce,
      });
    },

    dataLayerPushBegin() {
      var items = [];
      var products = copyData(this.$store.state.cart);
      for (var p = 0; p < products.length; p++) {
        var itemObj = {};
        itemObj.item_id = products[p].pid;
        itemObj.item_list_name = products[p].smallTypeName;
        itemObj.item_list_id = products[p].smallTypeId;
        itemObj.index = p;
        itemObj.quantity = 1;
        itemObj.rec_usage = "";
        itemObj.item_variant =
          this.lanType == 1
            ? products[p].desc || products[p].name
            : products[p].descEn || products[p].nameEn;

        items.push(itemObj);
      }
      var ecommerce = {
        items,
        item_brand: "KFC",
        yuu_id: this.yuuId,
        store: this.shopInfo.storeId,
        service:
          this.pageParam.businessType == 1
            ? "delivery"
            : this.pageParam.businessType == 2
            ? "Takeaway"
            : "dine in",
        Payment: this.cardInfo.paymentChannel,
        preorder: this.pageParam.book == 2 ? true : false,
        cutlery: this.paramInfoYk.switchBowl,
        Value:
          this.priceByCoupon.money +
          this.recommendPrice +
          this.pageParam.afterDeliveryFee,
        currency: this.$store.state.moneyMark[this.$store.state.area],
        safe_delivery: this.paramInfoYk.switchSend,
        address_variant: "",
      };
      if (this.$store.state.app) {
        ecommerce.affiliation = "app";
      } else {
        ecommerce.affiliation = "mobile";
      }
      if (this.couponInfo) {
        ecommerce.coupon = this.couponInfo.voucherCode;
      }
      if (this.pageParam.businessType == 2) {
        if (this.preItemInfo) {
          ecommerce.promotion =
            this.lanType == 1
              ? this.preItemInfo.title
              : this.preItemInfo.titleEng;
        }
      } else if (this.preItemInfo && this.preItemInfo.item) {
        ecommerce.promotion =
          this.lanType == 1
            ? this.preItemInfo.item.content
            : this.preItemInfo.item.contentEng;
      }
      if (this.$store.state.userInfo) {
        ecommerce.membership_id = this.$store.state.userInfo.userId;
      }
      if (this.pageParam.businessType == 1) {
        var address_variant = "";
        if (this.selectPlaceInfo) {
          address_variant +=
            this.selectPlaceInfo.building + " " + this.selectPlaceInfo.district;
          if (this.selectPlaceInfo.street) {
            address_variant += this.selectPlaceInfo.street;
          }
          if (this.selectPlaceInfo.block) {
            address_variant += this.selectPlaceInfo.block;
          }
          if (this.selectPlaceInfo.flat) {
            address_variant += this.selectPlaceInfo.flat;
          }
          if (this.selectPlaceInfo.floor) {
            address_variant += this.selectPlaceInfo.floor;
          }
        } else {
          address_variant +=
            this.$store.state.placeInfo.building +
            " " +
            this.$store.state.placeInfo.district;
          if (this.paramInfoYk.detailPlace) {
            address_variant += this.paramInfoYk.detailPlace;
          }
          if (this.paramInfoYk.detailPlace) {
            address_variant += this.paramInfoYk.house;
          }
          if (this.paramInfoYk.doorNum) {
            address_variant += this.paramInfoYk.doorNum;
          }
          if (this.paramInfoYk.floor) {
            address_variant += this.paramInfoYk.floor;
          }
        }
        ecommerce.address_variant = address_variant;
      }
      window.dataLayer.push({
        event: "confirm_payment",
        ecommerce,
      });
    },

    // 刪除購物車的優惠餐品
    deleteCouPro() {
      var cart = copyData(this.$store.state.cart) || [];
      for (var i = 0; i < cart.length; i++) {
        if (cart[i].isCouponPro) {
          var productObj = {};
          var product = cart[i];
          product.num = 0;
          productObj.product = product;
          productObj.cart = true;
          this.$store.commit("createCart", productObj);
          break;
        }
      }
    },
    async getPrice() {
      var preInfo = {};

      if (this.pageParam.businessType == 2) {
        if (this.preItemInfo) {
          preInfo[this.storePres.pid] = {};
          preInfo[this.storePres.pid][this.preItemInfo.items[0].index] =
            this.preItemInfo.items[0].num;
        }
      } else if (this.preItemInfo && this.preItemInfo.item) {
        preInfo[this.storePres.pid] = {};
        preInfo[this.storePres.pid][this.preItemInfo.item.index] =
          this.preItemInfo.item.num;
      }
      if (this.orderPres.length) {
        preInfo[this.rccommendInfo.pid] = {};
        this.orderPres.forEach((val) => {
          preInfo[this.rccommendInfo.pid][val.itemIndex] = val.num;
        });
      }
      if (this.discountPre) {
        preInfo[this.discountPre.pid] = {};
        this.discountPre.items.forEach((P) => {
          preInfo[this.discountPre.pid][P.index] = P.num;
        });
      }
      var selfGetDT = 0;
      if (this.pageParam.book != 2) {
        var timeNow =
          parseInt(this.quoteTime || 30) * 60 * 1000 + new Date().getTime();
        var years = new Date(timeNow).getFullYear();
        var months = new Date(timeNow).getMonth() + 1;
        var days = new Date(timeNow).getDate();
        var hour = new Date(timeNow).getHours();
        var minutes = new Date(timeNow).getMinutes();
        var miao = new Date(timeNow).getSeconds();
        if (months < 10) {
          months = "0" + months;
        }
        if (days < 10) {
          days = "0" + days;
        }
        if (hour < 10) {
          hour = "0" + hour;
        }
        if (minutes < 10) {
          minutes = "0" + minutes;
        }
        if (miao < 10) {
          miao = "0" + miao;
        }
        selfGetDT =
          years +
          "-" +
          months +
          "-" +
          days +
          " " +
          hour +
          ":" +
          minutes +
          ":" +
          miao;
      } else {
        var yuYueTime =
          this.pageParam.book == 2 ? this.pageParam.second : this.sendTime;
        var yuYueTimeArr = yuYueTime.split(" ");
        var yuYueDate = yuYueTimeArr[0].split("/");
        var yuYueHMS = yuYueTimeArr[1].split(":");
        if (yuYueDate[1] < 10 && yuYueDate[1].length < 2) {
          yuYueDate[1] = "0" + yuYueDate[1];
        }
        if (yuYueDate[2] < 10 && yuYueDate[2].length < 2) {
          yuYueDate[2] = "0" + yuYueDate[2];
        }
        if (yuYueHMS[0] < 10 && yuYueHMS[0].length < 2) {
          yuYueHMS[0] = "0" + yuYueHMS[0];
        }
        if (yuYueHMS[1] < 10 && yuYueHMS[1].length < 2) {
          yuYueHMS[1] = "0" + yuYueHMS[1];
        }
        if (yuYueHMS[2] < 10 && yuYueHMS[2].length < 2) {
          yuYueHMS[2] = "0" + yuYueHMS[2];
        }
        var selfGetDate =
          yuYueDate[0] + "-" + yuYueDate[1] + "-" + yuYueDate[2];
        var selfGetTime = yuYueHMS[0] + ":" + yuYueHMS[1] + ":" + "00";
        selfGetDT = selfGetDate + " " + selfGetTime;
      }

      var params = {
        actionName: "candao.orderOwn.calOrderPrice",
        content: {
          storeId: this.pageParam.storeId,
          menuId: this.pageParam.menuId,
          type:
            this.pageParam.businessType == 1 && this.pageParam.book == 2
              ? 4
              : this.pageParam.businessType,
          selfGetTime: this.pageParam.businessType != 3 ? selfGetDT : "", //预约送达时间 格式：yyyy-MM-dd HH:mm:ss
          products: this.foodSku.products,
          pres: preInfo || {},
        },
      };
      if (this.couponInfo) {
        params.content.memberPreferentials = [
          {
            childType: 3,
            content: this.couponInfo.voucherName || this.couponInfo.couponName,
            contentEng: "",
            pid: this.couponInfo.voucherCode || this.couponInfo.cid,
            prePrice: this.priceByCoupon.price
              ? "-" + this.priceByCoupon.price
              : 0,
            count: 1,
            type: 7,
            preProducts: [],
          },
        ];
        if (
          // this.login &&
          this.couponInfo.voucherType == 3 ||
          this.couponInfo.voucherType == 5
        ) {
          if (this.foodSku.preProducts.length) {
            params.content.memberPreferentials[0].preProducts =
              this.foodSku.preProducts;
          } else if (
            this.couponInfo.copyPro &&
            this.couponInfo.copyPro.type == 1 &&
            !this.couponInfo.copyPro.property
          ) {
            var singlePro = [];
            var singleObj = {
              num: 1,
              pid: this.couponInfo.copyPro.uid,
              price: this.couponInfo.copyPro.price,
              typeId: 1,
              weight: 0,
            };
            singlePro.push(singleObj);
            params.content.memberPreferentials[0].preProducts = singlePro;
          }
        } else if (!this.login && this.couponInfo.type == 2) {
          var productE = {
            pid: this.couponInfo.mappingPro.mappingId || 0,
            num: 1,
            price: this.couponInfo.mappingPro.mappingPrice || 0,
          };
          params.content.memberPreferentials[0].preProducts[0] = productE;
        }
      }
      // 如果登录了 并且手动选择了地址 用选择的地址
      var placeInfo = this.selectPlaceInfo || this.$store.state.placeInfo;
      if (placeInfo) {
        var coordinateRes = wgs_gcj_encrypts(placeInfo.lat, placeInfo.lng);
        params.content.longitude = coordinateRes.lng;
        params.content.latitude = coordinateRes.lat;
      }
      if (this.isYuu && this.yuuInfo.addressNow) {
        var coordinateResYuu = wgs_gcj_encrypts(
          this.yuuInfo.addressNow.postalCode.lat,
          this.yuuInfo.addressNow.postalCode.lng
        );
        params.content.longitude = coordinateResYuu.lng;
        params.content.latitude = coordinateResYuu.lat;
      }
      let result = await postDC("Action", params);
      if (result.status == 1) {
        this.priceTotalInfo = result.data;
      }
    },

    dataLayerPaymentSelected(info) {
      window.dataLayer.push({
        event: "payment",
        payment_selected: info.paymentChannel || "",
      });
    },

    // 中英文转换
    setlanguage() {
      var languageType;
      if (this.$store.state.language == 1) {
        languageType = "chinese";
      } else {
        languageType = "english";
      }
      this.lanType = this.$store.state.language;
      this.languageType = languageType;
      this.languageList = language;
      this.btnText = language.more[languageType];
      this.recomnedBtnText = language.more[languageType];
      this.new_QRDD_queRenDingDan =
        language.new_QRDD_queRenDingDan[languageType];
      this.gengGaiDiZhi = language.gengGaiDiZhi[languageType];
      this.age18 = language.age18[languageType];
      this.Payment = language.Payment[languageType];
      this.huiYuanJiFen = language.huiYuanJiFen[languageType];
      this.youHuiMa = language.youHuiMa[languageType];
      this.apply = language.apply[languageType];
      this.xiaoJiJinE = language.xiaoJiJinE[languageType];
      this.hungryMore = language.hungryMore[languageType];
      this.coupon = language.coupon[languageType];
      this.useCoupon = language.useCoupon[languageType];
      this.dingDanXiangQing = language.dingDanXiangQing[languageType];
      this.wu = language.wu[languageType];
      this.agreeXieXi = language.agreeXieXi[languageType];
      this.jieZhang = language.jieZhang[languageType];
      this.checkout = language.checkout[languageType];
      this.hanYouJiuJing = language.hanYouJiuJing[languageType];
      this.selectTime = language.selectTime[languageType];
      this.new_QRDD_heJi = language.new_QRDD_heJi[languageType];
      this.new_QRDD_peiSongFei = language.new_QRDD_peiSongFei[languageType];
      this.new_QRDD_dengRuKFC = language.new_QRDD_dengRuKFC[languageType];
      this.winkA = language.winkA[languageType];
      this.new_QRDD_liJiDengRu = language.new_QRDD_liJiDengRu[languageType];
      this.GWC_del = language.GWC_del[languageType];
      this.HQXXSB = language.HQXXSB[languageType];
      this.QXZQTMD = language.QXZQTMD[languageType];
      this.DQMDBZYYSJN = language.DQMDBZYYSJN[languageType];
      this.WFSYYHQ = language.WFSYYHQ[languageType];
      this.BZPSFWN = language.BZPSFWN[languageType];
      this.SYMK = language.SYMK[languageType];
      this.CDDLXBKY = language.CDDLXBKY[languageType];
      this.BZSYSJFW = language.BZSYSJFW[languageType];
      this.BZCD = language.BZCD[languageType];
      this.HQPSFYHSB = language.HQPSFYHSB[languageType];
      this.QBDYUU = language.QBDYUU[languageType];
      this.BFJKSB = language.BFJKSB[languageType];
      this.QTYXY = language.QTYXY[languageType];
      this.QXZDZ = language.QXZDZ[languageType];
      this.QTXDY = language.QTXDY[languageType];
      this.QTXLXR = language.QTXLXR[languageType];
      this.SJHCW = language.SJHCW[languageType];
      this.QTXXXDZ = language.QTXXXDZ[languageType];
      this.QXZZFFS = language.QXZZFFS[languageType];
      this.CPBZPSSJ = language.CPBZPSSJ[languageType];
      this.CPYCS = language.CPYCS[languageType];
      this.BZCDHYSQ = language.BZCDHYSQ[languageType];
      this.BZDQSJDSUB = language.BZDQSJDSUB[languageType];
      this.BZDQSJDSUBF = language.BZDQSJDSUBF[languageType];
      this.BZCDHYSQSUB = language.BZCDHYSQSUB[languageType];
      this.BNZCCTSY = language.BNZCCTSY[languageType];
      this.SXDZBZFWFWN = language.SXDZBZFWFWN[languageType];
      this.MZDDZKSYYZQSUB = language.MZDDZKSYYZQSUB[languageType];
      this.MZDDZKSYYZQSUBE = language.MZDDZKSYYZQSUBE[languageType];
      this.YCYHQ = language.YCYHQ[languageType];
      this.YCYHQSUB = language.YCYHQSUB[languageType];
      this.YCYHQCPSUB = language.YCYHQCPSUB[languageType];
      this.BNYYCDCFS = language.BNYYCDCFS[languageType];
      this.XYQTYHQFK = language.XYQTYHQFK[languageType];
      this.YHMYSX = language.YHMYSX[languageType];
      this.YHMZDSJSY = language.YHMZDSJSY[languageType];
      this.NKYGGSJ = language.NKYGGSJ[languageType];
      this.WDSYMK = language.WDSYMK[languageType];
      this.WDSYMKSUBJE = language.WDSYMKSUBJE[languageType];
      this.WDSYMKSUBCP = language.WDSYMKSUBCP[languageType];
      this.WDSYMKSUB = language.WDSYMKSUB[languageType];
      this.JXXG = language.JXXG[languageType];
      this.QWFK = language.QWFK[languageType];
      this.TDCP = language.TDCP[languageType];
      this.QSJG = language.QSJG[languageType];
      this.QRDDMK = language.QRDDMK[languageType];
      this.SCSJYG = language.SCSJYG[languageType];
    },
  },
  computed: {
    ...mapState(["isYuu", "yuuInfo", "isPC"]),
    canGetPres() {
      var cart = this.$store.state.cart;
      var length = 0;
      for (var i = 0; i < cart.length; i++) {
        if (cart[i].isCouponPro) continue;
        length += Number(cart[i].num);
      }
      let can = length > 0 ? true : false;
      return can;
    },
    // *
    canToPay() {
      // let cart = this.$store.state.cart;
      // if (cart && cart.length > 0) {
      //   return true;
      // }
      // return false;
      var cart = this.$store.state.cart;
      var length = 0;
      for (var i = 0; i < cart.length; i++) {
        if (cart[i].isCouponPro) continue;
        length += Number(cart[i].num);
      }
      if (
        this.couponInfo &&
        (this.couponInfo.voucherType == 3 || this.couponInfo.voucherType == 5)
      ) {
        length += 1;
      }
      let can = length > 0 ? true : false;
      return can;
    },
    // *
    couponItemSHow() {
      if (
        this.couponInfo &&
        this.couponInfo.mappingPro &&
        this.couponInfo.voucherType != 3 &&
        this.couponInfo.voucherType != 5
      ) {
        return true;
      }
      return false;
    },
    foodItemShow() {
      if (
        this.couponInfo &&
        (this.couponInfo.voucherType == 3 ||
          this.couponInfo.voucherType == 5) &&
        this.couponInfo.copyPro &&
        this.couponInfo.copyPro.type == 1 &&
        !this.couponInfo.copyPro.property
      ) {
        return true;
      }
      return false;
    },
    device() {
      var deviceId;
      var u = navigator.userAgent;
      if (u.indexOf("iPhone") > -1 || u.indexOf("iphone") > -1) {
        deviceId = "IOS";
      } else if (u.indexOf("Windows") > -1 || u.indexOf("windows") > -1) {
        deviceId = "WIN";
      } else if (u.indexOf("Android") > -1 || u.indexOf("android") > -1) {
        deviceId = "AND";
      } else if (u.indexOf("iPad") > -1 || u.indexOf("ipad") > -1) {
        deviceId = "IOS";
      } else if (u.indexOf("Mac") > -1 || u.indexOf("mac") > -1) {
        deviceId = "WIN";
      } else {
        deviceId = "Unknown device";
      }
      return deviceId;
    },
    time() {
      var timeMap = {
        "01": "Jan",
        "02": "Feb",
        "03": "Mar",
        "04": "Apr",
        "05": "May",
        "06": "Jun",
        "07": "Jul",
        "08": "Aug",
        "09": "Sept",
        10: "Oct",
        11: "Nov",
        12: "Dec",
      };
      var time =
        this.pageParam.book == 2 ? this.pageParam.second : this.sendTime;
      if (this.yuTime) {
        time = this.yuTime;
      }
      time = time.split(" ");
      var date = time[0];
      date = date.split("/");
      var dateStr = date[1] + "/" + date[2];
      if (this.lanType != 1) {
        dateStr = date[2] + " " + timeMap[date[1]];
      }
      var timeStr = time[1];
      timeStr = timeStr.split(":");
      if (timeStr[1] < 10 && timeStr[1].length < 2) {
        timeStr[1] = "0" + timeStr[1];
      }
      timeStr = timeStr[0] + ":" + timeStr[1];
      if (date[2] != new Date().getDate()) {
        timeStr = dateStr + " " + timeStr;
      }
      return timeStr;
    },
    // 推荐搭配的价钱
    recommendPrice() {
      var total = 0;
      for (var i = 0; i < this.orderPres.length; i++) {
        if (this.orderPres[i].price) {
          total += this.orderPres[i].price * this.orderPres[i].num;
        }
      }
      total = Math.round((total + Number.EPSILON) * 10) / 10;
      return total;
    },
    //送达时间
    sendTime() {
      var secondTime = new Date().getTime();
      if (this.pageParam.book == 2) {
        secondTime = new Date(this.pageParam.second).getTime();
      }
      // secondTime = secondTime;
      if (this.deliveryTime) {
        secondTime = secondTime + Number(this.deliveryTime) * 60 * 1000;
      } else {
        secondTime = secondTime + 30 * 60 * 1000;
      }
      var year = new Date(secondTime).getFullYear();
      var month = new Date(secondTime).getMonth() + 1;
      var day = new Date(secondTime).getDate();
      var hour = new Date(secondTime).getHours();
      var minutes = new Date(secondTime).getMinutes();
      var second = new Date(secondTime).getSeconds();
      if (month < 10) {
        month = "0" + month;
      }
      if (day < 10) {
        day = "0" + day;
      }
      if (hour < 10) {
        hour = "0" + hour;
      }
      minutes = parseInt(minutes);
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      var time =
        year +
        "/" +
        month +
        "/" +
        day +
        " " +
        hour +
        ":" +
        minutes +
        ":" +
        second;
      if (this.yuTime) {
        time = this.yuTime;
      }
      return time;
    },
    cartList() {
      var cart = copyData(this.$store.state.cart);
      for (var i = 0; i < cart.length; i++) {
        cart[i].isCanShow = true;
        if (cart[i].isCouponPro && !this.couponInfo) {
          cart[i].isCanShow = false;
        }
        var descStr = "";
        var priceAll = cart[i].price;
        if (cart[i].type == 2 || cart[i].typeId == 2) {
          var subProList = [];
          for (var c = 0; c < 1; c++) {
            for (
              var d = 0;
              d < cart[i].selectedFood.groups[c].mains.length;
              d++
            ) {
              for (
                var j = 0;
                j < cart[i].selectedFood.groups[c].mains[d].groups.length;
                j++
              ) {
                for (
                  var k = 0;
                  k <
                  cart[i].selectedFood.groups[c].mains[d].groups[j].products
                    .length;
                  k++
                ) {
                  if (!cart[i].isCouponPro) {
                    priceAll +=
                      cart[i].selectedFood.groups[c].mains[d].groups[j]
                        .products[k].price *
                      cart[i].selectedFood.groups[c].mains[d].groups[j]
                        .products[k].num;
                  }
                  var pro =
                    cart[i].selectedFood.groups[c].mains[d].groups[j].products[
                      k
                    ];
                  if (
                    pro.type == 1 &&
                    pro.listPropertys &&
                    pro.listPropertys.length > 0
                  ) {
                    pro.listPropertys.forEach((val1, index) => {
                      var nameType =
                        this.$store.state.language == 2 ? "nameEn" : "name";
                      var name = pro[nameType];
                      var num = val1.num;
                      val1.propertys.forEach((val2) => {
                        val2.items.forEach((val3) => {
                          name += " " + val3[nameType];
                        });
                      });
                      var proObj = copyData(pro);
                      proObj.name = proObj.nameEn = name;
                      proObj.subIndex = index;
                      proObj.num = num;
                      pro.subProListPropertys = proObj;
                      subProList.push(proObj);
                    });
                  } else {
                    subProList.push(
                      cart[i].selectedFood.groups[c].mains[d].groups[j]
                        .products[k]
                    );
                  }

                  // if (
                  //   pro.type == 1 &&
                  //   pro.listPropertys &&
                  //   pro.listPropertys.length
                  // ) {
                  // for (var pl = 0; pl < pro.num; pl++) {
                  //   if (this.lanType == 1) {
                  //     descStr += pro.name + " ";
                  //   } else {
                  //     descStr += pro.nameEn + " ";
                  //   }
                  //   var pPropertys = pro.listPropertys[pl].propertys;
                  //   for (var p_1 = 0; p_1 < pPropertys.length; p_1++) {
                  //     var pItems = pPropertys[p_1].items;
                  //     for (var p_l = 0; p_l < pItems.length; p_l++) {
                  //       if (this.lanType == 1) {
                  //         descStr += pItems[p_l].name;
                  //       } else {
                  //         descStr += pItems[p_l].nameEn;
                  //       }
                  //     }
                  //   }
                  //   descStr += " x1 ";
                  // }
                  // } else {

                  // if (this.lanType == 1) {
                  //   descStr +=
                  //     cart[i].selectedFood.groups[c].mains[d].groups[j]
                  //       .products[k].name +
                  //     "x" +
                  //     cart[i].selectedFood.groups[c].mains[d].groups[j]
                  //       .products[k].num +
                  //     " ";
                  // } else {
                  //   descStr +=
                  //     cart[i].selectedFood.groups[c].mains[d].groups[j]
                  //       .products[k].nameEn +
                  //     "x" +
                  //     cart[i].selectedFood.groups[c].mains[d].groups[j]
                  //       .products[k].num +
                  //     " ";
                  // }
                  // }
                }
              }
            }
          }
          cart[i].subProList = subProList;
        } else {
          if (this.lanType == 1) {
            descStr = cart[i].name + "x" + cart[i].num;
          } else {
            descStr = cart[i].nameEn + "x" + cart[i].num;
          }
          if (cart[i].listRequirements && cart[i].listRequirements.length) {
            var listRequirements = cart[i].listRequirements;
            for (var g = 0; g < listRequirements.length; g++) {
              var propertys = listRequirements[g].propertys;
              for (var l = 0; l < propertys.length; l++) {
                var items = propertys[l].items;
                for (var h = 0; h < items.length; h++) {
                  priceAll += items[h].price;
                  if (this.lanType == 1) {
                    descStr += items[h].name + " ";
                  } else {
                    descStr += items[h].nameEn + " ";
                  }
                }
              }
            }
          }
        }
        cart[i].descStr = descStr;
        cart[i].priceAll = priceAll;
      }
      return cart;
    },
    // 一共都少钱
    totalPrice() {
      var total = 0;
      var cart = copyData(this.$store.state.cart);
      for (var i = 0; i < cart.length; i++) {
        if (cart[i].isCouponPro) continue;
        var proMoney = cart[i].price;
        // total += cart[i].price * cart[i].num;
        if (cart[i].selectedFood && cart[i].selectedFood.groups) {
          for (var a = 0; a < cart[i].selectedFood.groups.length; a++) {
            for (
              var b = 0;
              b < cart[i].selectedFood.groups[a].mains.length;
              b++
            ) {
              for (
                var c = 0;
                c < cart[i].selectedFood.groups[a].mains[b].groups.length;
                c++
              ) {
                for (
                  var d = 0;
                  d <
                  cart[i].selectedFood.groups[a].mains[b].groups[c].products
                    .length;
                  d++
                ) {
                  proMoney +=
                    cart[i].selectedFood.groups[a].mains[b].groups[c].products[
                      d
                    ].price *
                    cart[i].selectedFood.groups[a].mains[b].groups[c].products[
                      d
                    ].num;
                }
              }
            }
          }
        }
        proMoney *= cart[i].num;
        total += proMoney;
      }
      return total;
    },
    couPrice() {
      if (this.pageParam.businessType == 2 && this.storePres) {
        var P = 1 - this.storePres.value / 100;
        // var money = (this.totalPrice * P).toFixed(1);
        var money =
          Math.round((this.totalPrice * P + Number.EPSILON) * 10) / 10;
      }
      return money || 0;
    },
    // 满减
    preItemInfo() {
      if (
        !this.storePres ||
        !this.storePres.items
        // || !this.storePres.items.length
      )
        return null;
      var preInfo = {};

      if (this.pageParam.businessType == 2) {
        preInfo = this.storePres;
      } else if (
        this.pageParam.businessType == 1 ||
        this.pageParam.businessType == 3
      ) {
        var priceArr = [];
        var pres = this.storePres.items;
        pres.sort((a, b) => {
          return a.needConsumeMoney - b.needConsumeMoney;
        });
        for (var a = 0; a < pres.length; a++) {
          if (this.totalPrice >= pres[a].needConsumeMoney) {
            priceArr[0] = pres[a].needConsumeMoney;
            priceArr[1] = pres[a].price;
            preInfo.item = pres[a];
          }
        }

        preInfo.price = priceArr;
      }
      return preInfo;
    },
    // 满减之后多少钱
    totalPricePre() {
      var money = this.totalPrice;
      if (this.preItemInfo) {
        if (this.pageParam.businessType == 2) {
          var value = this.preItemInfo.value / 100;
          money = money * value;
        } else {
          if (this.preItemInfo.price.length) {
            money = money + Number(this.preItemInfo.price[1] || 0);
          }
        }
      }
      if (this.$store.state.area != 0) {
        money += 1;
      }
      return money;
    },
    // 选取优惠券后的价格
    priceByCoupon() {
      var money = this.totalPricePre;

      if (this.couponInfo) {
        var price = 0;
        // if (this.login) {
        if (
          this.couponInfo.voucherType == 1 ||
          this.couponInfo.voucherType == 4
        ) {
          price = Number(this.couponInfo.voucherAmount) / 100;
          // price = price.toFixed(2);
          money -= price;
        } else if (this.couponInfo.voucherType == 2) {
          price = money * (Number(this.couponInfo.voucherDiscount) / 10000);
          // price = price.toFixed(2);
          money -= price;
        } else if (
          this.couponInfo.voucherType == 3 ||
          this.couponInfo.voucherType == 5
        ) {
          if (this.couponInfo.voucherAmount) {
            price = Number(this.couponInfo.voucherAmount) / 100;
            // price = price.toFixed(2);
          } else if (this.couponInfo.voucherDiscount) {
            price =
              this.totalPrice *
              (Number(this.couponInfo.voucherDiscount) / 10000);
            // price = price.toFixed(2);
          } else if (
            this.couponInfo.voucherProductCode &&
            this.couponInfo.voucherProductCode.length
          ) {
            // price = this.couponInfo.mappingPro.mappingPrice || 0;
            money += this.couponInfo.mappingPro.mappingPrice || 0;
          }
        }
        // } else {
        //   if (this.couponInfo.type == 1) {
        //     console.log(1);
        //   } else if (this.couponInfo.type == 2) {
        //     console.log(2);
        //   } else if (this.couponInfo.type == 3) {
        //     price = Number(this.couponInfo.voucherAmount) / 100;
        //     // price = price.toFixed(1);

        //     money -= price;
        //   } else if (this.couponInfo.type == 5) {
        //     // price = this.totalPrice * (Number(this.couponInfo.discount) / 100);
        //     // price = price.toFixed(1);
        //     money -= price;
        //   }
        // }
      }
      var pre = Number(this.recommendPrice) + Number(money);
      this.getDispatchPre(pre);

      // money = Number(money).toFixed(1);
      money = Math.round((Number(money) + Number.EPSILON) * 10) / 10;
      price = Math.round((price + Number.EPSILON) * 10) / 10;
      return { money, price };
    },
    // 是否登陆
    login() {
      var login;
      if (this.$store.state.userInfo) {
        login = true;
      } else {
        login = false;
      }
      return login;
    },
    // 餐品sku
    foodSku() {
      // var products = [];
      // var preProducts = [];
      // var proAll = [];
      // var cartList = copyData(this.$store.state.cart);
      // for (var i = 0; i < cartList.length; i++) {
      //   var obj = {
      //     pid: cartList[i].uid || cartList[i].pid,
      //     typeId: cartList[i].type || cartList[i].typeId,
      //     num: cartList[i].num || cartList[i].selectedFood.num || 1,
      //     weight: 0,
      //   };
      //   if (cartList[i].type == 1 || cartList[i].typeId == 1) {
      //     if (cartList[i].listRequirements) {
      //       obj.listRequirements = [];
      //       for (let m = 0; m < obj.num; m++) {
      //         var listPro = {
      //           index: m,
      //           num: cartList[i].listRequirements[0].num,
      //           propertys: cartList[i].listRequirements[0].propertys,
      //         };
      //         obj.listRequirements.push(listPro);
      //       }
      //     }
      //     proAll.push(obj);
      //     if (cartList[i].isCouponPro) {
      //       preProducts.push(obj);
      //     } else {
      //       products.push(obj);
      //     }
      //   } else if (cartList[i].type != 1 && cartList[i].typeId != 1) {
      //     cartList[i].selectedFood.num =
      //       cartList[i].num || cartList[i].selectedFood.num || 1;
      //     if (
      //       cartList[i].selectedFood.groups &&
      //       cartList[i].selectedFood.groups.length
      //     ) {
      //       // cartList[i].selectedFood.groups = [
      //       //   cartList[i].selectedFood.groups[0],
      //       // ];
      //       // for (var c = 1; c < cartList[i].selectedFood.num; c++) {
      //       //   // var grp = cartList[i].selectedFood.groups[0];
      //       //   var grp = {
      //       //     index: c,
      //       //     mains: cartList[i].selectedFood.groups[0].mains,
      //       //   };
      //       //   cartList[i].selectedFood.groups.push(grp);
      //       // }

      //       // cartList[i].selectedFood.groups.forEach((val1) => {
      //       //   val1.mains.forEach((val2) => {
      //       //     val2.groups.forEach((val3) => {
      //       //       val3.products.forEach((val4) => {
      //       //         if (val4.listPropertys && val4.listPropertys.length > 0) {
      //       //           delete val4.num
      //       //           // val4.num = 1;
      //       //           // val4.listPropertys.forEach(val5=>{
      //       //           //   delete val5.num
      //       //           // })
      //       //         }
      //       //       });
      //       //     });
      //       //   });
      //       // });
      //       var grp = {
      //         index: 0,
      //         num: cartList[i].selectedFood.num,
      //         mains: cartList[i].selectedFood.groups[0].mains,
      //       };
      //       cartList[i].selectedFood.groups[0] = grp;
      //     }
      //     proAll.push(cartList[i].selectedFood);
      //     if (cartList[i].isCouponPro) {
      //       preProducts.push(cartList[i].selectedFood);
      //     } else {
      //       products.push(cartList[i].selectedFood);
      //     }
      //   }
      // }
      // return { products, preProducts, proAll };
      var products = [];
      var preProducts = [];
      var proAll = [];
      var cartList = copyData(this.$store.state.cart);
      for (var i = 0; i < cartList.length; i++) {
        var obj = {
          pid: cartList[i].uid || cartList[i].pid,
          typeId: cartList[i].type || cartList[i].typeId,
          num: cartList[i].num || cartList[i].selectedFood.num || 1,
          weight: 0,
        };
        if (cartList[i].type == 1 || cartList[i].typeId == 1) {
          if (cartList[i].listRequirements) {
            obj.listRequirements = [];
            for (let m = 0; m < obj.num; m++) {
              var listPro = {
                index: m,
                num: cartList[i].listRequirements[0].num,
                propertys: cartList[i].listRequirements[0].propertys,
              };
              obj.listRequirements.push(listPro);
            }
          }
          proAll.push(obj);
          if (cartList[i].isCouponPro) {
            preProducts.push(obj);
          } else {
            products.push(obj);
          }
        } else if (cartList[i].type != 1 && cartList[i].typeId != 1) {
          cartList[i].selectedFood.num =
            cartList[i].num || cartList[i].selectedFood.num || 1;
          if (
            cartList[i].selectedFood.groups &&
            cartList[i].selectedFood.groups.length
          ) {
            var grp = {
              index: 0,
              num: cartList[i].selectedFood.num,
              mains: cartList[i].selectedFood.groups[0].mains,
            };
            cartList[i].selectedFood.groups[0] = grp;
          }
          proAll.push(cartList[i].selectedFood);
          if (cartList[i].isCouponPro) {
            preProducts.push(cartList[i].selectedFood);
          } else {
            products.push(cartList[i].selectedFood);
          }
        }
      }
      return { products, preProducts, proAll };
    },
    foodSkuGetPrice() {
      var products = [];
      var preProducts = [];
      var proAll = [];
      var cartList = copyData(this.$store.state.cart);
      for (var i = 0; i < cartList.length; i++) {
        var obj = {
          pid: cartList[i].uid || cartList[i].pid,
          typeId: cartList[i].type || cartList[i].typeId,
          num: cartList[i].num || cartList[i].selectedFood.num || 1,
          weight: 0,
        };
        if (cartList[i].type == 1 || cartList[i].typeId == 1) {
          if (cartList[i].listRequirements) {
            obj.listRequirements = [];
            for (let m = 0; m < obj.num; m++) {
              var listPro = {
                index: m,
                num: cartList[i].listRequirements[0].num,
                propertys: cartList[i].listRequirements[0].propertys,
              };
              obj.listRequirements.push(listPro);
            }
          }
          proAll.push(obj);
          if (cartList[i].isCouponPro) {
            preProducts.push(obj);
          } else {
            products.push(obj);
          }
        } else if (cartList[i].type != 1 && cartList[i].typeId != 1) {
          cartList[i].selectedFood.num =
            cartList[i].num || cartList[i].selectedFood.num || 1;
          if (
            cartList[i].selectedFood.groups &&
            cartList[i].selectedFood.groups.length
          ) {
            var grp = {
              index: 0,
              num: cartList[i].selectedFood.num,
              mains: cartList[i].selectedFood.groups[0].mains,
            };
            cartList[i].selectedFood.groups[0] = grp;
          }
          proAll.push(cartList[i].selectedFood);
          if (cartList[i].isCouponPro) {
            preProducts.push(cartList[i].selectedFood);
          } else {
            products.push(cartList[i].selectedFood);
          }
        }
      }
      return { products, preProducts, proAll };
    },
  },
  watch: {
    preItemInfo() {
      if (this.storePres && this.preItemInfo) {
        this.getPrice();
      }
    },
    couponInfo(val) {
      if (!val) {
        this.deleteCouPro();
      }
    },
  },
  activated() {
    this.getPrice();
  },
  created() {
    this.$store.commit("saveShowCoupon", true);
    this.canClickPay = [];
    this.getParams();
    this.$store.commit("changeClearCou", false);
    if (this.$store.state.userInfo) {
      this.scoreType = 1;
    }
    var href = window.location.href.split("/");
    this.pageLink = href[0] + "//";
    for (var i = 1; i < href.length; i++) {
      this.pageLink += href[i];
      if (href[i]) {
        break;
      }
    }
  },
  updated() {
    if (this.$store.state.clearCou) {
      this.couponInfo = null;
      this.$store.commit("saveCouponInfo", null);
    }
  },
  beforeRouteLeave(to) {
    this.changeData();
    if (to.name != "OrderFood") {
      var param = JSON.stringify(this.paramInfoYk);
      localStorage.setItem("KFCYK", param);
    } else {
      this.addFoodBox(false, true);
      localStorage.removeItem("KFCYK");
    }
    if (to.name == "FoodDetail") {
      this.$store.commit("saveRouter", "ConfirmOrder");
    } else {
      // if(!this.$store.state.app){
      this.$store.commit("clearRouter");
      // }
    }
  },
  components: {
    Topbar,
    OrderConfirmVip,
    OrderConfirmNoVip,
    UseCoupon,
    ConfirmBox,
    CardSelectBox,
    Myplace,
    Yuu,
    FoodConfirm,
  },
};
</script>
<style scoped>
.confirmOrderPage {
  background-color: #f5f5f5;
  padding-bottom: 1.2rem;
}
.userInfo,
.orderFood,
.orderCoupon {
  margin: 0.16rem 0.16rem 0.12rem;
  padding: 0.16rem 0.16rem 0;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 0.08rem;
}

.userSell {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.16rem 0;
  box-sizing: border-box;
  font-size: 0.14rem;
  font-family: PingFang HK;
  font-weight: 600;
  color: #030f14;
  border-bottom: 0.01rem solid #eee;
}

.userInfo.active,
.orderCoupon.active {
  padding-top: 0;
  padding-bottom: 0;
}
.title {
  height: 0.28rem;
  font-size: 0.2rem;
  font-family: PingFang HK;
  font-weight: 600;
  line-height: 0.28rem;
  color: #030f14;
  text-align: left;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.title i {
  width: 0.17rem;
  height: 0.21rem;
  background: url("../../assets/icon/ddxq.png") no-repeat center;
  background-size: cover;
  margin-right: 0.08rem;
}

.title.active i {
  width: 0.24rem;
  height: 0.24rem;
  background: url("../../assets/icon/more1.png") no-repeat center;
  background-size: cover;
}

.title-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.title-box p:nth-child(2) {
  font-family: PingFang HK;
  font-style: normal;
  font-weight: normal;
  font-size: 0.11rem;
  color: #808080;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.title-box p:nth-child(2) i {
  width: 0.16rem;
  height: 0.16rem;
  background: url("../../assets/icon/vector.png") no-repeat center;
  background-size: cover;
  margin-right: 0.04rem;
}
.foodList {
  margin: 0.15rem 0;
  padding-top: 0.16rem;
  border-top: 0.02rem dashed #aaa;
  border-bottom: 0.02rem dashed #aaa;
}
.itemBox {
  max-height: 1.1rem;
  overflow: hidden;
}
.itemBox.active {
  max-height: none;
  height: auto;
}
.foodItem {
  margin-bottom: 0.24rem;
}
.itemLeft {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 0.2rem;
  font-size: 0.14rem;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 0.18rem;
  color: #474747;
}
.foodName {
  width: 2rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
}
.couponIcon {
  display: inline-block;
  width: 0.18rem;
  height: 0.18rem;
  line-height: 0.18rem;
  text-align: center;
  border: 0.01rem solid #e4022b;
  color: #e4022b;
  margin-right: 0.02rem;
}
.foodInfo {
  width: 100%;
  font-size: 0.1rem;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 0.16rem;
  color: #b1b1b3;
  text-align: justify;
  margin-top: 0.08rem;
}
.lookAll {
  height: 0.17rem;
  font-size: 0.12rem;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 0.17rem;
  color: #b1b1b3;
  margin-bottom: 0.16rem;
  margin-top: 0.16rem;
}
.totalPrice {
  width: 100%;
  height: 0.22rem;
  font-size: 0.1rem;
  font-family: PingFang HK;
  font-weight: 600;
  line-height: 0.22rem;
  color: #03060d;
  text-align: right;
  margin-bottom: 0.24rem;
}
.totalPrice span {
  font-size: 0.16rem;
}
.recomendList {
  margin-top: 0.15rem;
  border-bottom: 0.02rem dashed #aaa;
  margin-bottom: 0.15rem;
}
.recomendItemBox {
  max-height: 1.8rem;
  overflow: hidden;
}
.recomendItemBox.active {
  max-height: none;
  height: auto;
}
.recomendItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.22rem;
}
.recomendItem img {
  width: 0.44rem;
  height: 0.44rem;
  object-fit: cover;
}
.name {
  width: 1.15rem;
  font-size: 0.14rem;
  font-family: PingFang HK;
  font-weight: 500;
  line-height: 0.18rem;
  color: #474747;
  text-align: left;
}
.price {
  font-size: 0.14rem;
  font-family: PingFang HK;
  font-weight: 500;
  line-height: 0.18rem;
  color: #e4022b;
}
.price p:last-child {
  font-size: 0.1rem;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 0.18rem;
  color: #cacbcc;
}
.foodNum {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.18rem;
}
.foodNum input {
  width: 0.2rem;
  height: 0.2rem;
  margin: 0 0.02rem;
  border: 0;
  outline: none;
  text-align: center;
  border-radius: 0.02rem;
  font-size: 0.15rem;
  line-height: 0.2rem;
}

.foodNum input.active {
  background-color: rgba(242, 242, 242, 1);
}
.foodNum span {
  width: 0.18rem;
  height: 0.18rem;
  border: 0.02rem solid #e4022b;
  border-radius: 0.04rem;
  line-height: 0.18rem;
  color: #e4022b;
  font-weight: 600;
  font-size: 0.2rem;
}
.foodNum span.active {
  background-color: #e4022b;
  color: #fff;
}
.foodNum .add {
  background-color: #e4022b;
  color: #fff;
}
.priceLine {
  position: relative;
}
.priceLine i {
  position: absolute;
  width: 80%;
  height: 0.02rem;
  background-color: #cacbcc;
  top: 0.08rem;
  left: 0.05rem;
}
.coupon span {
  color: #e4022b;
}
.couponCode input {
  width: 2.43rem;
  height: 0.37rem;
  border: 0.01rem solid #cacbcc;
  border-radius: 0.08rem;
  padding: 0 0.12rem;
  box-sizing: border-box;
  font-size: 0.12rem;
  font-family: PingFang HK;
  font-weight: 400;
  color: #b1b1b3;
}
.couponCode p {
  width: 0.6rem;
  height: 0.37rem;
  background: #e4022b;
  border-radius: 0.08rem;
  text-align: center;
  line-height: 0.37rem;
  color: #fff;
}
.score {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.score span {
  width: 0.52rem;
  height: 0.28rem;
  border: 0.01rem dashed #e1e1e1;
  border-radius: 0.04rem;
  margin-left: 0.08rem;
  line-height: 0.28rem;
  color: #e1e1e1;
}
.score span.active {
  border: 0.01rem solid #e4022b;
  color: #e4022b;
}
.agreement {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 0.16rem;
  box-sizing: border-box;
  margin-bottom: 0.12rem;
}
.agreement img {
  width: 0.16rem;
  height: 0.16rem;
  object-fit: cover;
  margin-right: 0.08rem;
}
.agreement p {
  font-size: 0.11rem;
  font-family: Open Sans;
  font-weight: 400;
  line-height: 0.18rem;
  color: #030f14;
  text-align: justify;
}
.payBtnBox {
  /* margin: 0 0.16rem; */
  background: #f5e9eb;
  height: 0.68rem;
  padding-top: 0.12rem;
  box-sizing: border-box;
  border-radius: 0.1rem;
  width: 3.43rem;
  position: fixed;
  bottom: 0.5rem;
  left: 50%;
  transform: translateX(-50%);
}
.warn {
  height: 0.16rem;
  font-size: 0.11rem;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 0.16rem;
  color: #03060d;
  text-align: center;
}
.payBtn {
  width: 100%;
  height: 0.56rem;
  background: rgb(182, 179, 179);
  border-radius: 0.28rem;
  font-size: 0.18rem;
  font-family: PingFang HK;
  font-weight: 600;
  color: #ffffff;
  margin-top: 0.12rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 0.16rem;
  box-sizing: border-box;
}
.payBtn p:last-child {
  width: 1.2rem;
  height: 100%;
  background: linear-gradient(90deg, #fe1112 0%, #e4022b 100%);
  border-radius: 0.28rem;
  line-height: 0.56rem;
}
.userSell.active {
  border: none;
}
.couponInfoBox {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 0.24rem;
  border: 0.01rem solid #e4022b;
  border-radius: 0.12rem;
  padding: 0 0.04rem;
  color: #e4022b;
}

.coupon {
  max-width: 2rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.delIcon {
  width: 0.16rem;
  height: 0.16rem;
  background: url("../../assets/icon/delcou.png") no-repeat center;
  background-size: cover;
  margin-left: 0.04rem;
}
.pickerBg {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999999;
}
.datePicker {
  width: 100%;
  position: fixed;
  bottom: -100vh;
  left: 0;
  z-index: 9999999999;
  transition: all 0.2s linear;
}
.datePicker.active {
  bottom: 0;
}
.pre {
  width: 100%;
  height: 0.2rem;
  font-size: 0.14rem;
  line-height: 0.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.16rem;
}
.pre span {
  font-size: 0.12rem;
}
.pre.active p:last-child {
  color: #e4022b;
}
.sendMoney {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.sendMoney p:first-child {
  margin-right: 0.1rem;
  position: relative;
  color: #cacbcc;
}
.sendMoney p i {
  position: absolute;
  width: 100%;
  height: 0.01rem;
  background-color: #cacbcc;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.scoreGet span {
  font-size: 0.12rem;
  color: #b5b6bd;
}
.userSell.activeK {
  flex-direction: column;
}
.userSell.activeK p:first-child {
  text-align: left;
  word-break: break-all;
}
.userSell.activeK p:last-child {
  width: 100%;
  height: 0.44rem;
  color: #fff;
  background-color: #e4022b;
  border-radius: 0.12rem;
  line-height: 0.44rem;
  margin-top: 0.16rem;
}
.foodInfoItem {
  width: 2.45rem;
  font-size: 0.1rem;
  display: flex;
  justify-content: space-between;
}
.foodInfoItem p:first-child {
  width: 2rem;
  line-height: 0.14rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.XYAGREE span {
  color: #218bce !important;
}
</style>