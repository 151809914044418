<template>
  <div class="yuuPage">
    <!-- 已绑定yuu -->
    <div class="bindded">
      <p class="yuu-num">
        <span></span
        ><span>{{
          $store.state.userInfo ? $store.state.userInfo.yuuId : yuuId
        }}</span>
      </p>
      <p :class="{ up: upActive, active: rnowActive }" @click="showAll"></p>
    </div>
    <!--  -->
    <div v-show="rnowActive">
      <div class="yuu-tab" v-if="showTab == 3">
        <p @click="selectTab(1)" :class="{ active: showTab == 1 }">
          {{ lanType == 1 ? "绑定yuu ID" : "Link Yuu ID" }}
        </p>
        <p @click="selectTab(2)" :class="{ active: showTab == 2 }">
          {{ lanType == 1 ? "快速註冊" : "Express Sign up" }}
        </p>
      </div>
      <!-- 绑定yuu -->
      <div class="bindYuu" v-if="showTab == 1">
        <p>{{ bindText }}</p>
        <div class="setBox">
          <input
            v-show="showInput"
            type="number"
            v-model.trim="yuuCode"
            :placeholder="
              $store.state.language == 1
                ? '輸入你的yuu ID'
                : 'Please provide your yuu ID'
            "
          />
          <p class="bindBtn" @click="bindYuu">{{ bindBtn }}</p>
        </div>
      </div>
      <!-- 注册yuu -->
      <div class="bindYuu" v-if="showTab == 3">
        <p v-if="registerShow">
          {{
            lanType == 1
              ? "立即註冊成為yuu會員，即可透過此次消費賺取積分，更快兌換豐富獎賞！"
              : "Sign up as a yuu Member now to start earning yuu Points on this purchase. Redeem your Points for amazing yuu Rewards!"
          }}
        </p>
        <p v-if="!registerShow && lanType == 1">全港最大型獎賞計畫！</p>
        <p v-if="!registerShow && lanType == 1">
          想賺得更快？你又進一步啦！立即使用我們發送至[<span>会员邮箱</span>
          ]的邀請碼完成登記。
        </p>
        <p v-if="!registerShow && lanType != 1">Check current, any guideine?</p>
        <div class="setBox">
          <p class="bindBtn" @click="registerYuu">{{ registerBtn }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { postPaas } from "../../assets/utils/request";
export default {
  name: "Yuu",
  data() {
    return {
      lanType: this.$store.state.language,
      showTab: 1,
      yuuCode: "",
      yuuId:
        this.$store.state.language == 1 ? "綁定我的yuu ID" : "Link my Yuu ID ",
      showInput: true,
      registerShow: true,
      upActive:
        this.$store.state.userInfo && this.$store.state.userInfo.yuuId
          ? false
          : true,
      rnowActive: false,
      bindBtn:
        this.$store.state.language == 1 ? "綁定我的yuu ID" : "Link my Yuu ID ",
      bindText:
        this.$store.state.language == 1
          ? "已有yuu ID ？立即绑定你的yuu帳戶賺取積分 ！"
          : "Have a yuu ID? Link your yuu Account now to earn yuu Points!",
      registerBtn:
        this.$store.state.language == 1 ? "快速註冊" : "Express Sign up",
      userInfo: this.$store.state.userInfo,
    };
  },
  props: {
    phone: String,
    default() {
      return "";
    },
  },
  methods: {
    setYuuCode() {
      if (!this.$store.state.isYuu) return;
      var code = this.$store.state.yuuInfo.cardNo;
      this.yuuId = code;
      this.$emit("bindYuu", code);
      this.upActive = false;
      this.rnowActive = false;
    },
    selectTab(type) {
      this.showTab = type;
    },
    showAll() {
      if (!this.upActive) return;
      this.upActive = !this.upActive;
      this.rnowActive = !this.rnowActive;
    },
    // 绑定yuu
    async bindYuu() {
      if (!this.showInput) {
        this.upActive = false;
        this.rnowActive = false;
        return;
      }
      if (!this.yuuCode) {
        var A =
          this.$store.state.language == 1
            ? "請填寫yuu號碼"
            : "Please provide your yuu ID";
        return this.$toast(A);
      }
      var param = {
        actionName: "candao.member.yuuCardInfoBinding",
        content: {
          cardNo: this.yuuCode,
        },
      };
      if (this.userInfo) {
        param.content.userId = this.$store.state.userInfo.userId;
      } else {
        param.actionName = "candao.member.yuuCardInfo";
      }
      let result = await postPaas("UserUnify", param);
      if (result.status == 1) {
        this.bindText = "你已成功綁定你的yuu ID";
        this.showInput = false;
        this.bindBtn = "下一步";
        if (this.$store.state.language != 1) {
          this.bindBtn = "Next";
        }
        this.$emit("bindYuu", this.yuuCode);
        if (this.userInfo) {
          this.userInfo.yuuId = this.yuuCode;
          this.$store.commit("setUserInfo", this.userInfo);
        } else {
          this.yuuId = "ID:" + this.yuuCode;
        }
      } else {
        var AT =
          this.$store.state.language == 1
            ? "網絡異常，請重試"
            : "Connection error has occurred, please retry";
        this.$toast(AT);
      }
    },
    // 注册Yuu
    async registerYuu() {
      if (!this.registerShow) {
        this.selectTab(1);
        // this.upActive = false;
        // this.rnowActive = false;
        return;
      }
      if (!this.phone) {
        var alertText = "請輸入手機號碼";
        if (this.$store.state.language != 1) {
          alertText = "Please provide mobile number";
        }
        return this.$toast(alertText);
      }
      var param = {
        actionName: "candao.member.yuuSignUp",
        content: {},
      };
      if (this.$store.state.userInfo) {
        var length = this.$store.state.userInfo.phoneNo.length;
        param.content.userId = this.$store.state.userInfo.userId;
        param.content.inviteSecret = this.$store.state.userInfo.phoneNo.slice(
          length - 4
        );
      } else {
        var pLength = this.phone.length;
        param.content.inviteSecret = this.phone.slice(pLength - 4);
      }
      let result = await postPaas("UserUnify", param);
      if (result.status == 1) {
        // this.$emit("bindYuu", this.yuuId);
        this.registerShow = false;
        this.registerBtn = "下一步";
        if (this.$store.state.language != 1) {
          this.registerBtn = "Next";
        }
        // if (this.userInfo) {
        //   this.userInfo.yuuId = result.data.cardNo;
        //   this.$store.commit("setUserInfo", this.userInfo);
        // } else {
        this.yuuId = "ID:" + result.data.cardNo;
        // }
      } else {
        var AT =
          this.$store.state.language == 1
            ? "網絡異常，請重試"
            : "Connection error has occurred, please retry";

        this.$toast(AT);
      }
    },
  },
  mounted() {
    this.setYuuCode();
  },
};
</script>
<style scoped>
.yuuPage {
  width: 100%;
  padding-bottom: 0.16rem;
}
.bindded {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 0.01rem solid rgba(0, 0, 0, 0.2);
  padding: 0.1rem;
  box-sizing: border-box;
  border-radius: 0.06rem;
}
.yuu-num {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.12rem;
  font-weight: 600;
}
.yuu-num span:first-child {
  width: 0.36rem;
  height: 0.24rem;
  overflow: hidden;
  background: url("../../assets/icon/yuuLogo.png") no-repeat center;
  background-size: cover;
  margin-right: 0.08rem;
}
.bindded p:nth-child(2) {
  width: 0.24rem;
  height: 0.24rem;
  background: url("../../assets/icon/yuu1.png") no-repeat center;
  background-size: cover;
}
.bindded p:nth-child(2).active {
  width: 0.2rem;
  height: 0.2rem;
  background: url("../../assets/icon/rnow1.png") no-repeat center;
  background-size: cover;
}
.bindded p:nth-child(2).up {
  background: url("../../assets/icon/rnow2.png") no-repeat center;
  background-size: cover;
}
.yuu-tab {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.12rem;
  color: #218bce;
}
.yuu-tab p {
  width: 50%;
  text-align: center;
  border-bottom: 0.01rem solid #9dc7f6;
  padding: 0.1rem 0.04rem;
  box-sizing: border-box;
  color: #9dc7f6;
}
.yuu-tab p.active {
  border-color: #218bce;
  color: #218bce;
}
.bindYuu {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0.16rem 0.16rem;
  border: 0.01rem solid rgba(0, 0, 0, 0.2);
  margin-top: 0.1rem;
  border-radius: 0.06rem;
  box-sizing: border-box;
}
.bindYuu > p {
  font-size: 0.12rem;
  font-weight: 500;
  color: #000;
  text-align: left;
}
.bindYuu > p:first-child {
  margin-bottom: 0.24rem;
}
.bindYuu input {
  width: 100%;
  height: 0.4rem;
  padding: 0 0.1rem;
  box-sizing: border-box;
  color: #218bce !important;
  font-size: 0.12rem;
  border: 0.01rem solid rgba(0, 0, 0, 0.2);
  outline: none;
  border-radius: 0.06rem;
}
.bindYuu input::placeholder {
  color: #218bce;
}
.setBox {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.bindBtn {
  width: 100%;
  height: 0.4rem;
  text-align: center;
  line-height: 0.4rem;
  color: #fff;
  background-color: #218bce;
  margin-top: 0.16rem;
  font-size: 0.12rem;
  border-radius: 0.06rem;
}
</style>