export function encodeStr(str) {
    const A = [
      {
        code: "%",
        encode: "%25",
      },
      {
        code: "?",
        encode: "%3F",
      },
      {
        code: "#",
        encode: "%23",
      },
      {
        code: "&",
        encode: "%26",
      },
      {
        code: "=",
        encode: "%3D",
      },
    ];
    var key = str.replace(/[%?#&=]/g, ($, index, str) => {
      for (var k of A) {
        if (k.code == $) {
          return k.encode;
        }
      }
      console.log(str);
    });
    return key;
  }